import React from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

export default function TaskCompletedFieldset({ task, onComplete }) {
    return (
        task.isCompleted && (
            <fieldset className="p-3 mt-2 border border-gray-400 border-solid rounded opacity-75">
                <legend
                    className="text-[16px] h-[14px]  pr-1 pb-0 pt-0 text-xs font-normal font-roboto m-0"
                >
                    Completed
                </legend>

                <div className="flex flex-row font-roboto">
                    <div className="flex">
                        <FontAwesomeIcon
                            className="flex mr-3 text-3xl cursor-pointer pointer-events-auto "
                            color={!task.isCompleted ? 'gray' : 'red'}
                            data-bs-toggle="tooltip"
                            title="Mark Task Complete"
                            icon={!task.isCompleted ? faCheckCircle : faCheckCircle}
                            onClick={(e) => onComplete(e)}
                        />
                    </div>
                    <div className="flex pt-1 text-gray-700">
                        {'Completed ' + moment(task.completedDate).fromNow()}
                    </div>
                </div>
            </fieldset>
        )
    );
}
