import React, { useEffect } from 'react';
import { App } from '@capacitor/app';
import { useNavigate, useLocation } from 'react-router-dom'; // Im

export const AppListener = () => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     navigate('/magic-login?token=test');
    // }, []);

    useEffect(() => {
        const handleDeepLink = async (event) => {
            const { url } = event;

            // Extract the token from the URL
            const token = new URL(url).searchParams.get('token');

            if (token) {
                // Handle the deep link with the token, e.g., navigate to the magic-login page
                // You can replace the console.log with your own logic
                console.log(`Deep link token: ${token}`);
                console.log("Navigating to /magic-login/?token=" + token);
                // navigate(`/magic-login?token=${token}`);
                if (token) {
                    console.log(`Deep link token: ${token}`);
                    window.location.href = `/magic-login?token=${token}`;
                }


            }
        };

        App.addListener('appUrlOpen', handleDeepLink);

        return () => {
            App.removeListener('appUrlOpen', handleDeepLink);
        };
    }, []);

    return null;
};

export default AppListener;
