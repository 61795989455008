import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

export function AccountSection({
    account,
    handleDeleteAccount,
    handleMakePrimary,
    handleToggleUsing,
    buttonSize = 'regular',
    loginGoogle,
    microsoftLogin,
}) {
    const [isOpen, setIsOpen] = useState(account.error);

    // Helper function to get timezone abbreviation
    const getTimezoneAbbr = (timeZone) => {
        if (!timeZone) return 'N/A';
        try {
            const options = { timeZone, timeZoneName: 'short' };
            return new Intl.DateTimeFormat('en-US', options)
                .formatToParts(new Date())
                .find((part) => part.type === 'timeZoneName').value;
        } catch (error) {
            console.error(`Error getting timezone abbreviation for ${timeZone}:`, error);
            return 'Unknown';
        }
    };

    return (
        <motion.div
            className="overflow-hidden bg-white rounded-lg shadow-md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
        >
            <div
                className="flex justify-between px-4 py-3 bg-blue-100 cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex items-center">
                    <div className="text-lg font-medium text-blue-800">{account.email}</div>
                    {account.primary && (
                        <div className="px-2 py-1 ml-2 text-xs font-semibold text-green-700 bg-green-200 rounded-full">
                            Primary
                        </div>
                    )}
                </div>
                <div className="flex items-center">
                    {buttonSize !== 'small' && (
                        <>
                            {!account.primary && (
                                <button
                                    className="px-3 py-1 mr-2 text-xs text-white bg-blue-500 rounded-md shadow-sm transition-colors hover:bg-blue-600"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleMakePrimary(account);
                                    }}
                                >
                                    Set Primary
                                </button>
                            )}
                            <button
                                className="px-3 py-1 text-xs text-white bg-red-500 rounded-md shadow-sm transition-colors hover:bg-red-600"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteAccount(account);
                                }}
                            >
                                Delete
                            </button>
                        </>
                    )}
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="ml-2 text-blue-500" />
                </div>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className="p-4"
                    >
                        {account.error && (
                            <div className="p-4 mb-4 text-white bg-red-500 rounded-lg shadow-md">
                                {account.error}
                                <button
                                    className="px-4 py-2 ml-4 text-red-500 bg-white rounded-lg shadow-md transition-colors hover:bg-red-100"
                                    onClick={() => (account.type === 'google' ? loginGoogle() : microsoftLogin())}
                                >
                                    Reconnect
                                </button>
                            </div>
                        )}

                        {account.calendars ? (
                            <table className="w-full text-sm">
                                <thead className="text-left text-blue-700 bg-blue-50">
                                    <tr>
                                        <th className="p-2">Summary</th>
                                        <th className="p-2">Zone</th>
                                        <th className="p-2">Role</th>
                                        <th className="p-2">Use?</th>
                                        <th className="p-2">Dirty?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {account.calendars.map((calendar) => (
                                        <tr
                                            key={calendar.id}
                                            className="border-b border-blue-100 transition-colors hover:bg-blue-50"
                                        >
                                            <td className="p-2">{calendar.summary}</td>
                                            <td className="p-2">{getTimezoneAbbr(calendar.timeZone)}</td>
                                            <td className="p-2">{calendar.accessRole}</td>
                                            <td className="p-2">
                                                <label className="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="text-blue-500 form-checkbox"
                                                        checked={calendar.using}
                                                        onChange={() => handleToggleUsing(calendar)}
                                                    />
                                                </label>
                                            </td>
                                            <td className="p-2">{calendar.dirty ? 'Yes' : 'No'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <div className="p-2 text-blue-600">No calendars are associated with this account.</div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
}
