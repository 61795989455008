import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../atoms/tokenAtoms';

const API_BASE_URL = import.meta.env.VITE_PUBLIC_API_HOST;

export const useBillingSubscription = () => {
    const [token] = useAtom(tokenAtom);
    const [mobilePaymentError, setMobilePaymentError] = useState('');
    const [mobilePaymentSuccess, setMobilePaymentSuccess] = useState('');

    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    const getRedirectURL = () => {
        if (Capacitor.isNativePlatform()) {
            return `https://${import.meta.env.VITE_PUBLIC_ANDROID_PACKAGE_NAME}`;
        }
        return `https://${window.location.host}`;
    };

    // const { data: subscriptionStatus, refetch: refetchSubscriptionStatus } = useQuery(
    //     'subscriptionStatus',
    //     () => axiosInstance.post('/api/payments/getSubscriptionStatus').then((res) => res.data),
    //     { enabled: !!token }
    // );

    const createCustomerPortalSession = useMutation(
        () =>
            axiosInstance
                .post('/api/payments/create-customer-portal-session', { redirectURL: getRedirectURL() })
                .then((res) => res.data),
        {
            onSuccess: (url) => {
                window.location.href = url;
            },
        }
    );

    const createCheckoutSession = useMutation(
        () =>
            axiosInstance
                .post('/api/payments/create-checkout-session', { redirectURL: getRedirectURL() })
                .then((res) => res.data),
        {
            onSuccess: (url) => {
                window.location.href = url;
            },
        }
    );

    const setMobileSubscription = useMutation(
        (productType) =>
            axiosInstance
                .post('/api/payments/setMobileSubscription', { response: productType })
                .then((res) => res.data),
        {
            onSuccess: () => {
                setMobilePaymentSuccess('Subscription successfully set!');
                refetchSubscriptionStatus();
            },
            onError: (error) => {
                setMobilePaymentError('Error setting subscription: ' + error.message);
            },
        }
    );

    return {
        // subscriptionStatus,
        // refetchSubscriptionStatus,
        createCustomerPortalSession,
        createCheckoutSession,
        setMobileSubscription,
        mobilePaymentError,
        mobilePaymentSuccess,
    };
};
