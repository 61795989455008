import React, { useState, useCallback, useMemo } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PickersActionBar } from '@mui/x-date-pickers';
import moment from 'moment';
import { useTaskSchedule } from './hooks/useTaskSchedule';
import TaskDatePickerToolbar from './TaskDatePickerToolbar';

const defaultMaterialTheme = createTheme({
    overrides: {
        MuiDatePicker: {
            switchHeader: {
                color: '#6A148E',
                textTransform: 'uppercase',
            },
            dayLabel: {
                textTransform: 'uppercase',
            },
            day: {
                color: '#707070',
            },
            daySelected: {
                backgroundColor: '#6A148E',
                '&:hover': {
                    backgroundColor: '#6A148E',
                },
            },
            current: {
                color: '#6A148E',
            },
            root: {
                fill: '#6A148E',
            },
        },
    },
});

function CustomLayout({
    children,
    onViewChange,
    onDismiss,
    setPriority,
    otherPriorities,
    setDate,
    updateTask,
    ...other
}) {
    // log updateTask
    console.log('updateTask in CustomLayout:', updateTask);

    return (
        <div>
            {children}
            <TaskDatePickerToolbar
                {...other}
                onViewChange={onViewChange}
                onDismiss={onDismiss}
                otherPriorities={otherPriorities}
                setDate={setDate}
                setPriorities={otherPriorities.map((priority) => () => setPriority(priority))}
                updateTask={updateTask}
            />
            <PickersActionBar
                onAccept={onDismiss}
                onCancel={onDismiss}
                onClear={onDismiss}
                onSetToday={onDismiss}
                disableSpacing={true}
                actions={['cancel', 'accept']}
            />
        </div>
    );
}

function CustomDatePicker({
    label,
    value,
    setValue,
    taskPriority,
    onPriorityChange,
    updateTask,
    taskStartDate,
    clearable = false,
}) {
    const { taskSchedule } = useTaskSchedule();

    const [open, setOpen] = useState(false);

    const memoizedSetValue = useCallback(
        (newValue) => {
            setValue(newValue);
        },
        [setValue]
    );

    const memoizedOnPriorityChange = useCallback(
        (priority) => {
            if (onPriorityChange) {
                onPriorityChange(priority);
            }
        },
        [onPriorityChange]
    );

    const priorities = useMemo(() => ['CRITICAL', 'OPPORTUNITY_NOW', 'OVER_THE_HORIZON', 'PARKING_LOT'], []);
    const otherPriorities = useMemo(
        () => priorities.filter((priority) => priority !== taskPriority),
        [priorities, taskPriority]
    );

    const parsedDateValue = useMemo(
        () => (value ? (typeof value === 'string' ? moment(value).toDate() : value) : null),
        [value]
    );

    const setToday = useCallback(() => {
        memoizedSetValue(new Date());
        setOpen(false);
    }, [memoizedSetValue]);

    const setTomorrow = useCallback(() => {
        memoizedSetValue(moment().add(1, 'day').toDate());
        setOpen(false);
    }, [memoizedSetValue]);

    const setNextWeekend = useCallback(() => {
        const nextWeekend = moment().add(1, 'week').startOf('isoWeek').add(5, 'day').toDate();
        memoizedSetValue(nextWeekend);
        setOpen(false);
    }, [memoizedSetValue]);

    const setNextWeek = useCallback(() => {
        const nextWeek = moment().add(1, 'week').startOf('isoWeek').toDate();
        memoizedSetValue(nextWeek);
        setOpen(false);
    }, [memoizedSetValue]);

    const setPriority = useCallback(
        (priority) => {
            let startDate;

            switch (priority) {
                case 'CRITICAL':
                    startDate = new Date();
                    break;
                case 'OVER_THE_HORIZON':
                    startDate = taskSchedule?.overTheHorizonDate;
                    break;
                case 'OPPORTUNITY_NOW':
                    startDate = taskSchedule?.opportunityNowDate;
                    break;
                case 'PARKING_LOT':
                    startDate = taskSchedule?.parkingLotDate;
                    break;
            }

            memoizedSetValue(startDate);
            memoizedOnPriorityChange(priority);
            setOpen(false);

            // Update the task with new priority and start date
            updateTask({ priority, startDate });
        },
        [memoizedSetValue, memoizedOnPriorityChange, taskSchedule, updateTask]
    );

    const handleChange = useCallback(
        (newValue) => {
            memoizedSetValue(newValue);
            setOpen(false);
            updateTask({ startDate: newValue });
        },
        [memoizedSetValue, updateTask]
    );

    const layoutProps = useMemo(
        () => ({
            setToday,
            setTomorrow,
            setNextWeekend,
            setNextWeek,
            otherPriorities,
            setPriority,
            setDate: memoizedSetValue,
            taskStartDate,
            updateTask,
        }),
        [
            setToday,
            setTomorrow,
            setNextWeekend,
            setNextWeek,
            otherPriorities,
            setPriority,
            memoizedSetValue,
            taskStartDate,
            updateTask,
        ]
    );

    return (
        <ThemeProvider theme={defaultMaterialTheme}>
            <DatePicker
                value={parsedDateValue}
                onChange={handleChange} // Use memoized handleChange
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                slots={{
                    layout: CustomLayout,
                }}
                slotProps={{
                    textField: { variant: 'outlined' },
                    layout: layoutProps,
                }}
                label={label}
                clearable={clearable}
                showDaysOutsideCurrentMonth={true}
                PaperProps={{
                    style: {
                        zIndex: 1400,
                    },
                }}
                PopperProps={{
                    style: {
                        zIndex: 1400,
                    },
                }}
            />
        </ThemeProvider>
    );
}

export default CustomDatePicker;
