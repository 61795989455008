import React, { useState, useCallback } from 'react';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import { questions } from './questions';
import 'survey-core/defaultV2.min.css';

// Separate component for displaying answers
function Answer({ question, result, feature }) {
    return (
        <div className="p-4 my-4 bg-blue-100 rounded-lg">
            <h3 className="text-lg font-bold">{question}</h3>
            <p className="mt-1">Your answer: {result}</p>
            <p className="mt-1">{feature}</p>
        </div>
    );
}

export function TimeManagementSurvey() {
    const [results, setResults] = useState(null);

    const alertResults = useCallback((sender) => {
        const results = JSON.stringify(sender.data);
        alert(results);
        setResults(sender.data);
    }, []);

    const surveyJson = {
        title: 'Time Management Survey',
        showProgressBar: 'top',
        firstPageIsStarted: true,
        startSurveyText: 'Start the Survey',
        pages: questions.map((q, index) => {
            return {
                questions: [
                    {
                        type: 'radiogroup',
                        name: `question${index + 1}`,
                        title: q.question,
                        choices: q.options,
                    },
                ],
            };
        }),
    };

    const survey = new Model(surveyJson);
    survey.onComplete.add(alertResults);

    return (
        <div className="bg-sky-100 p-4 text-[#25384f]">
            <Survey model={survey} />
            {results && (
                <div>
                    <h2 className="text-2xl font-bold">Your Results:</h2>
                    {Object.keys(results).map((resultKey, index) => {
                        const result = results[resultKey];
                        return (
                            <Answer
                                key={index}
                                question={questions[index].question}
                                result={result}
                                feature={questions[index].feature}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default TimeManagementSurvey;
