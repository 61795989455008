import { useState, useEffect, useCallback } from 'react';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { useAtom } from 'jotai';
import { pushTokenAtom } from '../atoms/pushTokenAtom';

export default function useNotifications(isAuthenticated) {
    const [notificationsRegistered, setNotificationsRegistered] = useState(false);
    const [listenersAdded, setListenersAdded] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [pushToken, setPushToken] = useAtom(pushTokenAtom);

    const isWeb = Capacitor.getPlatform() === 'web';

    useEffect(() => {
        if (isAuthenticated && !initialized) {
            initializeNotifications();
        }
    }, [isAuthenticated, initialized]);

    const initializeNotifications = async () => {
        if (isWeb) {
            await initializeWebNotifications();
        } else {
            await initializeNativeNotifications();
        }
        setInitialized(true);
    };

    const initializeWebNotifications = async () => {
        if ('Notification' in window) {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                setNotificationsRegistered(true);
                setListenersAdded(true);
                console.log('Web notifications initialized');
            } else {
                console.log('Web notification permission denied');
            }
        } else {
            console.log('Web notifications not supported in this browser');
        }
    };

    const initializeNativeNotifications = async () => {
        const { PushNotifications } = await import('@capacitor/push-notifications');

        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            console.log('User denied permissions!');
            return;
        }

        await PushNotifications.register();
        setNotificationsRegistered(true);

        await addNativeListeners(PushNotifications);
        setListenersAdded(true);

        console.log('Native notifications initialized');
    };

    const addNativeListeners = async (PushNotifications) => {
        await PushNotifications.addListener('registration', (token) => {
            console.log('Push registration success, token: ' + token.value);
            setPushToken(token.value);
        });

        await PushNotifications.addListener('registrationError', (err) => {
            console.error('Registration error: ', err.error);
        });

        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received: ', notification);
            scheduleLocalNotification(notification);
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
            console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
    };

    const scheduleLocalNotification = async (notification) => {
        const notif = {
            title: notification.data.title || notification.title,
            body: notification.data.body || notification.body,
            id: Math.floor(Math.random() * Math.pow(2, 31)),
            schedule: { allowWhileIdle: true },
        };

        await LocalNotifications.schedule({ notifications: [notif] });
    };

    return {
        notificationsRegistered,
        listenersAdded,
        pushToken,
    };
}
