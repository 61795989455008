import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

function ScheduleListItem({ schedule, handleScheduleSelect, handleScheduleDelete }) {
    return (
        <ListItem button onClick={() => handleScheduleSelect(schedule)}>
            <ListItemText primary={schedule.name} />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" onClick={() => handleScheduleSelect(schedule)}>
                    <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleScheduleDelete(schedule)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export default ScheduleListItem;
