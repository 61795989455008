import { useCallback, useRef, useState, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { tokenAtom } from '../atoms/tokenAtoms';
import { useTasks } from '../providers/TasksProvider';

export const usePlanning = () => {
    const [token] = useAtom(tokenAtom);
    const queryClient = useQueryClient();
    const debouncerRef = useRef(null);
    const [isPlanning, setIsPlanning] = useState(false);

    const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';

    // Use the useTasks context to fetch tasks
    const { tasks, isLoading: isLoadingTasks, refetch: refetchTasks } = useTasks();

    // Date utility functions
    const isToday = useCallback((dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    }, []);

    const startToday = useCallback((dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        return date <= today;
    }, []);

    // Task utility functions
    const getPlanningActivities = useCallback(
        (tasks) => {
            if (!tasks) return [];
            const plans = [];

            const filterTasks = (priority) =>
                tasks.filter(
                    (task) =>
                        task.priority === priority &&
                        !task.isCompleted &&
                        (isToday(task.startDate) || startToday(task.startDate))
                );

            const todaysUncompletedParkingLotTasks = filterTasks('PARKING_LOT');
            const todaysUncompletedOverTheHorizonTasks = filterTasks('OVER_THE_HORIZON');
            const todaysUncompletedOpportunityNowTasks = filterTasks('OPPORTUNITY_NOW');
            const todaysUncompletedCriticalTasks = filterTasks('CRITICAL');

            if (todaysUncompletedParkingLotTasks.length > 0) {
                plans.push('Plan your Parking Lot tasks');
            }
            if (todaysUncompletedOverTheHorizonTasks.length > 0) {
                plans.push('Plan your Over The Horizon Tasks');
            }
            if (todaysUncompletedOpportunityNowTasks.length > 20) {
                plans.push(`Too many Opportunity Now tasks (${todaysUncompletedOpportunityNowTasks.length}/20)`);
            }
            if (todaysUncompletedCriticalTasks.length > 5) {
                plans.push(`Too many Critical tasks (${todaysUncompletedCriticalTasks.length}/5)`);
            }

            return plans;
        },
        [isToday, startToday]
    );

    const planningActivities = useMemo(() => getPlanningActivities(tasks), [tasks, getPlanningActivities]);

    // Planning mutation functions
    const fetchPlanService = useCallback(async () => {
        const response = await axios.get(baseUrl + 'planning/plan', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    }, [baseUrl, token]);

    const planMutation = useMutation(fetchPlanService, {
        onSuccess: () => {
            queryClient.invalidateQueries('tasks');
            queryClient.invalidateQueries('calendarEvents');
            refetchTasks();
            setIsPlanning(false);
        },
    });

    const debouncedPlan = useCallback(() => {
        if (debouncerRef.current) {
            clearTimeout(debouncerRef.current);
        }

        setIsPlanning(true);
        debouncerRef.current = setTimeout(() => {
            planMutation.mutate();
        }, 5000);
    }, [planMutation]);

    const createPlanningMutation = (endpoint) => {
        const mutationFn = async () => {
            const response = await axios.get(baseUrl + endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        };

        return useMutation(mutationFn, {
            onSuccess: () => {
                queryClient.invalidateQueries('tasks');
                queryClient.invalidateQueries('calendarEvents');
                refetchTasks();
            },
        });
    };

    const scheduleAllMutation = createPlanningMutation('planning/scheduleAll');
    const unScheduleAllMutation = createPlanningMutation('planning/unScheduleAll');
    const kickTheCanMutation = createPlanningMutation('planning/kickTheCan', {
        onSuccess: () => {
            queryClient.invalidateQueries('tasks');
        },
    });

    const instantPlan = useCallback(() => {
        setIsPlanning(true);
        planMutation.mutate();
    }, [planMutation]);

    return useMemo(
        () => ({
            plan: debouncedPlan,
            instantPlan,
            isPlanning,
            scheduleAll: scheduleAllMutation.mutate,
            isSchedulingAll: scheduleAllMutation.isLoading,
            unScheduleAll: unScheduleAllMutation.mutate,
            isUnSchedulingAll: unScheduleAllMutation.isLoading,
            kickTheCan: kickTheCanMutation.mutate,
            isKickingTheCan: kickTheCanMutation.isLoading,
            tasks,
            isLoadingTasks,
            planningActivities,
        }),
        [
            debouncedPlan,
            instantPlan,
            isPlanning,
            scheduleAllMutation.mutate,
            scheduleAllMutation.isLoading,
            unScheduleAllMutation.mutate,
            unScheduleAllMutation.isLoading,
            kickTheCanMutation.mutate,
            kickTheCanMutation.isLoading,
            tasks,
            isLoadingTasks,
            planningActivities,
        ]
    );
};
