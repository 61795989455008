import { useCallback, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { tokenAtom } from '../../atoms/tokenAtoms';
import axios from 'axios';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { useCustomer } from '../../providers/CustomerProvider';

const PROJECTS_URL = `${import.meta.env.VITE_PUBLIC_API_HOST}/api/project`;

export const useProjects = () => {
    const token = useAtomValue(tokenAtom);
    const queryClient = useQueryClient();
    const { customer } = useCustomer();

    const fetchProjects = useCallback(async () => {
        if (!token) throw new Error('No token available');
        const response = await axios.get(`${PROJECTS_URL}/defaults`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    }, [token]);

    const {
        data: projectsData,
        isLoading,
        error,
    } = useQuery(['projects', customer?.id], fetchProjects, {
        enabled: !!token && !!customer,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 15 * 60 * 1000, // 15 minutes
    });

    const invalidateProjects = useCallback(() => {
        queryClient.invalidateQueries(['projects', customer?.id]);
    }, [queryClient, customer?.id]);

    const createProjectMutation = useMutation(
        async (newProject) => {
            const response = await axios.post(`${PROJECTS_URL}/create`, newProject, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(['projects', customer?.id], (oldData) => {
                    return oldData ? [...oldData, data] : [data];
                });
            },
        }
    );

    const updateProjectMutation = useMutation(
        async (updatedProject) => {
            const response = await axios.put(`${PROJECTS_URL}/${updatedProject.id}`, updatedProject, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(['projects', customer?.id], (oldData) => {
                    return oldData?.map((project) => (project.id === data.id ? data : project)) ?? [];
                });
            },
        }
    );

    const deleteProjectMutation = useMutation(
        async (id) => {
            await axios.delete(`${PROJECTS_URL}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return id;
        },
        {
            onSuccess: (deletedId) => {
                queryClient.setQueryData(['projects', customer?.id], (oldData) => {
                    return oldData?.filter((project) => project.id !== deletedId) ?? [];
                });
            },
        }
    );

    const moveTaskToProject = useCallback(
        async (projectId, task) => {
            const response = await axios.put(`${PROJECTS_URL}/${projectId}/moveTaskToProject/${task.id}`, null, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            invalidateProjects();
            return response.data;
        },
        [token, invalidateProjects]
    );

    const getProjectById = useCallback(
        (projectId) => {
            return projectsData?.find((project) => project.id === projectId);
        },
        [projectsData]
    );

    return {
        projectsData,
        isLoading,
        error,
        createProject: createProjectMutation.mutate,
        updateProject: updateProjectMutation.mutate,
        deleteProject: deleteProjectMutation.mutate,
        moveTaskToProject,
        refetchProjects: invalidateProjects,
        getProjectById,
    };
};
