import React, { useRef, useState, useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Paper } from '@mui/material';
import { Layout } from 'antd';
import { useCustomer } from '../../providers/CustomerProvider';
import HomeLoading from '../HomeLoading';
import { CalendarSetup } from './CalendarSetup';
import { CalendarWidget } from './CalendarWidget';
import { useCalendarEvents } from '../../contexts/CalendarEventsContext';
import { useAIChat } from '../utils/useAIChat';
import LoadingOverlay from '../LoadingOverlay';
import { startOfWeek, endOfWeek, parseISO, format, isSameDay } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { useTaskModal } from '../../contexts/TaskModalContext';

const MynCalendar = () => {
    const { customer } = useCustomer();
    const { calendarEvents, isLoading: isCalendarEventsLoading } = useCalendarEvents();
    const { chatMessages, handleUserInput } = useAIChat();

    const navigate = useNavigate();
    const location = useLocation();

    const { openTaskModal } = useTaskModal();

    const [selectedTimeZone, setSelectedTimeZone] = useState(customer?.defaultTimeZone);

    const timezones = [
        'America/New_York',
        'America/Chicago',
        'America/Denver',
        'America/Los_Angeles',
        'Europe/London',
        'Europe/Paris',
        'Asia/Tokyo',
        'Australia/Sydney',
    ];

    // Parse URL parameters
    const searchParams = new URLSearchParams(location.search);
    const initialView = searchParams.get('view') || 'timeGridWeek';
    const initialDate = searchParams.get('date') ? parseISO(searchParams.get('date')) : new Date();

    const updateURL = useCallback(
        (view, date) => {
            const newSearchParams = new URLSearchParams();
            newSearchParams.set('view', view);
            newSearchParams.set('date', format(date, 'yyyy-MM-dd'));
            navigate(`?${newSearchParams.toString()}`, { replace: true });
        },
        [navigate]
    );

    const handleViewChange = useCallback(
        (newView, newDate) => {
            updateURL(newView, newDate || initialDate);
        },
        [updateURL, initialDate]
    );

    const handleDatesSet = useCallback(
        debounce((dateInfo) => {
            if (dateInfo.view.type !== initialView || !isSameDay(dateInfo.start, initialDate)) {
                updateURL(dateInfo.view.type, dateInfo.start);
            }
        }, 300),
        [updateURL, initialView, initialDate]
    );

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD');
        ReactGA.send({ hitType: 'pageview', page: '/calendar', title: 'calendar' });
    }, []);

    const handleTimeZoneChange = (event) => {
        setSelectedTimeZone(event.target.value);
        if (customer) {
            customer.defaultTimeZone = event.target.value;
        }
    };

    const handleEventClick = useCallback(
        (info) => {
            const matchingEvent = calendarEvents.find((event) => event.id === info.event.id);
            console.log(matchingEvent);
            if (matchingEvent?.task) {
                console.log('matchingEvent.task', matchingEvent.task);
                const taskToOpen = matchingEvent.task.isRecurringChild
                    ? matchingEvent.task.parentTask
                    : matchingEvent.task;
                openTaskModal(taskToOpen);
            }
        },
        [calendarEvents, openTaskModal]
    );

    if (!customer) {
        return <HomeLoading />;
    }

    return (
        <Paper elevation={3} className="bg-white">
            <div id="allcalendar" className="flex overflow-auto relative z-0 flex-row h-screen max-h-screen">
                <Layout>
                    {(!customer.accounts || customer.accounts.length === 0) && (
                        <div className="w-1/5 flex-start">
                            <CalendarSetup buttonSize="small" />
                        </div>
                    )}

                    <div className="relative w-full">
                        {isCalendarEventsLoading && <LoadingOverlay />}
                        <CalendarWidget
                            handleEventClick={handleEventClick}
                            selectedTimeZone={selectedTimeZone}
                            handleTimeZoneChange={handleTimeZoneChange}
                            timezones={timezones}
                            calendarEvents={calendarEvents}
                            isLoading={isCalendarEventsLoading}
                            handleDatesSet={handleDatesSet}
                            initialView={initialView}
                            initialDate={initialDate}
                            onViewChange={handleViewChange}
                        />
                    </div>
                </Layout>
            </div>
        </Paper>
    );
};

export default MynCalendar;
