import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { useTasks } from '../../providers/TasksProvider';

const TimeOfDayHistogramChart = () => {
    const { tasks } = useTasks();
    const chartRef = useRef(null);

    const colorMap = {
        OVER_THE_HORIZON: '#F9913B',
        CRITICAL: '#0C803D',
        OPPORTUNITY_NOW: '#107CC4',
        PARKING_LOT: '#F04F23',
    };

    const timeLabels = ['Midnight to 4AM', '4AM to 8AM', '8AM to Noon', 'Noon to 4PM', '4PM to 8PM', '8PM to Midnight'];

    const priorities = Object.keys(colorMap);

    const processedData = useMemo(() => {
        const counts = Array.from({ length: 6 }, () =>
            priorities.reduce((acc, priority) => ({ ...acc, [priority]: 0 }), {})
        );

        tasks.forEach((task) => {
            const date = new Date(task.createdDate);
            const hours = date.getHours();
            const index = Math.min(Math.floor(hours / 4), 5);
            counts[index][task.priority]++;
        });

        return counts;
    }, [tasks, priorities]);

    useEffect(() => {
        if (!chartRef.current || !tasks.length) return;

        const margin = { top: 10, right: 10, bottom: 50, left: 50 };
        const width = 960 - margin.left - margin.right;
        const height = 500 - margin.top - margin.bottom;

        d3.select(chartRef.current).select('svg').remove();
        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleBand().domain(timeLabels).range([0, width]).padding(0.1);

        const y = d3
            .scaleLinear()
            .domain([0, d3.max(processedData, (d) => d3.sum(priorities, (p) => d[p]))])
            .range([height, 0]);

        const rects = svg
            .selectAll('g')
            .data(processedData)
            .join('g')
            .attr('transform', (d, i) => `translate(${x(timeLabels[i])},0)`);

        priorities.forEach((priority, index) => {
            rects
                .append('rect')
                .attr('y', (d, i) => {
                    const heightSum = d3.sum(priorities.slice(0, index).map((p) => processedData[i][p]));
                    return y(d[priority] + heightSum);
                })
                .attr('height', (d) => height - y(d[priority]))
                .attr('width', x.bandwidth())
                .attr('fill', colorMap[priority])
                .attr('stroke', 'white');
        });

        svg.append('g').call(d3.axisLeft(y).ticks(5));
        svg.append('g').attr('transform', `translate(0,${height})`).call(d3.axisBottom(x));

        const legend = svg
            .append('g')
            .attr('font-family', 'sans-serif')
            .attr('font-size', 10)
            .attr('text-anchor', 'end')
            .selectAll('g')
            .data(priorities.slice().reverse())
            .join('g')
            .attr('transform', (d, i) => `translate(0,${i * 20})`);

        legend
            .append('rect')
            .attr('x', width - 19)
            .attr('width', 19)
            .attr('height', 19)
            .attr('fill', (d) => colorMap[d]);

        legend
            .append('text')
            .attr('x', width - 24)
            .attr('y', 9.5)
            .attr('dy', '0.32em')
            .text((d) => d);
    }, [tasks, processedData, priorities, timeLabels, colorMap]);

    return (
        <div className="flex justify-center mt-10 w-full">
            <div ref={chartRef}></div>
        </div>
    );
};

export default TimeOfDayHistogramChart;
