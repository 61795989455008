import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import moment from 'moment-timezone';
import { Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function ScheduleForm({
    selectedSchedule,
    onCancel,
    handleScheduleSelect,
    handleScheduleAddEvent,
    handleSave,
    onDelete,
    handleTimeZoneChange,
    onUpdateSelectedSchedule,
}) {
    const [hoveredEventId, setHoveredEventId] = useState(null);
    const [scheduleName, setScheduleName] = useState('');
    const [timeZone, setTimeZone] = useState(undefined);
    const calendarRef = useRef(null);

    const timeZones = [
        { value: 'GMT', label: 'Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London', iana: 'Europe/Dublin' },
        { value: 'JST', label: 'Japan Standard Time: Osaka, Sapporo, Tokyo', iana: 'Asia/Tokyo' },
        { value: 'CET', label: 'Central European Time: Brussels, Copenhagen, Madrid, Paris', iana: 'Europe/Paris' },
        { value: 'EST', label: 'Eastern Time (US and Canada)', iana: 'America/New_York' },
        { value: 'CST', label: 'Central Time (US and Canada)', iana: 'America/Chicago' },
        { value: 'MST', label: 'Mountain Time (US and Canada)', iana: 'America/Denver' },
        { value: 'PST', label: 'Pacific Time (US and Canada)', iana: 'America/Los_Angeles' },
    ];

    useEffect(() => {
        if (selectedSchedule?.name) {
            setScheduleName(selectedSchedule.name);
            if (selectedSchedule?.timeZone) {
                setTimeZone(timeZones.find((tz) => tz.iana === selectedSchedule.timeZone));
            }
        }
    }, [selectedSchedule, selectedSchedule?.events, selectedSchedule?.timeZone]);

    function getCurrentTime(timeZone) {
        return moment().tz(timeZone).format('HH:mm');
    }

    const handleSaveSchedule = () => {
        const updatedSchedule = {
            ...selectedSchedule,
            name: scheduleName,
            timeZone: timeZone?.iana,
        };

        if (selectedSchedule.id) {
            handleSave(updatedSchedule);
        } else {
            const newSchedule = {
                ...updatedSchedule,
                id: null,
            };
            handleSave(newSchedule);
        }
    };

    const handleScheduleNameChange = (event) => {
        selectedSchedule.name = event.target.value;
        setScheduleName(event.target.value);
    };

    const handleEventDrop = (info) => {
        const updatedEvent = selectedSchedule.events.find((e) => e.id === info.event.id);
        const updatedEventIndex = selectedSchedule.events.indexOf(updatedEvent);
        const updatedScheduleEvents = selectedSchedule.events.map((e) => {
            if (e.id == info.event.id) {
                return {
                    ...e,
                    start: info.event.start,
                    end: info.event.end,
                };
            }
            return e;
        });

        const updatedSchedule = {
            ...selectedSchedule,
            events: updatedScheduleEvents,
        };

        onUpdateSelectedSchedule(updatedSchedule);
    };

    const handleEventResize = (info) => {
        const updatedEvent = selectedSchedule.events.find((e) => e.id === info.event.id);
        const updatedEventIndex = selectedSchedule.events.indexOf(updatedEvent);
        const updatedScheduleEvents = selectedSchedule.events.map((e) => {
            if (e.id == info.event.id) {
                return {
                    ...e,
                    start: info.event.start,
                    end: info.event.end,
                };
            }
            return e;
        });
        const updatedSchedule = {
            ...selectedSchedule,
            events: updatedScheduleEvents,
        };
        selectedSchedule.tryevents = updatedSchedule.events;
        selectedSchedule.events = updatedSchedule.events;
    };

    const handleCancelForm = (event) => {
        const abbreviation = moment.tz.zone(Intl.DateTimeFormat().resolvedOptions().timeZone).abbr(Date.now());
        setTimeZone(
            timeZones.filter((tz) => tz.value === abbreviation)[0] || timeZones.filter((tz) => tz.value === 'PST')[0]
        );
        setScheduleName(selectedSchedule.name);
        onCancel();
    };

    const handleEventMouseover = useCallback((arg) => {
        setHoveredEventId(arg.event.id);
    }, []);

    const handleEventMouseleave = useCallback(() => {
        setHoveredEventId(null);
    }, []);

    const renderEventContent = useCallback(
        (eventInfo) => {
            const { event } = eventInfo;
            const isHovered = event.id === hoveredEventId;

            const handleDeleteClick = (e) => {
                e.preventDefault();

                const selectedEventId = event.id;
                const updatedEvents = selectedSchedule.events.filter((e) => e.id != selectedEventId);
                const updatedSchedule = { ...selectedSchedule, events: updatedEvents };
                onUpdateSelectedSchedule(updatedSchedule);

                e.stopPropagation();
            };

            return (
                <div>
                    <b>{event.title}</b>
                    {isHovered && (
                        <div className="absolute top-0 right-0">
                            <DeleteForeverIcon
                                onClick={handleDeleteClick}
                                className="text-black cursor-pointer hover:text-red-700"
                            />
                        </div>
                    )}
                </div>
            );
        },
        [hoveredEventId, selectedSchedule, onUpdateSelectedSchedule]
    );

    const handleEventDidMount = useCallback(
        (arg) => {
            const eventElement = arg.el;
            const handleMouseOver = () => {
                handleEventMouseover(arg);
            };

            eventElement.addEventListener('mouseover', handleMouseOver);

            return () => {
                eventElement.removeEventListener('mouseover', handleMouseOver);
            };
        },
        [handleEventMouseover]
    );

    if (selectedSchedule == undefined) return <div> Please Select a schedule </div>;

    let firstSunday = moment('1970-01-01').day('Sunday');

    const timeZoneValue = timeZone?.value || '';

    return (
        <div id="ScheduleForm" className="flex flex-col flex-1 h-full">
            <div
                id="ScheduleFormRow"
                className="flex justify-between items-center p-4 w-full bg-blue-50 rounded-lg shadow-md"
            >
                <TextField
                    className="my-4 w-1/2"
                    label="Schedule Name"
                    value={scheduleName}
                    onChange={handleScheduleNameChange}
                />
                <FormControl className="my-4">
                    <Select
                        value={timeZoneValue}
                        onChange={handleTimeZoneChange}
                        displayEmpty
                        renderValue={() => timeZone?.label}
                    >
                        {timeZones.map((timeZone) => (
                            <MenuItem key={timeZone.value} value={timeZone.value}>
                                <ListItemText
                                    primary={<span className="font-bold">{timeZone.value}</span>}
                                    secondary={timeZone.label}
                                    style={{ width: '80%', display: 'inline-block' }}
                                />
                                <div style={{ width: '20%', textAlign: 'right', display: 'inline-block' }}>
                                    {getCurrentTime(timeZone.iana)}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="mt-8">
                    <Button onClick={handleCancelForm} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveSchedule} variant="contained" color="primary">
                        Save Changes
                    </Button>
                </div>
            </div>

            <div className="flex-grow">
                <FullCalendar
                    ref={calendarRef}
                    height={'auto'}
                    scrollTime="06:00:00"
                    plugins={[timeGridPlugin, interactionPlugin]}
                    initialView="timeGrid"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    nowIndicator={true}
                    visibleRange={{
                        start: '1970-01-04',
                        end: '1970-01-11',
                    }}
                    titleFormat={{ year: 'numeric', month: 'long' }}
                    dayHeaderFormat={{ weekday: 'short' }}
                    headerToolbar={null}
                    allDaySlot={false}
                    events={selectedSchedule.events}
                    eventResize={handleEventResize}
                    eventDrop={handleEventDrop}
                    select={handleScheduleAddEvent}
                    eventMouseEnter={handleEventMouseover}
                    eventMouseLeave={handleEventMouseleave}
                    eventContent={renderEventContent}
                    eventDidMount={handleEventDidMount}
                />
            </div>
        </div>
    );
}

export default ScheduleForm;
