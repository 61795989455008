import React from 'react';


const MouseFollowLine = ({ position }) => {
    if (!position) return null;

    const lineStyle = {
        position: 'absolute',
        top: position.y,
        left: 0,
        right: 0,
        height: '1px',
        backgroundColor: 'darkgray',
        pointerEvents: 'none',
    };

    return <div style={lineStyle} />;
};
export default MouseFollowLine;