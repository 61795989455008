import moment from 'moment';
// This function sorts the events in the calendar for a given day.
// The events are sorted based on several factors including priority, current or past status,
// and start time. The purpose of this sorting is to determine the relative order of the events.

// The first factor that is considered is the priority of the events.
// If the events have different priorities, the one with higher priority will be sorted first.

// If the events have the same priority, then the current or past status of the events is considered.
// If both events are current or in the past, they are sorted by start time in descending order.
// If one of the events is current or in the past, it is sorted first.

// If both events are not on the calendar, they are sorted by startDate.
// If one of the events is not on the calendar, it is sorted first.

// If both events are on the calendar and in the future, they are sorted by start time.
export function sortTasksAndEvents(calendarEvents, day) {

    if (!calendarEvents || !Array.isArray(calendarEvents[day])) {
        console.warn(`No events found for day: ${day}`);
        return [];
    }
    return calendarEvents[day].sort((a, b) => {
        const now = moment();

        // Check if event a is on the calendar
        const aScheduled = a.googleEvent && a.googleEvent.googleStart;
        // Check if event b is on the calendar
        const bScheduled = b.googleEvent && b.googleEvent.googleStart;

        // If both events are scheduled
        if (aScheduled && bScheduled) {
            // Sort by start time
            return moment(a.googleEvent.googleStart.dateTime).diff(moment(b.googleEvent.googleStart.dateTime));
        }
        // If only event a is scheduled, put it first
        else if (aScheduled) {
            return -1;
        }
        // If only event b is scheduled, put it first
        else if (bScheduled) {
            return 1;
        }
        // If neither event is scheduled
        else {
            // Sort by priority
            if (a.priority < b.priority) {
                return -1;
            } else if (a.priority > b.priority) {
                return 1;
            } else {
                // If priorities are the same, sort by startDate
                return moment(a.startDate).diff(moment(b.startDate));
            }
        }
    });
}
export default sortTasksAndEvents;
