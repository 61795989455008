import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { tokenAtom, isTokenExpiredAtom } from '../atoms/tokenAtoms';

const CurrentPictureContext = createContext();

export const CurrentPictureProvider = ({ children }) => {
    const [token] = useAtom(tokenAtom);
    const [isTokenExpired] = useAtom(isTokenExpiredAtom);
    const [currentPicture, setCurrentPicture] = useState(null);

    const fetchCurrentPicture = async () => {
        if (!token || isTokenExpired) {
            throw new Error('No valid token available');
        }

        const response = await axios.get(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/currentPicture`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data.currentPicture || response.data;
    };

    const { data, error, isLoading } = useQuery(['currentPicture', token], fetchCurrentPicture, {
        enabled: !!token && !isTokenExpired,
        staleTime: 1000 * 60 * 60, // 1 hour
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            if (error.message === 'No valid token available') {
                return false;
            }
            return failureCount < 3;
        },
        onSuccess: (data) => {
            setCurrentPicture(data);
        },
    });

    const updateCurrentPicture = useCallback((newPictureData) => {
        setCurrentPicture((prevPicture) => {
            if (JSON.stringify(prevPicture) !== JSON.stringify(newPictureData)) {
                return newPictureData;
            }
            return prevPicture;
        });
    }, []);

    const value = useMemo(
        () => ({
            currentPicture,
            updateCurrentPicture,
            isLoading,
            error,
        }),
        [currentPicture, updateCurrentPicture, isLoading, error]
    );

    if (error) {
        console.error('Error fetching current picture:', error);
    }

    return <CurrentPictureContext.Provider value={value}>{children}</CurrentPictureContext.Provider>;
};

export const useCurrentPicture = () => {
    const context = useContext(CurrentPictureContext);
    if (!context) {
        throw new Error('useCurrentPicture must be used within a CurrentPictureProvider');
    }
    return context;
};
