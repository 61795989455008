import React, { createContext, useContext, useReducer, useCallback } from 'react';

const NotificationsContext = createContext();

const notificationsReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_NOTIFICATION':
            return [...state, action.payload];
        case 'REMOVE_NOTIFICATION':
            return state.filter((notification) => notification.key !== action.payload);
        default:
            return state;
    }
};

export const NotificationsProvider = ({ children }) => {
    const [notifications, dispatch] = useReducer(notificationsReducer, []);

    const addNotification = useCallback((notification) => {
        dispatch({ type: 'ADD_NOTIFICATION', payload: notification });
    }, []);

    const removeNotification = useCallback((key) => {
        dispatch({ type: 'REMOVE_NOTIFICATION', payload: key });
    }, []);

    return (
        <NotificationsContext.Provider value={{ notifications, addNotification, removeNotification }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationsContext);
