import { atomWithStorage } from 'jotai/utils';
import { atom } from 'jotai';

// Create the base atom with storage
const baseIsAuthenticatedAtom = atomWithStorage('isAuthenticated', false, undefined, { getOnInit: true });

// Create a derived atom that logs changes for testing
const isAuthenticatedAtom = atom(
  (get) => get(baseIsAuthenticatedAtom),
  (get, set, newValue) => {
    console.log(`isAuthenticatedAtom updated to: ${JSON.stringify(newValue)}`);
    set(baseIsAuthenticatedAtom, newValue);
  }
);

export { baseIsAuthenticatedAtom as isAuthenticatedAtom };