import React, { useState } from 'react';
import { useCustomer } from '../../providers/CustomerProvider';
import { useCalendarEvents } from '../../contexts/CalendarEventsContext';
import { AccountSection } from './AccountSection';

const AccountCalendarList = ({ account, buttonSize, googleLogin, microsoftLogin }) => {
    const { customer, refetchCustomer } = useCustomer();
    const { makeCalendarPrimary, deleteAccount, toggleCalendarIsUsing } = useCalendarEvents();

    const [useChecked, setChecked] = useState({});

    if (!account.calendars) {
        return <div>No calendars are associated with this account.</div>;
    }

    const handleMakePrimary = async (calendar) => {
        await makeCalendarPrimary(calendar.id);
        refetchCustomer();
    };

    const handleToggleUsing = async (calendar) => {
        const newUsingValue = !calendar.using;
        await toggleCalendarIsUsing({ calendarId: calendar.id, isUsing: newUsingValue });
        setChecked({ ...useChecked, [calendar.id]: newUsingValue });
        refetchCustomer();
    };

    const handleDeleteAccount = async (account) => {
        const confirmed = window.confirm(
            `Are you sure you want to delete the account ${account.email}? This will remove all tasks scheduled using this account. Note: Deleting an account while the tasks are being rescheduled may result in tasks staying on your calendar.`
        );

        if (confirmed) {
            await deleteAccount(account.email);
            refetchCustomer();
        }
    };

    return (
        <AccountSection
            loginGoogle={googleLogin}
            microsoftLogin={microsoftLogin}
            handleToggleUsing={handleToggleUsing}
            buttonSize={buttonSize}
            account={account}
            handleDeleteAccount={handleDeleteAccount}
            handleMakePrimary={handleMakePrimary}
        />
    );
};

export default AccountCalendarList;
