import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { useTasks } from '../../providers/TasksProvider';

const TimeOfDayHeatMapChart = () => {
    const { tasks } = useTasks();
    const chartRef = useRef(null);

    const processedData = useMemo(() => {
        const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
        const timeLabels = [
            'Midnight to 4AM',
            '4AM to 8AM',
            '8AM to Noon',
            'Noon to 4PM',
            '4PM to 8PM',
            '8PM to Midnight',
        ];

        const counts = Array.from({ length: 7 }, () => Array.from({ length: 6 }, () => 0));

        tasks.forEach((task) => {
            const time = new Date(task.createdDate);
            const day = time.getDay();
            const hour = time.getHours();
            const timeLabelIndex = Math.min(Math.floor(hour / 4), 5);
            counts[day][timeLabelIndex]++;
        });

        return { counts, daysOfWeek, timeLabels };
    }, [tasks]);

    useEffect(() => {
        if (chartRef.current && processedData.counts.length > 0) {
            const { counts, daysOfWeek, timeLabels } = processedData;

            const legendWidth = 100;
            const margin = { top: 30, right: 10, bottom: 50, left: 50 };
            const width = 960 - margin.left - margin.right;
            const height = 500 - margin.top - margin.bottom;

            d3.select(chartRef.current).select('svg').remove();
            const svg = d3
                .select(chartRef.current)
                .append('svg')
                .attr('width', width + margin.left + margin.right + legendWidth)
                .attr('height', height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            const x = d3.scaleBand().domain(timeLabels).range([0, width]).padding(0.1);
            const y = d3.scaleBand().domain(daysOfWeek).range([0, height]).padding(0.1);
            const colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, d3.max(counts.flat())]);

            const rects = svg
                .selectAll('rect')
                .data(counts.flat())
                .join('rect')
                .attr('x', (_, i) => x(timeLabels[i % 6]))
                .attr('y', (_, i) => y(daysOfWeek[Math.floor(i / 6)]))
                .attr('height', y.bandwidth())
                .attr('width', x.bandwidth())
                .attr('fill', (d) => colorScale(d))
                .attr('stroke', 'white');

            rects.append('title').text((d) => `${d} tasks`);

            svg.append('g').call(d3.axisLeft(y));
            svg.append('g').attr('transform', `translate(0,${height})`).call(d3.axisBottom(x));

            const legend = svg.append('g').attr('transform', `translate(${width + margin.left},${margin.top})`);

            const legendData = Array.from({ length: 6 }, (_, i) => i * (d3.max(counts.flat()) / 5));

            legend
                .selectAll('rect')
                .data(legendData)
                .join('rect')
                .attr('x', 0)
                .attr('y', (_, i) => i * 20)
                .attr('width', 20)
                .attr('height', 20)
                .attr('fill', (d) => colorScale(d));

            legend
                .selectAll('text')
                .data(legendData)
                .join('text')
                .attr('x', 24)
                .attr('y', (_, i) => i * 20 + 14)
                .attr('font-size', '12px')
                .attr('dominant-baseline', 'central')
                .text((d) => d.toFixed(0));
        }
    }, [processedData]);

    return (
        <div className="flex justify-center mt-10 w-full">
            <div ref={chartRef}></div>
        </div>
    );
};

export default TimeOfDayHeatMapChart;
