import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import { tokenAtom, isTokenExpiredAtom } from '../../../atoms/tokenAtoms';
import { useTaskFilters } from './useTaskFilters';
import { useCustomer } from '../../../providers/CustomerProvider';
import { useSettings } from '../../../atoms/SettingsAtom';
import { useProjects } from '../../utils/projectApi';
import { useTaskActions } from './useTaskActions';
import { isToday } from 'date-fns';

const baseUrl = import.meta.env.VITE_PUBLIC_API_HOST + '/';

export const useTasksHook = (isPlanner = false, selectedFilter = null, selectedCollection = null) => {
    const [token] = useAtom(tokenAtom);
    const [isTokenExpired] = useAtom(isTokenExpiredAtom);
    const { customer, isLoading: isLoadingCustomer } = useCustomer();
    const { settings, toggleSetting } = useSettings();
    const { projectsData } = useProjects();

    const [processedRecurringTasks, setProcessedRecurringTasks] = useState({
        tasks: [],
        parentTaskIds: new Set(),
    });

    const fetchTasks = async () => {
        if (isTokenExpired) {
            throw new Error('Token is expired');
        }

        if (!customer || !customer.accounts) {
            throw new Error('Customer data is not loaded yet');
        }

        const response = await axios.get(baseUrl + 'api/tasks', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const processedTasks = response.data.map((task) => ({
                ...task,
                projectId: task.project?.id || null,
            }));

            return processedTasks;
        } else {
            throw new Error('Failed to fetch tasks: ' + response.statusText);
        }
    };

    const {
        data: tasks,
        isLoading: isLoadingTasks,
        error,
        refetch,
    } = useQuery('tasks', fetchTasks, {
        enabled: !!token && !!customer && !!customer.accounts,
        retry: 5,
        retryDelay: (attempt) => Math.min(attempt * 1000, 30000),
        staleTime: 5 * 60 * 1000,
    });

    const processRecurringTasks = useCallback(
        (showFuture) => {
            if (!tasks) return;

            const closestRecurringTasks = new Map();
            const parentTaskIds = new Set();

            tasks.forEach((task) => {
                if (typeof task !== 'object' || task === null) return;

                task.isRecurringParent = false;
                task.isRecurringChild = false;

                if (task.recurrenceRule && task.recurrenceRule.toUpperCase() !== 'ONCE') {
                    parentTaskIds.add(task.id);
                    task.isRecurringParent = true;
                }

                if (task.type === 'RecurringTask') {
                    const parentTaskId = task.parentTask.id;
                    parentTaskIds.add(parentTaskId);
                    task.isRecurringChild = true;
                    const existingTask = closestRecurringTasks.get(parentTaskId);
                    if (!existingTask || new Date(task.requiredDate) < new Date(existingTask.requiredDate)) {
                        closestRecurringTasks.set(parentTaskId, task);
                    }
                }
            });

            const processedTasks = tasks.filter((task) => {
                if (showFuture) {
                    return true;
                }
                if (task.type === 'RecurringTask') {
                    return closestRecurringTasks.get(task.parentTask.id) === task;
                }
                return !parentTaskIds.has(task.id);
            });

            setProcessedRecurringTasks({
                tasks: processedTasks,
                parentTaskIds,
            });
        },
        [tasks]
    );

    useEffect(() => {
        processRecurringTasks(settings.showFuture);
    }, [tasks, settings.showFuture, processRecurringTasks]);

    const {
        tasksAccounted,
        tasksAccountedFiltered,
        tasksPastDue,
        tasksDueTodayOrPast,
        tasksCriticalFiltered,
        tasksOpportunityNowFiltered,
        tasksOverTheHorizonFiltered,
        tasksParkingLotFiltered,
        tasksSchedulingIssues,
        todaysTasks,
        getPlanningActivities,
    } = useTaskFilters(processedRecurringTasks.tasks);

    const getFilteredTasks = useCallback(
        (selectedFilter) => {
            if (!tasks) return [];

            let filtered;
            switch (selectedFilter) {
                case 'TOTAL_TASKS':
                    filtered = tasksAccounted;
                    break;
                case 'TODAYS_TASKS':
                    filtered = todaysTasks;
                    break;
                case 'PAST_DUE':
                    filtered = tasksPastDue;
                    break;
                case 'DUE_TODAY':
                    filtered = tasksDueTodayOrPast;
                    break;
                case 'CRITICAL':
                    filtered = tasksCriticalFiltered;
                    break;
                case 'OPPORTUNITY_NOW':
                    filtered = tasksOpportunityNowFiltered;
                    break;
                case 'OVER_THE_HORIZON':
                    filtered = tasksOverTheHorizonFiltered;
                    break;
                case 'PARKING_LOT':
                    filtered = tasksParkingLotFiltered;
                    break;
                case 'SCHEDULING_ISSUES':
                    filtered = tasksSchedulingIssues;
                    break;
                default:
                    filtered = tasksAccountedFiltered;
            }

            return filtered;
        },
        [
            tasks,
            tasksAccounted,
            tasksAccountedFiltered,
            todaysTasks,
            tasksPastDue,
            tasksDueTodayOrPast,
            tasksCriticalFiltered,
            tasksOpportunityNowFiltered,
            tasksOverTheHorizonFiltered,
            tasksParkingLotFiltered,
            tasksSchedulingIssues,
        ]
    );

    const prettyEventsView = useCallback((events) => {
        if (!events || events.length === 0) {
            return {};
        }

        let finalObj = {};

        events.forEach((event) => {
            if (event === undefined) {
                console.error('undefined event');
            } else {
                let date;

                if (event.calendarEvent && event.calendarEvent.start) {
                    date = new Date(event.calendarEvent.start);
                } else if (event.startDate) {
                    date = new Date(event.startDate);
                }

                if (date && !isNaN(date.getTime())) {
                    date = new Date(date.toLocaleDateString());

                    if (!event.calendarEvent && date < new Date()) {
                        date = new Date();
                    }

                    const dateKey = date.toLocaleDateString();

                    if (finalObj[dateKey]) {
                        finalObj[dateKey].push(event);
                    } else {
                        finalObj[dateKey] = [event];
                    }
                } else {
                    console.error('Invalid date for event:', event);
                }
            }
        });

        return finalObj;
    }, []);

    const filteredTasks = useMemo(() => {
        if (!tasks) {
            return [];
        }

        let filtered = tasks;

        if (selectedFilter) {
            filtered = getFilteredTasks(selectedFilter);
        } else if (selectedCollection) {
            filtered = tasks.filter((task) => {
                if (!task.projectId) {
                    return selectedCollection === 'INBOX';
                }
                const project = projectsData?.find((p) => p.id === task.projectId);
                return project?.type === selectedCollection;
            });
        }

        // Apply additional filtering based on settings
        filtered = filtered.filter((task) => {
            if (!settings.showCompleted && task.isCompleted) {
                return false;
            }
            if (!settings.showFuture && new Date(task.startDate) > new Date() && !isToday(new Date(task.startDate))) {
                return false;
            }
            return true;
        });

        return filtered;
    }, [tasks, selectedFilter, selectedCollection, getFilteredTasks, projectsData, settings]);

    const filteredPrettyEvents = useMemo(() => {
        if (!filteredTasks || filteredTasks.length === 0) {
            return { filteredPrettyEvents: {}, filteredPrettyEventsKeys: [], filteredPrettyEventsSorted: [] };
        }

        const prettyEvents = prettyEventsView(filteredTasks);

        const keys = Object.keys(prettyEvents);
        const sortedKeys = [...keys].sort((a, b) => {
            const dateA = new Date(a).getTime();
            const dateB = new Date(b).getTime();
            return dateA - dateB;
        });

        return {
            filteredPrettyEvents: prettyEvents,
            filteredPrettyEventsKeys: keys,
            filteredPrettyEventsSorted: sortedKeys,
        };
    }, [filteredTasks, prettyEventsView]);

    return {
        tasks: tasks,
        isLoading: isLoadingCustomer || isLoadingTasks,
        error,
        refetch,
        getFilteredTasks,
        filteredTasks,
        getPlanningActivities,
        processedRecurringTasks,
        ...filteredPrettyEvents,
    };
};

export default useTasksHook;
// const createRandomTasks = useCallback(async () => {
//     const randomTasks = Array.from({ length: 100 }, () => {
//         const startDate = new Date().toISOString();
//         const dueDate = new Date(Date.now() + Math.random() * 10000000000).toISOString();

//         return {
//             id: 'new',
//             title: faker.lorem.sentence(),
//             isCompleted: false,
//             ownerId: customer.id,
//             startDate,
//             dueDate,
//             createdDate: new Date().toISOString(),
//             lastUpdated: new Date().toISOString(),
//             completedDate: null,
//             notes: faker.lorem.paragraph(),
//             priority: ['CRITICAL', 'OPPORTUNITY_NOW', 'OVER_THE_HORIZON', 'PARKING_LOT'][
//                 Math.floor(Math.random() * 4)
//             ],
//             category: 'REGULAR',
//             isRecurringParent: false,
//             isRecurringChild: false,
//             recurrenceRule: 'ONCE',
//             type: 'Task',
//             project: null,
//             parentTask: null,
//         };
//     });

//     console.log('Created ' + randomTasks.length + ' random tasks');

//     for (const task of randomTasks) {
//         try {
//             await saveTask(task);
//             await new Promise((resolve) => setTimeout(resolve, 500)); // 500ms delay
//         } catch (error) {
//             console.error('Error saving task:', error);
//         }
//     }

//     queryClient.invalidateQueries('tasks');
// }, [customer.id, queryClient, saveTask]);
