import React, { useState, useLayoutEffect } from 'react';

export function useIsLandscapeAndLargeScreen() {
    const [isLandscapeAndLargeScreen, setIsLandscapeAndLargeScreen] = useState(false);

    useLayoutEffect(() => {
        function updateScreen() {
            const isLandscape = window.matchMedia('(orientation: landscape)').matches;
            const isLargeScreen = window.matchMedia('(min-width: 1200px)').matches; // Adjust min-width as needed
            setIsLandscapeAndLargeScreen(isLandscape && isLargeScreen);
        }

        window.addEventListener('resize', updateScreen);
        updateScreen(); // Initial check

        return () => window.removeEventListener('resize', updateScreen);
    }, []);

    return isLandscapeAndLargeScreen;
}

export default useIsLandscapeAndLargeScreen;
