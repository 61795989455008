import { useMemo } from 'react';

import { useCustomer } from '../../../providers/CustomerProvider';
import { useSettings } from '../../../atoms/SettingsAtom';

export const useTaskFilters = (tasks) => {
    const { customer } = useCustomer();
    const { settings } = useSettings();

    const getLocalDate = (date) => {
        return new Date(date.toLocaleString('en-US', { timeZone: customer?.defaultTimeZone }));
    };

    const isToday = (date) => {
        const dateToCheck = getLocalDate(new Date(date));
        const today = getLocalDate(new Date());
        return (
            dateToCheck.getUTCDate() === today.getUTCDate() &&
            dateToCheck.getUTCMonth() === today.getUTCMonth() &&
            dateToCheck.getUTCFullYear() === today.getUTCFullYear()
        );
    };

    const startToday = (dateString) => {
        const date = getLocalDate(new Date(dateString));
        const today = getLocalDate(new Date());
        return (
            date.getUTCFullYear() < today.getUTCFullYear() ||
            (date.getUTCFullYear() === today.getUTCFullYear() && date.getUTCMonth() < today.getUTCMonth()) ||
            (date.getUTCFullYear() === today.getUTCFullYear() &&
                date.getUTCMonth() === today.getUTCMonth() &&
                date.getUTCDate() <= today.getUTCDate())
        );
    };
    const filterTasks = (priorityFilter) => {
        if (!tasks) return [];
        return tasks.filter(
            (task) =>
                task.priority === priorityFilter &&
                (!task.isCompleted || settings.showCompleted) &&
                (settings.showFuture || new Date(task.startDate) < new Date() || isToday(task.startDate))
        );
    };

    const nonRecurringTasks = useMemo(() => {
        if (!tasks) return [];
        return tasks.filter((task) => !task.recurrenceRule || task.recurrenceRule.toUpperCase() === 'ONCE');
    }, [tasks]);

    const tasksPastDue = useMemo(() => {
        if (!nonRecurringTasks) return [];
        const filteredTasks = nonRecurringTasks.filter((task) => {
            if (!task.dueDate) return false;
            const dueDate = new Date(task.dueDate);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            dueDate.setHours(0, 0, 0, 0);
            return dueDate < today;
        });
        return filteredTasks;
    }, [nonRecurringTasks]);

    const tasksDueTodayOrPast = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter((task) => {
            if (!task.dueDate) return false;
            const dueDate = new Date(task.dueDate);
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return dueDate <= today;
        });
    }, [nonRecurringTasks]);

    const tasksCritical = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter((task) => task.priority === 'CRITICAL');
    }, [nonRecurringTasks]);

    const tasksOpportunityNow = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter((task) => task.priority === 'OPPORTUNITY_NOW');
    }, [nonRecurringTasks]);

    const tasksOverTheHorizon = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter((task) => task.priority === 'OVER_THE_HORIZON');
    }, [nonRecurringTasks]);

    const tasksParkingLot = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter((task) => task.priority === 'PARKING_LOT');
    }, [nonRecurringTasks]);

    const tasksCriticalFiltered = useMemo(() => {
        if (!nonRecurringTasks) return [];
        const filteredTasks = nonRecurringTasks.filter(
            (task) =>
                task.priority === 'CRITICAL' &&
                (!task.isCompleted || settings.showCompleted) &&
                (settings.showFuture || new Date(task.startDate) < new Date() || isToday(task.startDate))
        );
        return filteredTasks;
    }, [nonRecurringTasks, settings.showCompleted, settings.showFuture]);

    const tasksOpportunityNowFiltered = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                task.priority === 'OPPORTUNITY_NOW' &&
                (!task.isCompleted || settings.showCompleted) &&
                (settings.showFuture || new Date(task.startDate) < new Date() || isToday(task.startDate))
        );
    }, [nonRecurringTasks, settings.showCompleted, settings.showFuture]);

    const tasksOverTheHorizonFiltered = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                task.priority === 'OVER_THE_HORIZON' &&
                (!task.isCompleted || settings.showCompleted) &&
                (settings.showFuture || new Date(task.startDate) < new Date() || isToday(task.startDate))
        );
    }, [nonRecurringTasks, settings.showCompleted, settings.showFuture]);

    const tasksParkingLotFiltered = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                task.priority === 'PARKING_LOT' &&
                (!task.isCompleted || settings.showCompleted) &&
                (settings.showFuture || new Date(task.startDate) < new Date() || isToday(task.startDate))
        );
    }, [nonRecurringTasks, settings.showCompleted, settings.showFuture]);

    const tasksAccounted = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return [...tasksCritical, ...tasksOpportunityNow, ...tasksOverTheHorizon, ...tasksParkingLot];
    }, [tasksCritical, tasksOpportunityNow, tasksOverTheHorizon, tasksParkingLot]);

    const tasksAccountedFiltered = useMemo(() => {
        if (!tasksAccounted) return [];
        const parentTaskIdsToExclude = new Set(
            nonRecurringTasks
                .filter((task) => task.recurrenceRule && task.recurrenceRule.toUpperCase() !== 'ONCE')
                .map((task) => task.id)
        );

        return tasksAccounted.filter((task) => !parentTaskIdsToExclude.has(task.id));
    }, [tasksAccounted, nonRecurringTasks]);

    const tasksSchedulingIssues = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) => task.constraintViolation != null && task.constraintViolation !== undefined
        );
    }, [nonRecurringTasks]);

    const uncompletedTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter((task) => task.isCompleted?.toString() !== 'true');
    }, [nonRecurringTasks]);

    const todaysTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) => startToday(task.startDate) || isToday(task.startDate) || task.googleEvent
        );
    }, [nonRecurringTasks]);

    const todaysUncompletedTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) => !task.isCompleted && (startToday(task.startDate) || isToday(task.startDate))
        );
    }, [nonRecurringTasks]);

    const todaysUncompletedOverTheHorizonAndParkingLotTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                (task.priority === 'OVER_THE_HORIZON' || task.priority === 'PARKING_LOT') &&
                (task.isCompleted == null || task.isCompleted?.toString() !== 'true') &&
                (startToday(task.startDate) || isToday(task.startDate))
        );
    }, [nonRecurringTasks]);

    const todaysUncompletedOverTheHorizonTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                task.priority === 'OVER_THE_HORIZON' &&
                (task.isCompleted == null || task.isCompleted?.toString() !== 'true') &&
                (startToday(task.startDate) || isToday(task.startDate))
        );
    }, [nonRecurringTasks]);

    const todaysUncompletedParkingLotTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                task.priority === 'PARKING_LOT' &&
                (task.isCompleted == null || task.isCompleted?.toString() !== 'true') &&
                (startToday(task.startDate) || isToday(task.startDate))
        );
    }, [nonRecurringTasks]);

    const todaysUncompletedOpportunityNowTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                task.priority === 'OPPORTUNITY_NOW' &&
                (task.isCompleted == null || task.isCompleted?.toString() !== 'true') &&
                (startToday(task.startDate) || isToday(task.startDate))
        );
    }, [nonRecurringTasks]);

    const todaysUncompletedCriticalTasks = useMemo(() => {
        if (!nonRecurringTasks) return [];
        return nonRecurringTasks.filter(
            (task) =>
                task.priority === 'CRITICAL' &&
                (task.isCompleted == null || task.isCompleted?.toString() !== 'true') &&
                (startToday(task.startDate) || isToday(task.startDate))
        );
    }, [nonRecurringTasks]);

    const getPlanningActivities = useMemo(() => {
        const plans = [];

        if (todaysUncompletedParkingLotTasks.length > 0) {
            plans.push('Plan your Parking Lot tasks');
        }
        if (todaysUncompletedOverTheHorizonTasks.length > 0) {
            plans.push('Plan your Over The Horizon Tasks');
        }
        if (todaysUncompletedOpportunityNowTasks.length > 20) {
            plans.push(`Too many Opportunity Now tasks (${todaysUncompletedOpportunityNowTasks.length}/20)`);
        }
        if (todaysUncompletedCriticalTasks.length > 5) {
            plans.push(`Too many Critical tasks (${todaysUncompletedCriticalTasks.length}/5)`);
        }

        return plans;
    }, [
        todaysUncompletedParkingLotTasks,
        todaysUncompletedOverTheHorizonTasks,
        todaysUncompletedOpportunityNowTasks,
        todaysUncompletedCriticalTasks,
    ]);

    return {
        tasksPastDue,
        tasksDueTodayOrPast,
        tasksCritical,
        tasksOpportunityNow,
        tasksOverTheHorizon,
        tasksParkingLot,
        tasksCriticalFiltered,
        tasksOpportunityNowFiltered,
        tasksOverTheHorizonFiltered,
        tasksParkingLotFiltered,
        tasksAccounted,
        tasksAccountedFiltered,
        tasksSchedulingIssues,
        uncompletedTasks,
        todaysTasks,
        todaysUncompletedTasks,
        todaysUncompletedOverTheHorizonAndParkingLotTasks,
        todaysUncompletedOverTheHorizonTasks,
        todaysUncompletedParkingLotTasks,
        todaysUncompletedOpportunityNowTasks,
        todaysUncompletedCriticalTasks,
        getPlanningActivities,
    };
};
