import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useCalendarEvents } from '../../contexts/CalendarEventsContext';

const UpcomingEventsWidget = () => {
    const { calendarEvents, isLoading } = useCalendarEvents();
    const [events, setEvents] = useState([]);
    const [key, setKey] = useState(0);
    const calendarRef = useRef(null);
    const [hasReceivedEvents, setHasReceivedEvents] = useState(false);

    const processEvents = useCallback(() => {
        if (!calendarEvents || !Array.isArray(calendarEvents)) return [];

        const now = new Date();
        const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        const oneHourAgo = new Date(now.getTime() - 1 * 60 * 60 * 1000);
        const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        let futureEvents = calendarEvents.filter((event) => {
            let eventStart = new Date(event.start);
            const eventEnd = event.end ? new Date(event.end) : eventStart;

            // Change the start date to start of today if it is more than 24 hours old
            if (eventStart < twentyFourHoursAgo && eventEnd > now) {
                event.start = startOfToday.toISOString();
                eventStart = startOfToday;
            }

            // Filter events that haven't ended more than an hour ago
            return eventEnd >= oneHourAgo;
        });

        futureEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

        return futureEvents.slice(0, 10);
    }, [calendarEvents]);

    useEffect(() => {
        if (!isLoading && calendarEvents && calendarEvents.length > 0) {
            const processedEvents = processEvents();
            setEvents(processedEvents);
            setKey((prevKey) => prevKey + 1);
            setHasReceivedEvents(true);
        }
    }, [calendarEvents, isLoading, processEvents]);

    useEffect(() => {
        if (calendarRef.current && events.length > 0) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.removeAllEvents();
            calendarApi.addEventSource(events);

            const today = new Date();
            const eventsToday = events.filter((event) => {
                const eventStart = new Date(event.start);
                return (
                    eventStart.getDate() === today.getDate() &&
                    eventStart.getMonth() === today.getMonth() &&
                    eventStart.getFullYear() === today.getFullYear()
                );
            });

            if (eventsToday.length === 0) {
                const nextEvent = events.find((event) => new Date(event.start) > today);
                if (nextEvent) {
                    calendarApi.gotoDate(new Date(nextEvent.start));
                }
            } else {
                calendarApi.gotoDate(today);
            }
        }
    }, [events]);

    const handleEventClick = useCallback((info) => {
        console.log('Event clicked:', info.event);
    }, []);

    const renderEventContent = useCallback((eventInfo) => {
        const MAX_TITLE_LENGTH = 40;
        const { event } = eventInfo;
        const title =
            event.title.length > MAX_TITLE_LENGTH ? `${event.title.substring(0, MAX_TITLE_LENGTH)}...` : event.title;

        const now = new Date();
        const eventStart = new Date(event.start);
        const eventEnd = event.end ? new Date(event.end) : new Date(event.start);

        const isCurrentEvent = eventStart <= now && now <= eventEnd;
        const backgroundColor = isCurrentEvent ? 'bg-green-50' : '';

        const isCompletedTask = event.extendedProps.task && event.extendedProps.task.isCompleted;
        const strikeClass = isCompletedTask ? 'line-through' : '';

        if (event.extendedProps.isMultiDay) {
            const startDate = eventStart.toLocaleDateString();
            const endDate = new Date(event.extendedProps.multiDayEventEndDate).toLocaleDateString();

            return (
                <div
                    title={event.title}
                    className={`p-0 m-0 rounded b-0 font-sfCompactDisplay ${backgroundColor} ${strikeClass}`}
                >
                    {title} ({startDate} - {endDate})
                </div>
            );
        } else {
            return (
                <div
                    title={event.title}
                    className={`p-0 m-0 rounded b-0 font-sfCompactDisplay ${backgroundColor} ${strikeClass}`}
                >
                    {title}
                </div>
            );
        }
    }, []);

    const highlightOverlappingRow = useCallback((info) => {
        const now = new Date();
        const eventStart = new Date(info.event.start);
        const eventEnd = info.event.end ? new Date(info.event.end) : new Date(eventStart);

        if (eventStart <= now && now <= eventEnd) {
            const rowEl = info.el.closest('tr');
            if (rowEl) {
                rowEl.classList.add('bg-green-50');
            }
        }
    }, []);

    const calendar = useMemo(
        () => (
            <FullCalendar
                key={key}
                ref={calendarRef}
                events={events}
                height="auto"
                contentHeight="100%"
                eventDidMount={highlightOverlappingRow}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
                initialView="listMonth"
                eventContent={renderEventContent}
                scrollTime="08:00:00"
                weekends={true}
                handleWindowResize={true}
                editable={false}
                selectable={false}
                selectMirror={true}
                dayMaxEvents={true}
                expandRows={false}
                eventTextColor="white"
                eventBorderColor="red"
                nowIndicator={true}
                eventClick={handleEventClick}
                headerToolbar={false}
                titleFormat={{
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                }}
            />
        ),
        [events, key, highlightOverlappingRow, renderEventContent, handleEventClick]
    );

    if (isLoading) {
        return <div>Loading events...</div>;
    }

    return (
        <div className="upcoming-events">
            <div className="flex">
                <div className="flex-1 mb-2 ml-0 text-3xl font-medium text-black">Upcoming Events</div>
            </div>

            {events.length === 0 ? (
                <div className="flex justify-center">
                    There are no events. Have you&nbsp;
                    <a href="/settings" className="underline">
                        connected a calendar
                    </a>
                    ?
                </div>
            ) : (
                <div className="w-full">{calendar}</div>
            )}
        </div>
    );
};

export default React.memo(UpcomingEventsWidget);
