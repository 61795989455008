import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ScheduleSend, CancelScheduleSend } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import { motion } from 'framer-motion';
import { useTasks } from '../../providers/TasksProvider';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { Task } from '../../domain/Task'; // Import the Task type

interface RecurringTaskProps {
    title: string;
    completed: boolean;
    icon: React.ReactNode;
    task: Task; // Use the Task type here
    openPriorityPanel: (event: React.MouseEvent, task: Task) => void;
}

const RecurringTask: React.FC<RecurringTaskProps> = ({ title, completed, icon, task, openPriorityPanel }) => {
    const [isScheduleChipActive, setIsScheduleChipActive] = useState(false);
    const { selectTask, saveTask } = useTasks();
    const { openTaskModal } = useTaskModal();

    let borderColor;

    switch (task.priority) {
        case 'CRITICAL':
            borderColor = '#0C803D'; // Sea Green
            break;
        case 'OPPORTUNITY_NOW':
            borderColor = '#107CC4'; // Star Command Blue
            break;
        case 'OVER_THE_HORIZON':
            borderColor = '#F9913B'; // Cadmium Orange
            break;
        case 'PARKING_LOT':
            borderColor = '#F04F23'; // Coquelicot
            break;
        // Add additional cases as necessary
        default:
            borderColor = '#000000'; // Default color if priority is unknown
    }

    const toggleActive = () => {
        setIsScheduleChipActive(!isScheduleChipActive);
    };

    const toggleScheduling = useCallback(
        async (event, task) => {
            event.stopPropagation();

            // Update the task's scheduling status
            const updatedTask = {
                ...task,
                isAutoScheduled: !task.isAutoScheduled,
                schedulingState: !task.isAutoScheduled ? 'scheduling_requested' : 'scheduling_cancel_requested',
            };

            // Save the updated task
            await saveTask(updatedTask);
        },
        [saveTask]
    );

    // Framer Motion animation variants
    const variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        hover: { scale: 1.05 },
    };

    const handleTaskClicked = useCallback(
        (e, task) => {
            e.stopPropagation();
            if (task.isRecurringParent || task.isRecurringChild) {
                const taskToSelect = task.isRecurringChild ? task.parentTask : task;
                selectTask(taskToSelect.id);
                openTaskModal(taskToSelect); // Use the openTaskModal function from the hook
            } else {
                selectTask(task.id);
                openTaskModal(task); // Use the openTaskModal function from the hook
            }
        },
        [selectTask, openTaskModal] // Include openTaskModal in dependencies
    );

    return (
        <motion.div
            className="flex flex-col mb-1 bg-white rounded-lg shadow transition duration-150 ease-in-out"
            style={{
                borderLeftColor: borderColor,
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
                minWidth: '200px',
            }}
            initial="hidden"
            animate="visible"
            whileHover="hover"
            variants={variants}
            onClick={(e) => handleTaskClicked(e, task)}
        >
            <div className="px-4 pt-3">
                <p className="text-lg text-gray-900">{task.title}</p>
            </div>
            <div className="flex justify-between items-center px-4 py-1">
                <motion.div
                    className="flex justify-end items-center"
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                >
                    <Chip
                        variant="outlined"
                        label={!task.isAutoScheduled ? 'Schedule' : 'Cancel/Pause'}
                        size="small"
                        color="primary"
                        icon={!task.isAutoScheduled ? <ScheduleSend /> : <CancelScheduleSend />}
                        onClick={(event) => {
                            toggleScheduling(event, task);
                            toggleActive();
                        }}
                        className={`text-xs ${isScheduleChipActive ? 'text-white bg-blue-500' : 'text-blue-500 border-blue-500'}`}
                    />

                    {!task.isCompleted && (
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="ml-4 text-xl cursor-pointer"
                            style={{ color: borderColor }}
                            title="Change priority"
                            onClick={(e) => openPriorityPanel(e, task)}
                        />
                    )}
                </motion.div>
            </div>
        </motion.div>
    );
};

export default RecurringTask;
