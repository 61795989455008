import React, { lazy, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Capacitor } from '@capacitor/core';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faFileImport, faCreditCard, faCloud } from '@fortawesome/free-solid-svg-icons';
import { AccountInfo } from './AccountInfo';
import { ScheduleEditor } from './ScheduleEditor';
import { useParams, useNavigate } from 'react-router-dom';

const UploadCSV = lazy(() => import('../UploadCSV'));
const Billing = lazy(() => import('./Billing'));

const Settings = ({ success }) => {
    const { tab } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(tab || 'account');

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD', {});
        ReactGA.send({ hitType: 'pageview', page: '/settings', title: 'Settings' });
    }, []);

    useEffect(() => {
        if (tab && tabItems.some((item) => item.key === tab)) {
            setActiveTab(tab);
        } else {
            navigate('/settings/account', { replace: true });
        }
    }, [tab, navigate]);

    const tabItems = [
        { key: 'account', label: 'Account', icon: faUser, component: <AccountInfo /> },
        { key: 'scheduling', label: 'Scheduling', icon: faClock, component: <ScheduleEditor /> },
        { key: 'import', label: 'Import/Export', icon: faFileImport, component: <ImportExport /> },
        { key: 'billing', label: 'Billing', icon: faCreditCard, component: <Billing success={success} /> },
    ];

    try {
        if (Capacitor.isNativePlatform()) {
            return <MobileSettings success={success} />;
        }

        const handleTabChange = (newTab) => {
            navigate(`/settings/${newTab}`);
        };

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="p-0 bg-gradient-to-br from-blue-50 to-blue-100"
            >
                <div className="mx-auto" style={{ maxWidth: '95%' }}>
                    <h1 className="flex items-center mb-8 text-4xl font-bold text-blue-800">
                        <FontAwesomeIcon icon={faCloud} className="mr-4 text-blue-500" />
                        Settings
                    </h1>
                    <div className="overflow-hidden bg-blue-50 rounded-lg shadow-lg">
                        <div className="flex">
                            <div className="flex-shrink-0 p-0 w-48 bg-blue-50">
                                {tabItems.map((item) => (
                                    <TabButton
                                        key={item.key}
                                        item={item}
                                        activeTab={activeTab}
                                        setActiveTab={handleTabChange}
                                    />
                                ))}
                            </div>
                            <div className="flex-grow p-6">
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        key={activeTab}
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: -20 }}
                                        transition={{ duration: 0.3 }}
                                        className="h-full"
                                    >
                                        {tabItems.find((item) => item.key === activeTab)?.component}
                                    </motion.div>
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    } catch (error) {
        console.error('Error in Settings component:', error);
        return <div>An error occurred. Please try again.</div>;
    }
};

const TabButton = ({ item, activeTab, setActiveTab }) => (
    <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setActiveTab(item.key)}
        className={`w-full text-left py-3 px-4 rounded-lg mb-2 transition-colors duration-200 flex items-center ${
            activeTab === item.key ? 'bg-blue-600 text-white' : 'text-blue-600 hover:bg-blue-200'
        }`}
    >
        <FontAwesomeIcon icon={item.icon} className="mr-3" />
        {item.label}
    </motion.button>
);

const ImportExport = () => (
    <div className="space-y-4">
        <h2 className="mb-4 text-2xl font-semibold text-blue-800">Import/Export</h2>
        <p className="text-blue-600">
            Mind Your Now can import your tasks from a list of tasks, or any CSV file containing the task as the first
            column.
        </p>
        <a
            href="https://www.toodledo.com/tools/csv.php"
            target="_blank"
            rel="noreferrer"
            className="block mb-4 text-blue-500 underline hover:text-blue-700"
        >
            Download Toodledo® List
        </a>
        <UploadCSV />
    </div>
);

const MobileSettings = ({ success }) => (
    <div className="p-4 min-h-screen bg-blue-50">
        <h1 className="mb-6 text-3xl font-bold text-blue-800">Settings</h1>
        <AccountInfo />
        {Capacitor.isNativePlatform() && import.meta.env.VITE_PUBLIC_MOBILE_BILLING === 'true' && (
            <div className="mt-8">
                <h2 className="mb-4 text-2xl font-semibold text-blue-800">Billing</h2>
                <Billing success={success} />
            </div>
        )}
        <div className="p-4 mt-8 bg-white rounded-lg shadow">
            <p className="text-blue-600">
                For other account changes, please visit us online at{' '}
                <a href="https://app.mindyournow.com" className="text-blue-500 underline hover:text-blue-700">
                    app.mindyournow.com
                </a>
            </p>
        </div>
    </div>
);

export default Settings;
