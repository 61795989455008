import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMicrosoftCalendarEvents } from '../tasks/hooks/useMicrosoftCalendarEvents';

export function MsCallback() {
    console.log('MsCallback');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const navigate = useNavigate();
    const { fetchMicrosoftCode } = useMicrosoftCalendarEvents();

    useEffect(() => {
        // Call your backend API with the code here, and perform any necessary actions
        // ...

        fetchMicrosoftCode(code).then((response) => {
            console.log('Result from backend');
            console.log(response);

            // Close this popup window
            window.close();
        });

        // Navigate to the desired page, passing any necessary state or props
        // navigate('/home', { state: { fromCallback: true } });
    }, [code, navigate, fetchMicrosoftCode]);

    return <div>Processing authorization code...</div>;
}
export default MsCallback;
