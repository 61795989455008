import { Capacitor } from "@capacitor/core";
import React, { useState } from "react";

const PreviewBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    const [isMobile] = useState(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios');

    return (
        showBanner && (
            <div className="flex items-center justify-center h-16 text-center bg-mynopportunitynow-400">
                <p className={`text-lg text-center text-mynyellow-400 ${isMobile ? 'text-sm' : ''}`}>
                    {isMobile ? (
                        <>
                            Preview Release --{" "}
                            <a className="text-mynyellow-400" href="https://mindyournow.com/blog/ladybug-update" target="_blank" rel="noreferrer">
                                Learn More
                            </a>
                        </>
                    ) : (
                        <>
                            Preview Release: Features are subject to change and may experience issues.{" "}
                            <a className="text-mynyellow-400" href="https://mindyournow.com/blog/ladybug-update" target="_blank" rel="noreferrer">
                                Learn More
                            </a>
                        </>
                    )}
                </p>

                <button
                    className="p-2 ml-4 text-white fill-current"
                    onClick={() => setShowBanner(false)}
                >
                    <svg className="w-6 h-6" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    </svg>
                </button>
            </div>
        )
    );


};

export default PreviewBanner;
