import React, { useState } from 'react';

const CalendarIcon = ({ timezone, onClick, size = 22 }) => {
    const [isHovered, setIsHovered] = useState(false);
    const currentDate = new Date().toLocaleString('en-US', { timeZone: timezone });
    const day = currentDate.split(',')[0].split('/')[1];

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={size}  // Use the prop or default to 22
            height={size}  // Use the prop or default to 22
            style={{ display: 'block', cursor: 'pointer' }}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <rect x="1" y="1" width="22" height="22" fill="none" stroke="#008000" strokeWidth="1" />
            <rect x="1" y="1" width="22" height="2" fill="none" stroke="#008000" strokeWidth="1" />
            <text
                x="12"
                y="15"
                textAnchor="middle"
                fontSize="12"
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
                fill={isHovered ? 'white' : '#008000'}
                dominantBaseline="middle"
            >
                {day}
            </text>
            <line x1="6" y1="6" x2="18" y2="6" stroke={isHovered ? 'white' : '#008000'} strokeWidth="1" />
        </svg>
    );
};

export default CalendarIcon;
