import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { isAuthenticatedAtom } from '../../atoms/authAtoms';
import { setTokenAtom } from '../../atoms/tokenAtoms';
import ReactGA from 'react-ga4';

const MagicLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
    const [, setToken] = useAtom(setTokenAtom);
    const authAttemptedRef = useRef(false);

    const searchParams = new URLSearchParams(location.search);
    const exchangeToken = searchParams.get('token');
    const directToken = searchParams.get('directtoken');

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD');
        ReactGA.send({ hitType: "pageview", page: '/magic-login', title: "Magic Login" });
    }, []);

    useEffect(() => {
        const authenticate = async () => {
            if (authAttemptedRef.current) return;
            authAttemptedRef.current = true;

            if (directToken) {
                setToken(directToken);
                setIsAuthenticated(true);
                ReactGA.event({
                    category: 'User',
                    action: 'Logged In (Direct Token)'
                });
                navigate('/home');
                return;
            }

            if (!exchangeToken) {
                navigate('/GettingStarted', { state: { error: 'No token provided' } });
                return;
            }

            try {
                const response = await fetch(`${import.meta.env.VITE_PUBLIC_API_HOST}/api/exchange-magic-link`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({ exchangeToken }),
                    compress: false,
                });

                const data = await response.json();

                if (response.ok && data.status === 'success') {
                    setToken(data.token);
                    setIsAuthenticated(true);
                    ReactGA.event({
                        category: 'User',
                        action: 'Logged In (Exchange Token)'
                    });
                    navigate('/home');
                } else {
                    console.error('Authentication failed:', data.message);
                    navigate('/GettingStarted', { state: { error: data.message || 'Authentication failed' } });
                }
            } catch (error) {
                console.error('Authentication error:', error);
                navigate('/GettingStarted', { state: { error: 'An unexpected error occurred' } });
            }
        };

        authenticate();
    }, [exchangeToken, directToken, navigate, setIsAuthenticated, setToken]);

    // ... rest of the component remains the same
};

export default MagicLogin;