import React from 'react';
import { CircularProgress } from '@mui/material';

const LoadingOverlay = () => (
    <div className="flex absolute inset-0 z-50 justify-center items-center bg-white bg-opacity-70">
        <CircularProgress />
    </div>
);

export default LoadingOverlay;
