import React, { useEffect, useCallback, useState, useRef } from 'react';
import SockJS from 'sockjs-client';
import { over } from 'stompjs';
import { jwtDecode } from 'jwt-decode';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';
import { useAtom } from 'jotai';
import { tokenAtom, isTokenExpiredAtom } from '../atoms/tokenAtoms';
import { stompClientAtom } from '../atoms/webSocketAtom';
import { useQueryClient } from 'react-query';

import { useCalendarEvents } from '../contexts/CalendarEventsContext';
import { useCustomer } from '../providers/CustomerProvider';
import { useCurrentPicture } from '../contexts/CurrentPictureContext';

const StompClientConnector = () => {
    const [sessionId] = React.useState(uuid());
    const { enqueueSnackbar } = useSnackbar();
    const [stompClient, setStompClient] = useAtom(stompClientAtom);
    const [token] = useAtom(tokenAtom);
    const [isTokenExpired] = useAtom(isTokenExpiredAtom);

    const { refetchCalendarEvents } = useCalendarEvents();
    const { customer, refetchCustomer } = useCustomer();

    const [reconnectAttempt, setReconnectAttempt] = useState(0);
    const maxReconnectAttempts = 5;
    const baseReconnectDelay = 1000; // 1 second

    const { updateCurrentPicture } = useCurrentPicture();

    const isConnectedRef = useRef(false);
    const reconnectTimeoutRef = useRef(null); // Add a ref to store the timeout ID

    const onPictureReceived = useCallback(
        (message) => {
            try {
                const pictureData = JSON.parse(message.body);
                updateCurrentPicture(pictureData);
            } catch (error) {
                console.error('Error parsing picture message:', error);
            }
        },
        [updateCurrentPicture]
    );

    const queryClient = useQueryClient();

    const onMessageReceived = useCallback(
        (payload) => {
            console.log('Message received:', payload);
            if (
                payload.body.startsWith('TASK_UPDATED:') ||
                payload.body.startsWith('TASK_CREATED:') ||
                payload.body.startsWith('TASK_DELETED:')
            ) {
                const splitIndex = payload.body.indexOf(':');
                const actionType = payload.body.substring(0, splitIndex);
                let taskData = payload.body.substring(splitIndex + 1);

                try {
                    taskData = JSON.parse(taskData);
                    const formattedActionType = actionType
                        .split('_')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ');

                    enqueueSnackbar(`${formattedActionType}: ${taskData.title}`, {
                        variant: 'success',
                        dense: true,
                    });

                    // Instead of directly updating tasks, invalidate the tasks query
                    queryClient.invalidateQueries('tasks');
                } catch (e) {
                    console.error('Failed to parse task data:', e);
                }
            } else if (payload.body === 'RESCHEDULING') {
                enqueueSnackbar('Rescheduling tasks..', {
                    variant: 'info',
                    dense: true,
                });
            } else if (payload.body === 'RESCHEDULED') {
                enqueueSnackbar('Rescheduled tasks.', { variant: 'success', dense: true });
                queryClient.invalidateQueries('tasks');
                queryClient.invalidateQueries('calendarEvents');
            } else if (payload.body === 'CUSTOMER_UPDATED') {
                refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } else if (payload.body === 'CUSTOMER_UPDATED_SUBSCRIPTION_RECEIPT') {
                refetchCustomer();
                queryClient.invalidateQueries('calendarEvents');
            } else if (payload.body.startsWith('AI_RESPONSE:')) {
                const response = payload.body.substring('AI_RESPONSE:'.length);
                console.log('AI response received:', response);
                // Handle AI response (you might need to adjust this based on how you're managing state)
            }
        },
        [enqueueSnackbar, queryClient, refetchCustomer]
    );

    const connect = useCallback(() => {
        if (!customer || isTokenExpired || !token || stompClient?.connected) {
            return;
        }

        let Sock = new SockJS(import.meta.env.VITE_PUBLIC_API_HOST + '/ws');
        const client = over(Sock);
        client.debug = null; // Disable STOMP debug logging
        const headers = { Authorization: `Bearer ${token}` };

        const onError = (error) => {
            if (client && client.connected) {
                client.disconnect();
            }
            if (Sock) {
                Sock.close();
            }
            setStompClient(null);

            // Implement reconnection logic
            if (reconnectAttempt < maxReconnectAttempts) {
                const delay = Math.min(30000, baseReconnectDelay * Math.pow(2, reconnectAttempt));
                reconnectTimeoutRef.current = setTimeout(() => {
                    setReconnectAttempt((prev) => prev + 1);
                    connect();
                }, delay);
            } else {
                setReconnectAttempt(0);
            }
        };

        const onConnected = () => {
            const claims = jwtDecode(token);
            const email = claims['https://mindyournow.com/email'];

            client.subscribe(`/topic/${email}` + '-' + import.meta.env.VITE_PUBLIC_NODE_ENV, onMessageReceived);
            client.subscribe(`/topic/pictures` + '-' + import.meta.env.VITE_PUBLIC_NODE_ENV, onPictureReceived);
            setStompClient(client);
            setReconnectAttempt(0); // Reset reconnect attempts on successful connection
        };

        client.connect(headers, onConnected, onError);
    }, [
        customer,
        isTokenExpired,
        token,
        sessionId,
        onMessageReceived,
        onPictureReceived,
        stompClient,
        setStompClient,
        reconnectAttempt,
    ]);

    useEffect(() => {
        if (customer && !isTokenExpired && token && !stompClient && !isConnectedRef.current) {
            isConnectedRef.current = true;
            connect();
        }
        return () => {
            if (stompClient?.connected) {
                stompClient.disconnect();
                setStompClient(null);
                isConnectedRef.current = false;
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current); // Clear any pending reconnection attempts
            }
        };
    }, [customer, isTokenExpired, token, connect, stompClient, setStompClient]);

    return null; // No UI to render
};

export default StompClientConnector;
