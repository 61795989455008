import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const settingsAtom = atomWithStorage('settings', {
    showCompleted: false,
    showFuture: false,
    showDelegated: false,
    showBackground: true,
});

export const useSettings = () => {
    const [settings, setSettings] = useAtom(settingsAtom);

    const updateSetting = (key, value) => {
        setSettings((prev) => ({ ...prev, [key]: value }));
    };

    const toggleSetting = (key) => {
        setSettings((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    return {
        settings,
        updateSetting,
        toggleSetting,
    };
};
