import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { format, parseISO, add, sub, isValid, startOfDay, endOfDay, isBefore, isAfter } from 'date-fns';
import { toDate as tzToDate } from 'date-fns-tz';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';

import { Capacitor } from '@capacitor/core';
import { TimezoneModal } from './TimezoneModal';
import { TimezoneEditModal } from './TimezoneEditModal';
import CustomNowIndicator from './NowIndicatorContainer';
import MouseFollowLine from './MouseFollowLine';

import { useCustomer } from '../../providers/CustomerProvider';
import { useGoogleCalendarEvents } from '../tasks/hooks/useGoogleCalendarEvents';
import { debounce } from 'lodash';

const isMobile = Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios';

export const CalendarWidget = ({
    calendarEvents,
    isCalendarEventsLoading,
    handleEventClick,
    handleDatesSet,
    selectedTimeZone,
    handleTimeZoneChange,
    timezones,
    initialView,
    initialDate,
    onViewChange,
}) => {
    const calendarRef = useRef(null);

    const handleDatesSetInternal = useCallback(
        debounce((dateInfo) => {
            handleDatesSet(dateInfo);
        }, 300),
        [handleDatesSet]
    );

    useEffect(() => {
        if (calendarRef.current) {
            queueMicrotask(() => {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.changeView(initialView);
                calendarApi.gotoDate(initialDate);
            });
        }
    }, [initialView, initialDate]);

    const { customer } = useCustomer();

    const [displayedTimezones, setDisplayedTimezones] = useState([]);
    const [isTimezoneModalOpen, setIsTimezoneModalOpen] = useState(false);
    const [timezoneToEdit, setTimezoneToEdit] = useState(null);
    const [mousePosition, setMousePosition] = useState(null);
    const [hoveredTime, setHoveredTime] = useState(null);

    const { updateGoogleEvent } = useGoogleCalendarEvents();

    // Create a map for faster lookups
    const [calendarEventMap, setCalendarEventMap] = useState(new Map());

    const transformEventData = (event) => {
        const matchingEvent = calendarEventMap.get(event.id);
        if (matchingEvent) {
            const isAllDay = matchingEvent.allDay || isAllDayEvent(event.start, event.end) || matchingEvent.isMultiDay;
            return {
                ...event,
                allDay: isAllDay,
                start: isAllDay
                    ? startOfDay(parseISO(event.start))
                    : tzToDate(event.start, { timeZone: customer?.defaultTimeZone }),
                end: isAllDay
                    ? endOfDay(parseISO(event.end))
                    : tzToDate(event.end, { timeZone: customer?.defaultTimeZone }),
            };
        }
        return event;
    };

    const handleSlotMouseEnter = (info) => {
        setHoveredTime(info.date);
    };

    const handleSlotMouseLeave = () => {
        setHoveredTime(null);
    };

    const handleMouseMove = (event) => {
        const calendarEl = event.target.closest('.fc');
        if (calendarEl) {
            const calendarRect = calendarEl.getBoundingClientRect();
            const mouseX = event.clientX - calendarRect.left;
            const mouseY = event.clientY - calendarRect.top;
            setMousePosition({ x: mouseX, y: mouseY });
        } else {
            setMousePosition(null);
        }
    };

    const handleAddTimezone = () => {
        setIsTimezoneModalOpen(true);
    };

    const handleEventMouseEnter = (info) => {
        setHoveredTime(info.event.start);
    };

    const handleEventMouseLeave = () => {
        setHoveredTime(null);
        setMousePosition(null);
    };

    const handleTimezoneSelect = (selectedTimezone) => {
        if (!displayedTimezones.includes(selectedTimezone)) {
            setDisplayedTimezones([...displayedTimezones, selectedTimezone]);
        }
        setIsTimezoneModalOpen(false);
    };

    const handleCloseTimezoneModal = () => {
        setIsTimezoneModalOpen(false);
    };

    const handleTimezoneClick = (timezone) => {
        setTimezoneToEdit(timezone);
    };

    const handleRemoveTimezone = (timezone) => {
        setDisplayedTimezones(displayedTimezones.filter((tz) => tz !== timezone));
        setTimezoneToEdit(null);
    };

    function roundDateToNearestMinute(date) {
        const roundedDate = startOfMinute(date);
        return roundedDate;
    }

    // Function to round the hovered time to the nearest 15-minute increment
    const snapToNearest15Min = (date) => {
        const minutes = date.getMinutes();
        const remainder = minutes % 15;
        const adjustment = remainder < 8 ? -remainder : 15 - remainder;
        return add(date, { minutes: adjustment });
    };

    function roundEventTimesToNearestMinute(events) {
        return events.map((event) => {
            const roundedStart = roundDateToNearestMinute(parseISO(event.start));
            const roundedEnd = roundDateToNearestMinute(parseISO(event.end));
            return { ...event, start: roundedStart, end: roundedEnd };
        });
    }

    const handleDragStart = (event) => {
        // Handle drag start event
    };

    const handleDragStop = (event) => {
        // Handle drag stop event
    };

    const handleEventDrop = (info) => {
        calendarEvents.forEach((event) => {
            // Handle event drop
        });

        const matchingEvent = calendarEvents.find((calendarEvent) => calendarEvent.id === info.event.id);

        if (matchingEvent) {
            const updatedEvent = {
                summary: info.event.title,
                start: {
                    dateTime: formatISO(info.event.start),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                end: {
                    dateTime: formatISO(info.event.end),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
                startEditable: info.event.startEditable,
                durationEditable: info.event.durationEditable,
                colorId: info.event.color,
            };

            updateGoogleEvent(matchingEvent.calendarId, matchingEvent.id, matchingEvent.accessToken, updatedEvent);
        }
    };
    useEffect(() => {
        // Add a style tag to override FullCalendar's default styles
        const style = document.createElement('style');
        style.textContent = `
            .fc-event {
                margin: 0 !important;
                border: none !important;
                padding: 0 !important;
                background-clip: padding-box !important;
            }
            .fc-event-main {
                padding: 0 !important;
            }
            .fc-event-main-frame {
                margin: 0 !important;
                padding: 0 !important;
            }
            .fc-daygrid-event {
                white-space: normal !important;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const containerStyle = useMemo(
        () => ({
            borderRadius: '4px',
            padding: '2px 4px',
            fontSize: '12px',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            minHeight: '18px',
            boxShadow: 'none',
            margin: '0',
            outline: 'none',
        }),
        []
    );

    const titleStyle = useMemo(
        () => ({
            fontWeight: '500',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        []
    );

    const timeStyle = useMemo(
        () => ({
            fontSize: '10px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }),
        []
    );

    const renderEventContent = useCallback(
        (eventInfo) => {
            const matchingEvent = calendarEvents.find((calendarEvent) => calendarEvent.id === eventInfo.event.id);

            if (!matchingEvent) {
                return null;
            }

            let backgroundColor, textColor, borderColor, borderStyle;

            if (matchingEvent.showAs === 'tentative' || matchingEvent.status === 'cancelled') {
                backgroundColor = 'white';
                textColor = 'black';
                borderColor = 'black'; // Changed from task priority color to black
                borderStyle = 'dashed';
            } else {
                if (matchingEvent.task) {
                    const priority = matchingEvent.task.priority;
                    backgroundColor = getPriorityColor(priority);
                } else {
                    backgroundColor = eventInfo.event.backgroundColor || '#4CAF50';
                }
                backgroundColor = adjustColorForAccessibility(backgroundColor);
                textColor = getContrastColor(backgroundColor);
                borderColor = darkenColor(backgroundColor, 30);
                borderStyle = 'solid';
            }

            const isShortEvent = (new Date(eventInfo.event.end) - new Date(eventInfo.event.start)) / (1000 * 60) <= 30;

            return (
                <div
                    style={{
                        ...containerStyle,
                        backgroundColor,
                        color: textColor,
                        borderLeft: `4px ${borderStyle} ${borderColor}`,
                        position: 'relative',
                    }}
                >
                    {isShortEvent ? (
                        <div
                            style={{
                                ...titleStyle,
                                color: textColor,
                                fontWeight:
                                    matchingEvent.showAs === 'tentative' || matchingEvent.status === 'cancelled'
                                        ? 'bold'
                                        : '500',
                            }}
                        >
                            {eventInfo.timeText} {eventInfo.event.title}
                        </div>
                    ) : (
                        <>
                            <div
                                style={{
                                    ...titleStyle,
                                    fontWeight:
                                        matchingEvent.showAs === 'tentative' || matchingEvent.status === 'cancelled'
                                            ? 'bold'
                                            : '500',
                                }}
                            >
                                {eventInfo.event.title}
                            </div>
                            {eventInfo.timeText && <div style={timeStyle}>{eventInfo.timeText}</div>}
                        </>
                    )}
                    {(matchingEvent.showAs === 'tentative' || matchingEvent.status === 'cancelled') && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundImage:
                                    'repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(0,0,0,0.1) 5px, rgba(0,0,0,0.1) 10px)',
                                pointerEvents: 'none',
                            }}
                        />
                    )}
                </div>
            );
        },
        [calendarEvents, containerStyle, titleStyle, timeStyle]
    );

    function adjustColorForAccessibility(hexColor) {
        const rgb = parseInt(hexColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

        // If the luma is too low (dark), lighten the color
        if (luma < 50) {
            return lightenColor(hexColor, 20);
        }
        // If the luma is too high (light), darken the color
        else if (luma > 200) {
            return darkenColor(hexColor, 20);
        }
        return hexColor;
    }

    function getContrastColor(hexColor) {
        const rgb = parseInt(hexColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luma < 128 ? '#ffffff' : '#000000';
    }

    function lightenColor(hex, percent) {
        const num = parseInt(hex.slice(1), 16);
        const amt = Math.round(2.55 * percent);
        const R = (num >> 16) + amt;
        const G = ((num >> 8) & 0x00ff) + amt;
        const B = (num & 0x0000ff) + amt;
        return `#${((1 << 24) | ((R < 255 ? R : 255) << 16) | ((G < 255 ? G : 255) << 8) | (B < 255 ? B : 255))
            .toString(16)
            .slice(1)}`;
    }

    // Helper functions (getPriorityColor, isLightColor, darkenColor) remain the same
    function isLightColor(hexColor) {
        const rgb = parseInt(hexColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luma > 186; // This threshold can be adjusted
    }

    // Helper functions
    function getPriorityColor(priority) {
        switch (priority) {
            case 'CRITICAL':
                return '#0C803D';
            case 'OPPORTUNITY_NOW':
                return '#107CC4';
            case 'OVER_THE_HORIZON':
                return '#F9913B';
            case 'PARKING_LOT':
                return '#F04F23';
            default:
                return '#4CAF50';
        }
    }

    function getContrastColor(hexColor) {
        const rgb = parseInt(hexColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luma < 128 ? '#ffffff' : '#000000';
    }

    function darkenColor(hex, percent) {
        const num = parseInt(hex.slice(1), 16);
        const amt = Math.round(2.55 * percent);
        const R = (num >> 16) - amt;
        const G = ((num >> 8) & 0x00ff) - amt;
        const B = (num & 0x0000ff) - amt;
        return `#${((1 << 24) | ((R < 255 ? (R < 1 ? 0 : R) : 255) << 16) | ((G < 255 ? (G < 1 ? 0 : G) : 255) << 8) | (B < 255 ? (B < 1 ? 0 : B) : 255)).toString(16).slice(1)}`;
    }

    function handleEventDidMountV1(info) {
        info.el.style.padding = '0px';

        const matchingEvent = calendarEvents.find((calendarEvent) => calendarEvent.id === info.event.id);

        if (!matchingEvent) {
            return;
        }

        // Using the browser's local timezone for the current date
        const currentDate = new Date();

        // Check if info.event.end is a valid date string before parsing
        const eventEndDate =
            info.event.end && typeof info.event.end === 'string'
                ? parseISO(info.event.end)
                : info.event.end instanceof Date
                  ? info.event.end
                  : null;

        if (eventEndDate && isBefore(eventEndDate, currentDate)) {
            info.el.style.opacity = 0.4; // Set past events to be more transparent
        }

        if (matchingEvent.task) {
            const dueDate =
                matchingEvent.task.dueDate && typeof matchingEvent.task.dueDate === 'string'
                    ? parseISO(matchingEvent.task.dueDate)
                    : matchingEvent.task.dueDate instanceof Date
                      ? matchingEvent.task.dueDate
                      : null;

            if (dueDate) {
                const today = startOfDay(new Date());
                const todayEnd = endOfDay(today);
                const borderColor =
                    isBefore(dueDate, today) && matchingEvent.task.isRecurringChild == false
                        ? 'red'
                        : info.event.borderColor;
                info.el.style.borderColor = borderColor;
            }
        }

        if (matchingEvent.showAs === 'tentative') {
            info.el.classList.add('gradient-box');
        }

        if (matchingEvent.status === 'free') {
            info.el.style.opacity = 0.7;
            info.el.style.color = 'rgba(255, 255, 255, 1)';
        }
    }
    function handleEventDidMountV2(info) {
        info.el.style.padding = '0px';

        if (info.event.textColor === '#039be5') {
            info.el.style.backgroundColor = 'white';
        } else {
            info.el.style.backgroundColor = info.event.borderColor + '04';
        }

        info.el.style.borderLeft = '4px solid' + info.event.borderColor;

        const originalBackgroundColor = info.el.style.backgroundColor;
        info.el.addEventListener('mouseover', function () {
            this.style.backgroundColor = info.event.borderColor + '3A';
        });
        info.el.addEventListener('mouseout', function () {
            this.style.backgroundColor = originalBackgroundColor;
        });

        const currentDate = new Date();
        const eventEndDate = parseISO(info.event.end);

        const matchingEvent = calendarEvents.find((calendarEvent) => calendarEvent.id === info.event.id);

        if (!matchingEvent) {
            return;
        }

        info.el.style.borderLeftColor = info.event.borderColor;

        if (isBefore(eventEndDate, currentDate)) {
            info.el.style.opacity = 0.2;
        }

        if (matchingEvent.task) {
            const dueDate = parseISO(matchingEvent.task.dueDate);
            const todayStart = startOfDay(currentDate);
            if (isBefore(dueDate, todayStart)) {
                info.el.style.borderLeftColor = 'red';
            }
        }

        if (matchingEvent.showAs === 'tentative') {
            info.el.style.borderStyle = 'dashed';
            info.el.classList.add('gradient-box');
        } else if (matchingEvent.status === 'free') {
            info.el.style.opacity = 0.7;
        }

        info.el.style.color = 'rgba(0, 0, 0, 1)';
        const titleElements = info.el.querySelectorAll('.fc-event-title, .fc-event-time');
        titleElements.forEach((el) => {
            el.style.color = info.event.borderColor;
        });

        if (isBefore(eventEndDate, currentDate)) {
            info.el.style.opacity = 0.5;
        }
    }

    function isAllDayEvent(start, end) {
        const differenceInHours = (parseISO(end).getTime() - parseISO(start).getTime()) / (1000 * 60 * 60);
        return differenceInHours >= 24;
    }

    function logOverlappingEvents(events) {
        for (let i = 0; i < events.length; i++) {
            const currentEvent = events[i];
            const currentEventStart = parseISO(currentEvent.start);
            const currentEventEnd = parseISO(currentEvent.end);

            for (let j = i + 1; j < events.length; j++) {
                const otherEvent = events[j];
                const otherEventStart = parseISO(otherEvent.start);
                const otherEventEnd = parseISO(otherEvent.end);

                if (
                    (isBefore(currentEventStart, otherEventEnd) && isAfter(currentEventEnd, otherEventStart)) ||
                    (isBefore(otherEventStart, currentEventEnd) && isAfter(otherEventEnd, currentEventStart))
                ) {
                    console.log(`Event ${currentEvent.title} and event ${otherEvent.title} are overlapping.`);
                    console.log(
                        `Event ${currentEvent.title} starts at ${currentEventStart} and ends at ${currentEventEnd}.`
                    );
                    console.log(`Event ${otherEvent.title} starts at ${otherEventStart} and ends at ${otherEventEnd}.`);
                }
            }
        }
    }

    // function getInitialDate() {
    //     const today = new Date();
    //     const dayOfWeek = today.getDay();
    //     const diff = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
    //     const mostRecentSunday = sub(today, { days: diff });

    //     return mostRecentSunday;
    // }

    function getInitialDate() {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const diff = dayOfWeek === 0 ? 0 : dayOfWeek; // If today is Sunday, diff is 0, otherwise it's the number of days since last Sunday
        return sub(today, { days: diff });
    }

    const handleWeekStartButtonClick = () => {
        const calendarApi = calendarRef.current.getApi();
        const today = new Date();
        const startOfWeek = sub(startOfWeek(today, { weekStartsOn: 1 }), { days: 1 });
        calendarApi.gotoDate(startOfWeek);
        calendarApi.changeView('timeGridWeek');
    };

    const CustomToolbarDropdown = ({ currentView, changeView }) => {
        const [isOpen, setIsOpen] = useState(false);

        const views = [
            { name: 'dayGridMonth', text: 'Month' },
            { name: 'timeGridWeek', text: 'Week' },
            { name: 'timeGridDay', text: 'Day' },
            { name: 'listYear', text: 'List' },
            { name: 'timeGridFourDay', text: '3 Day' },
        ];

        const currentViewText = views.find((v) => v.name === currentView)?.text || 'Select View';

        const handleClick = (viewName) => {
            changeView(viewName);
            setIsOpen(false);
        };

        return (
            <div className="relative custom-toolbar-dropdown">
                <button className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                    {currentViewText} <i className={`fas fa-caret-${isOpen ? 'up' : 'down'} ml-2`}></i>
                </button>
                {isOpen && (
                    <div className="absolute right-0 z-20 py-2 mt-2 w-48 bg-white rounded-md shadow-xl dropdown-menu">
                        {views.map((view) => (
                            <button
                                key={view.name}
                                className="block px-4 py-2 w-full text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                onClick={() => handleClick(view.name)}
                            >
                                {view.text}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    };
    // Add the event listener when the component mounts
    // useEffect(() => {
    //     document.addEventListener('mousemove', handleMouseMove);
    //     return () => {
    //         document.removeEventListener('mousemove', handleMouseMove);
    //     };
    // }, []);

    useEffect(() => {
        if (!calendarEvents) {
            return;
        }
        // Create the map when calendarEvents changes
        const newMap = new Map(calendarEvents.map((event) => [event.id, event]));
        queueMicrotask(() => {
            setCalendarEventMap(newMap);
        });
    }, [calendarEvents]);

    return (
        <div className="mx-auto w-full h-screen">
            {/* <CustomToolbarDropdown
                currentView={currentView}
                changeView={handleViewChange}
            /> */}

            <FullCalendar
                nowIndicatorContent={<CustomNowIndicator />}
                nowIndicator={true}
                // eventMouseEnter={handleEventMouseEnter}
                // eventMouseLeave={handleEventMouseLeave}
                ref={calendarRef}
                events={calendarEvents}
                height="90%"
                contentHeight="50%"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, timeGridPlugin, rrulePlugin]}
                initialView={initialView}
                initialDate={initialDate}
                datesSet={handleDatesSetInternal}
                views={{
                    timeGrid: {},
                    dayGrid: {},
                    timeGridWeek: {
                        type: 'timeGrid',
                        duration: { days: 7 },
                        buttonText: 'week',
                        slotDuration: '00:30',
                        validRange: function (nowDate) {
                            return {
                                start: getInitialDate(),
                                end: add(getInitialDate(), { days: 90 }),
                            };
                        },
                        visibleRange: (currentDate) => {
                            const start = getInitialDate();
                            const end = add(start, { days: 7 });
                            return { start, end };
                        },
                    },
                    timeGridFourDay: {
                        type: 'timeGrid',
                        duration: { days: 3 },
                        buttonText: '3 day',
                        slotDuration: '00:30',
                    },
                }}
                allDaySlot={true}
                allDayMaintainDuration={true}
                scrollTime="08:00:00"
                weekends={true}
                handleWindowResize={true}
                aspectRatio={4}
                eventContent={renderEventContent}
                // eventDidMount={handleEventDidMountV1}
                eventDataTransform={transformEventData}
                dayCount={7}
                firstDay={0}
                selectMirror={true}
                dayMaxEvents={true}
                expandRows={false}
                dayHeaderFormat={(args) => {
                    // Since args.date is already a Date object, we don't need to use parseISO or isDate
                    if (isValid(args.date)) {
                        return `${format(args.date, 'EEE')} ${args.date.getDate()}`;
                    } else {
                        return ''; // or return a default value if the date is invalid
                    }
                }}
                dayHeaderContent={({ date }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '4px' }}>{format(date, 'EEE')}</div>
                        <div>{date.getDate()}</div>
                    </div>
                )}
                slotLabelContent={({ date }) => (
                    <div className="flex justify-end">
                        <div style={{ marginRight: '2rem' }}>
                            {format(tzToDate(date, { timeZone: customer.defaultTimeZone }), 'h a')}
                        </div>

                        {displayedTimezones.map((timezone) => (
                            <div key={timezone}>
                                <div style={{ marginRight: '2rem' }}>
                                    {format(tzToDate(date, { timeZone: timezone }), 'h:mm a')}
                                </div>
                                <div>{timezone}</div>
                            </div>
                        ))}
                    </div>
                )}
                eventClick={handleEventClick}
                headerToolbar={
                    isMobile
                        ? {
                              left: 'prev,next today',
                              center: 'title',
                              right: 'listDay',
                          }
                        : {
                              // left: 'prev,next today weekStart viewSelector',
                              left: 'prev,next today',
                              center: 'title',
                              // right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear,timeGridFourDay weekStart,customViewSelector',
                              right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear,timeGridFourDay',
                          }
                }
                viewClassNames={{
                    timeGridFourDay: 'view-dropdown',
                    dayGridMonth: 'view-dropdown',
                    timeGridWeek: 'view-dropdown',
                    timeGridDay: 'view-dropdown',
                    listYear: 'view-dropdown',
                }}
                titleFormat={{
                    year: isMobile ? undefined : 'numeric',
                    month: 'short',
                    day: 'numeric',
                }}
                allDayContent={() => (
                    <div className="flex justify-end items-center">
                        <div className="mr-4">
                            {format(tzToDate(new Date(), { timeZone: customer.defaultTimeZone }), 'z')}
                        </div>
                        {displayedTimezones.map((timezone) => (
                            <div key={timezone} className="mr-4">
                                {format(tzToDate(new Date(), { timeZone: timezone }), 'z')}
                            </div>
                        ))}
                        <button className="ml-4" onClick={handleAddTimezone}>
                            +
                        </button>
                    </div>
                )}
            />
            {isTimezoneModalOpen && (
                <TimezoneModal
                    timezones={timezones}
                    handleTimezoneSelect={handleTimezoneSelect}
                    handleCloseTimezoneModal={handleCloseTimezoneModal}
                />
            )}
            {timezoneToEdit && (
                <TimezoneEditModal
                    timezone={timezoneToEdit}
                    handleRemoveTimezone={handleRemoveTimezone}
                    handleCloseModal={() => setTimezoneToEdit(null)}
                />
            )}
            <MouseFollowLine position={mousePosition} calendarRef={calendarRef} />
            {hoveredTime && mousePosition && (
                <div
                    style={{
                        position: 'absolute',
                        left: '10px',
                        top: `${mousePosition.y}px`,
                        transform: 'translateY(-50%)',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    {format(hoveredTime, 'h:mm a')}
                </div>
            )}
        </div>
    );
};
