import React, { useCallback, useState, useRef, useEffect } from 'react';
import { format, addMinutes, differenceInMilliseconds, isToday, isPast } from 'date-fns';
import { Dialog } from '@mui/material';
import { PriorityPanel } from '../tasks/PriorityPanel';
import { DayRow } from './DayRow';
import { motion, AnimatePresence } from 'framer-motion';
import { useTasks } from '../../providers/TasksProvider';
import { useProjects } from '../utils/projectApi';
import { useSettings } from '../../atoms/SettingsAtom';
import { useNavigate } from 'react-router-dom';
import { useTaskModal } from '../../contexts/TaskModalContext';

export const Briefing = ({ selectedCollectionObj, isPlanner }) => {
    const {
        tasks,
        filteredTasks,
        filteredPrettyEvents,
        filteredPrettyEventsSorted,
        selectedFilter,
        selectedCollection,
    } = useTasks();

    const hasNoTasks = tasks.length === 0;
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showPriorityPanel, setShowPriorityPanel] = useState(false);
    const [priorityTask, setPriorityTask] = useState(null);
    const { settings, updateSetting } = useSettings();
    const navigate = useNavigate();
    const { openTaskModal } = useTaskModal();

    const openPriorityPanel = useCallback((event, task) => {
        event.stopPropagation();
        setPriorityTask(task);
        setShowPriorityPanel(true);
    }, []);

    const timeoutIdRef = useRef(null);
    const renderCount = useRef(0);

    useEffect(() => {
        renderCount.current += 1;
    });
    useEffect(() => {}, [filteredTasks]);

    useEffect(() => {
        const scheduleNextMinute = () => {
            const now = new Date();
            const nextMinute = addMinutes(now, 1);
            const timeUntilNextMinute = differenceInMilliseconds(nextMinute, now);

            timeoutIdRef.current = setTimeout(() => {
                setCurrentDate(new Date());
                scheduleNextMinute();
            }, timeUntilNextMinute);
        };

        scheduleNextMinute();

        return () => {
            clearTimeout(timeoutIdRef.current);
        };
    }, []);

    const headlineVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { delay: 0.2, duration: 0.5 } },
        exit: { opacity: 0 },
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: 'beforeChildren',
                staggerChildren: 0.1,
            },
        },
        exit: { opacity: 0 },
    };

    const hasTasksForToday = filteredTasks.some(
        (task) => isToday(new Date(task.startDate)) || isPast(new Date(task.startDate))
    );

    const shouldShowTasks = hasTasksForToday || settings.showFuture || isPlanner;

    let content;
    if (hasNoTasks) {
        content = (
            <div className="mt-10 text-center">
                <p>No tasks available. Please add a new task to get started.</p>
                <button onClick={() => openTaskModal()} className="px-4 py-2 mt-4 text-white bg-green-500 rounded">
                    Add New Task
                </button>
            </div>
        );
    } else if (shouldShowTasks) {
        content = (
            <AnimatePresence>
                {filteredPrettyEventsSorted.map((day) => (
                    <motion.div
                        key={day}
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="grid grid-cols-1 ml-0 w-full text-lg border-t-2 border-solid divide-y-2 text-bold font-roboto"
                    >
                        <motion.p
                            variants={headlineVariants}
                            className="text-2xl md:text-3.5xl font-semibold leading-tight tracking-tight shadow-md"
                        >
                            {format(new Date(day), 'd EEE MMM yyyy')}
                        </motion.p>

                        <DayRow
                            filteredPrettyEvents={filteredPrettyEvents}
                            isPlanner={isPlanner}
                            tasks={filteredTasks}
                            day={day}
                            openPriorityPanel={openPriorityPanel}
                            selectedCollectionObj={selectedCollectionObj}
                            selectedFilter={selectedFilter}
                            selectedCollection={selectedCollection}
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
        );
    } else {
        content = (
            <div className="mt-10 text-center">
                <p>
                    No tasks for today. Please toggle the "Show Future" setting or go to the{' '}
                    <a
                        href="/planner?filter=TOTAL_TASKS"
                        className="text-blue-500 underline"
                        aria-label="Go to Planner"
                    >
                        Planner
                    </a>{' '}
                    and click "All Tasks" in the overview.
                </p>
                <button
                    onClick={() => updateSetting('showFuture', true)}
                    className="px-4 py-2 mt-4 text-white bg-blue-500 rounded"
                    title="Show Future Tasks"
                    aria-label="Show Future Tasks"
                >
                    Show Future Tasks
                </button>
                <button
                    onClick={() => navigate('/planner?filter=TOTAL_TASKS')}
                    className="px-4 py-2 mt-4 ml-2 text-white bg-orange-500 rounded"
                    title="Go to Planner"
                    aria-label="Go to Planner"
                >
                    Go to Planner
                </button>
                <button
                    onClick={() => openTaskModal()}
                    className="px-4 py-2 mt-4 ml-2 text-white bg-green-500 rounded"
                    title="Add New Task"
                    aria-label="Add New Task"
                >
                    Add New Task
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full text-black briefing" style={{ zIndex: 10 }}>
            <div className="flex-1 mb-2 ml-0 text-4xl font-medium text-black">Briefing</div>
            <div className="justify-center m-auto mt-0 w-full h-full bg-green">
                <Dialog
                    keepMounted
                    size="lg"
                    open={showPriorityPanel}
                    onClose={() => setShowPriorityPanel(false)}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                    slotProps={{
                        backdrop: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                >
                    {showPriorityPanel && (
                        <PriorityPanel
                            aria-labelledby="Priority Panel"
                            keepMounted
                            openPriorityPanel={openPriorityPanel}
                            notifyClose={() => setShowPriorityPanel(false)}
                            task={priorityTask}
                        />
                    )}
                </Dialog>
                {content}
            </div>
        </div>
    );
};

export default Briefing;
