import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CollectionItem from './CollectionItem';

const CollectionsPane = ({
    sortedProjects,
    selectedCollection,
    handleCollectionClick,
    handleTaskMoved,
    collectionIcon,
    collectionColor,
}) => {
    const slideInFromLeft = {
        hidden: { x: '-100%', opacity: 0 },
        visible: { x: '0%', opacity: 1 },
    };
    return (
        <motion.div className="overflow-y-auto col-span-2 bg-gray-50" variants={slideInFromLeft}>
            <div className="sticky top-0 p-4 bg-gray-50">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xs uppercase font-sfProText">Collections</h2>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
                <ul>
                    {sortedProjects.map((collection) => (
                        <CollectionItem
                            key={collection.id}
                            collection={collection}
                            selectedCollection={selectedCollection}
                            handleCollectionClick={handleCollectionClick}
                            icon={collectionIcon[collection.type]}
                            color={collectionColor[collection.type]}
                            customName={collection.customName}
                            onTaskMoved={handleTaskMoved}
                        />
                    ))}
                </ul>
            </div>
        </motion.div>
    );
};

export default CollectionsPane;
