import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTasks } from '../../providers/TasksProvider';

const AgeOfTasksBarChart = () => {
    const { tasks, isLoading } = useTasks();

    const data = useMemo(() => {
        if (!tasks || tasks.length === 0) return [];

        const prepareDataForBarChart = (tasks) => {
            const ageRanges = {
                '0-7 days': { OVER_THE_HORIZON: 0, CRITICAL: 0, OPPORTUNITY_NOW: 0, PARKING_LOT: 0 },
                '8-30 days': { OVER_THE_HORIZON: 0, CRITICAL: 0, OPPORTUNITY_NOW: 0, PARKING_LOT: 0 },
                '31-90 days': { OVER_THE_HORIZON: 0, CRITICAL: 0, OPPORTUNITY_NOW: 0, PARKING_LOT: 0 },
                '91+ days': { OVER_THE_HORIZON: 0, CRITICAL: 0, OPPORTUNITY_NOW: 0, PARKING_LOT: 0 },
            };

            const now = new Date();

            tasks.forEach((task) => {
                if (!task.createdDate || !task.priority) return; // Skip tasks with missing data

                const createdDate = new Date(task.createdDate);
                const ageInDays = Math.floor((now - createdDate) / (1000 * 60 * 60 * 24));

                let ageRange;
                if (ageInDays <= 7) ageRange = '0-7 days';
                else if (ageInDays <= 30) ageRange = '8-30 days';
                else if (ageInDays <= 90) ageRange = '31-90 days';
                else ageRange = '91+ days';

                if (ageRanges[ageRange] && ageRanges[ageRange][task.priority] !== undefined) {
                    ageRanges[ageRange][task.priority]++;
                }
            });

            return Object.entries(ageRanges).map(([name, values]) => ({
                name,
                ...values,
            }));
        };

        return prepareDataForBarChart(tasks);
    }, [tasks]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (data.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="OVER_THE_HORIZON" stackId="a" fill="#F9913B" />
                <Bar dataKey="CRITICAL" stackId="a" fill="#0C803D" />
                <Bar dataKey="OPPORTUNITY_NOW" stackId="a" fill="#107CC4" />
                <Bar dataKey="PARKING_LOT" stackId="a" fill="#F04F23" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default AgeOfTasksBarChart;
