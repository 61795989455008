import React from 'react';
import { Chip, Grid } from '@mui/material';

export default function TaskSelectedSchedules({ selectedSchedules, handleDeleteSchedule }) {

    return (
        <Grid item xs={12}>
            <div className="flex flex-wrap">
                {(!selectedSchedules || selectedSchedules.length === 0) ? (
                    <Chip size="small" label="No schedules selected" className="" />
                ) : (
                    selectedSchedules.map((schedule) => (
                        <Chip
                            key={schedule.id}
                            size="small"
                            label={schedule.name}
                            onDelete={() => handleDeleteSchedule(schedule.id)}
                            className="m-1"
                        />
                    ))
                )}
            </div>
        </Grid>
    );
}