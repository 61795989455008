import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { TextField, Button, Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CompletedIcon } from '../home/DayRowTask';
import TaskProjectSelector from './TaskProjectSelector';
import TaskDurationField from './TaskDurationField';
import { useProjects } from '../utils/projectApi';

// Memoize the FontAwesomeIcon component
const MemoizedFontAwesomeIcon = React.memo(FontAwesomeIcon);

const TaskEdit = React.memo(({ task, updateTask, deleteTask, onComplete }) => {
    const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);
    const { isLoading: isProjectsLoading } = useProjects();

    useEffect(() => {
        if (!isProjectsLoading) {
            setIsProjectsLoaded(true);
        }
    }, [isProjectsLoading]);

    const handleChangeTitle = useCallback(
        (e) => {
            updateTask({ title: e.target.value });
        },
        [updateTask]
    );

    const handleChangeProject = useCallback(
        (projectId) => {
            updateTask({ projectId });
        },
        [updateTask]
    );

    const handleChangeDuration = useCallback(
        (e) => {
            updateTask({ duration: e.target.value });
        },
        [updateTask]
    );

    const handleDelete = useCallback(() => {
        if (window.confirm('Are you sure you wish to delete this task?')) {
            deleteTask(task.id);
        }
    }, [deleteTask, task.id]);

    const textFieldProps = useMemo(
        () => ({
            label: 'Task Title',
            size: 'small',
            fullWidth: true,
            autoFocus: true,
            inputProps: { autoComplete: 'new-password' },
            name: 'notARealFieldName12345',
            value: task.title || '',
            onChange: handleChangeTitle,
            style: { flex: 1, marginRight: '8px' },
        }),
        [task.title, handleChangeTitle]
    );

    const buttonStyle = useMemo(() => ({ minWidth: 'auto', color: 'red' }), []);

    // Memoize the style prop for CompletedIcon
    const completedIconStyle = useMemo(() => ({ marginLeft: '12px' }), []);

    return (
        <>
            <div className="flex flex-grow items-center mt-5 min-w-full task-title-first-row">
                <TextField {...textFieldProps} />
                <CompletedIcon isCompleted={task.isCompleted} onComplete={onComplete} style={completedIconStyle} />
            </div>
            <div className="flex justify-between items-center pt-1 mt-3">
                {isProjectsLoaded ? (
                    <TaskProjectSelector newProjectId={task.projectId} handleChangeProject={handleChangeProject} />
                ) : (
                    <Skeleton variant="rectangular" width={200} height={40} />
                )}
                <div className="flex-grow ml-2">
                    <TaskDurationField duration={task.duration} handleChangeDuration={handleChangeDuration} />
                </div>
                <Button onClick={handleDelete} className="ml-2 text-red-500" title="Delete Task" style={buttonStyle}>
                    <MemoizedFontAwesomeIcon icon={faTrashAlt} size="lg" />
                </Button>
            </div>
        </>
    );
});

export default TaskEdit;
