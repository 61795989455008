import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../atoms/tokenAtoms';
import axios from 'axios';

export const useAIChat = () => {
    const [token] = useAtom(tokenAtom);
    const [aiResponse, setAIResponse] = useState('');
    const [chatMessages, setChatMessages] = useState([]);

    const fetchAIChat = async (message) => {
        try {
            const response = await axios.post(
                `${import.meta.env.VITE_PUBLIC_API_HOST}/api/ai/chat`,
                { message },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setAIResponse(response.data);
        } catch (error) {
            console.error('Error fetching AI chat:', error);
        }
    };

    useEffect(() => {
        if (aiResponse) {
            setChatMessages((prevMessages) => [
                ...prevMessages,
                { text: aiResponse, timestamp: new Date(), isUser: false },
            ]);
            setAIResponse(''); // Clear the response after appending it
        }
    }, [aiResponse]);

    const handleUserInput = async (value) => {
        setChatMessages((prevMessages) => [...prevMessages, { text: value, timestamp: new Date(), isUser: true }]);
        await fetchAIChat(value);
    };

    return {
        chatMessages,
        handleUserInput,
    };
};
