import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useProjects } from './utils/projectApi';

const CollectionItem = ({ collection, selectedCollection, handleCollectionClick, icon, color, onTaskMoved }) => {
    const { moveTaskToProject } = useProjects();
    const [isOver, setIsOver] = useState(false);
    const isSelected = selectedCollection === collection.type;
    const isDropDisabled = collection.type === 'TODAY' || collection.type === 'UPCOMING';

    const [, ref] = useDrop({
        accept: 'TASK',
        drop: (item) => {
            if (isDropDisabled) return;

            moveTaskToProject(collection.id, item);

            if (onTaskMoved) {
                onTaskMoved();
            }
        },
        hover: () => {
            if (!isDropDisabled) {
                setIsOver(true);
            }
        },
        collect: (monitor) => {
            setIsOver(monitor.isOver() && !isDropDisabled);
        },
    });

    const containerStyle = isOver ? { backgroundColor: isDropDisabled ? 'red' : 'green' } : {};

    const toTitleCase = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <li
            ref={ref}
            onClick={() => handleCollectionClick({ ...collection, type: collection.type })}
            className={`flex items-center mb-2 cursor-pointer py-1 ${isSelected ? 'bg-blue-100' : ''}`}
            style={containerStyle}
        >
            <div className="flex justify-center items-center w-8 h-8">
                <FontAwesomeIcon icon={icon} className={`text-lg ${color}`} />
            </div>
            <div className="flex items-center h-8">
                <span className="ml-2 text-md font-sfCompactDisplay">
                    {collection.customName || toTitleCase(collection.type)}
                </span>
            </div>
        </li>
    );
};

export default CollectionItem;
