import React, { useState, lazy } from 'react';
import { usePapaParse } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import { Task } from '../domain/Task';
import { Loading } from './utils/Loading';

const Button = lazy(() => import('@mui/material/Button'));

export function UploadCSV({ userId }) {
    const { readString } = usePapaParse();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // Process CSV data
    const processData = (dataString) => {
        readString(dataString, {
            worker: true,
            step: (row) => {
                const firstCol = row.data[0].replace(/['"]+/g, '');

                const task = new Task(userId, firstCol, 'PARKING_LOT', 'REGULAR', {
                    startDate: new Date(),
                });

                // Here you would typically save the task to your backend
                // For now, we'll just log it
                console.log('New task created:', task);
            },
            complete: (results) => {
                setLoading(false);
                navigate('/tasks');
            },
        });
    };

    // Handle file upload
    const handleFileUpload = (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            processData(bstr);
        };
        reader.readAsBinaryString(file);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <label htmlFor="upload-todo">
                <input
                    style={{ display: 'none' }}
                    id="upload-todo"
                    name="upload-todo"
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={handleFileUpload}
                />
                <div className="text-blue-500 underline">
                    <Button color="primary" component="span">
                        Upload other todo lists
                    </Button>
                </div>
            </label>
        </div>
    );
}

export default UploadCSV;
