// src/hooks/useTaskState.ts

import { useState, useEffect, useCallback } from 'react';
import { Task } from '../../../domain/Task';

export const useTaskState = (initialTask: Task) => {
    const [task, setTask] = useState<Task>(initialTask);

    useEffect(() => {
        setTask(initialTask);
    }, [initialTask]);

    const updateTask = useCallback((updates: Partial<Task> | ((prevTask: Task) => Partial<Task>)) => {
        setTask((prevTask) => {
            const newTask = new Task(prevTask.ownerId, prevTask.title, prevTask.priority, prevTask.category, prevTask);
            if (typeof updates === 'function') {
                const updateResult = updates(prevTask);
                newTask.update(updateResult);
            } else {
                newTask.update(updates);
            }
            return newTask;
        });
    }, []);

    return {
        task,
        updateTask,
    };
};
