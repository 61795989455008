export const questions = [
    {
        id: 'question1',
        question: 'How often do you feel overwhelmed by the number of tasks you have to manage?',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
        feature: 'Mind Your Now can help you manage your tasks in a more organized manner.',
    },
    {
        id: 'question2',
        question: 'Do you have trouble focusing on the right tasks at the right time?',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
        feature: 'Mind Your Now helps you focus on the right tasks at the right time.',
    },
    {
        id: 'question3',
        question: 'Do you find it difficult to plan your future tasks?',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
        feature: 'Mind Your Now can help you plan for all your tasks, reducing worry and increasing productivity.',
    },
    {
        id: 'question4',
        question: 'How often do you focus on when tasks are due rather than when you should start them?',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
        feature: 'Mind Your Now encourages you to focus on the start date of tasks, helping you to plan better.',
    },
    {
        id: 'question5',
        question: 'Do you wish your tasks could be automatically sorted for you?',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
        feature: 'Mind Your Now uses AI to sort your tasks, helping you to stress less and accomplish more.',
    },
    {
        id: 'question6',
        question: 'Do you wish your tasks could be intelligently scheduled for you?',
        options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
        feature: 'Mind Your Now uses AI-driven task scheduling to help you plan for the future.',
    },
];
