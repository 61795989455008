import React, { useEffect } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel, Select, OutlinedInput, Grid } from '@mui/material';
export default function TaskScheduleSelector({ selectedSchedule, setSelectedSchedule, schedulesData }) {
    useEffect(() => {
        console.log('selectedSchedule:', selectedSchedule);
        console.log('schedulesData:', schedulesData);
    }, [selectedSchedule, schedulesData]);

    return (
        <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="schedule-select">Select a schedule</InputLabel>
                <Select
                    value={selectedSchedule}
                    onChange={(e) => setSelectedSchedule(e.target.value)} // Updated line
                    label="Select a schedule"
                    input={<OutlinedInput label="Select a schedule" name="schedule-select" id="schedule-select" />}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {schedulesData?.map((schedule) => (
                        <MenuItem key={schedule.id} value={schedule.id}>
                            {schedule.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
