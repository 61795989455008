import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TaskBarChart from './TaskBarChart';
import AgeOfTasksBarChart from './AgeOfTasksBarChart';
import GrowthOverTimeChart from './GrowthOverTimeChart';
import CreationGrowthOverTimeChart from './CreationGrowthOverTimeChart';
import HeatMapOfTimeDayChart from './HeatMapOfTimeDayChart';
import TimeOfDayHeatMapChart from './TimeOfDayChart';
import ReactGA from 'react-ga4';

export const Stats = () => {

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD', {});
        ReactGA.send({ hitType: "pageview", page: '/stats', title: "Stats" });
    }, []);

    return (
        <Paper elevation={3}>
            <div className="TaskBarChart">
                <TaskBarChart />
            </div>
            <div className="AgeOfTasksBarChart">
                <AgeOfTasksBarChart />
            </div>
            <div className="GrowthOverTimeChart">
                <GrowthOverTimeChart />
            </div>
            <div className="CreationGrowthOverTimeChart">
                <CreationGrowthOverTimeChart />
            </div>
            <div className="HeatMapOfTimeDayChart">
                <HeatMapOfTimeDayChart />
            </div>
            <div className="TimeOfDayHeatMapChart">
                <TimeOfDayHeatMapChart />
            </div>

            <br></br>
            <br></br>
            <br></br>
        </Paper>
    );
};

export default Stats;
