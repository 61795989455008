// src/atoms/tokenAtoms.js
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { jwtDecode } from 'jwt-decode';

const MAX_TOKEN_LENGTH = 1024;

export const tokenAtom = atomWithStorage('token', null, undefined, { getOnInit: true });

export const isTokenExpiredAtom = atom(
    (get) => (get(tokenAtom) ? jwtDecode(get(tokenAtom)).exp < Date.now() / 1000 : true),
    (get, set, newValue) => {
        // Define how to update the atom here
        set(tokenAtom, newValue ? null : get(tokenAtom));
    }
);

export const setTokenAtom = atom(null, (get, set, newToken) => {
    if (!newToken || newToken.length < MAX_TOKEN_LENGTH) {
        set(tokenAtom, newToken);
    } else {
        console.warn('Token exceeds maximum length. Clearing token.');
        set(tokenAtom, null);
    }
});

// export const isTokenExpiredAtom = atom((get) => {
//     const token = get(tokenAtom);
//     if (!token) return true;
//     if (token.length >= MAX_TOKEN_LENGTH) return true;

//     try {
//         const decodedToken = jwtDecode(token);
//         const currentTime = Date.now() / 1000;
//         return decodedToken.exp < currentTime;
//     } catch (error) {
//         console.error('Error decoding token:', error);
//         return true;
//     }
// });
