import React, { useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, Legend } from 'recharts';
import { useTasks } from '../../providers/TasksProvider';

const GrowthOverTimeChart = () => {
    const { tasks } = useTasks();

    const formatXAxis = (date) => {
        const options = { month: 'short', day: 'numeric', year: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
    };

    const data = useMemo(() => {
        const prepareDataForGrowthChart = (tasks) => {
            const taskData = tasks
                .map((task) => ({
                    date: new Date(task.startDate),
                    priority: task.priority,
                }))
                .sort((a, b) => a.date - b.date);

            const cumulativeData = [];
            const counts = {
                OVER_THE_HORIZON: 0,
                CRITICAL: 0,
                OPPORTUNITY_NOW: 0,
                PARKING_LOT: 0,
            };

            taskData.forEach((task) => {
                counts[task.priority]++;
                cumulativeData.push({
                    date: task.date,
                    ...counts,
                });
            });

            return cumulativeData;
        };

        return prepareDataForGrowthChart(tasks);
    }, [tasks]);

    return (
        <div className="flex justify-center mt-10 w-full">
            <ResponsiveContainer width="95%" height={400}>
                <AreaChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 50,
                        bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" type="category" tickFormatter={formatXAxis}>
                        <Label value="Start Date" offset={0} position="bottom" className="text-xl font-semibold" />
                    </XAxis>
                    <YAxis>
                        <Label value="Tasks" angle={-90} position="insideLeft" className="text-xl font-semibold" />
                    </YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ bottom: -10 }} />
                    <Area
                        type="monotone"
                        dataKey="OVER_THE_HORIZON"
                        name="Over the Horizon"
                        stackId="1"
                        stroke="#F9913B"
                        fill="#F9913B"
                    />
                    <Area
                        type="monotone"
                        dataKey="CRITICAL"
                        name="Critical"
                        stackId="1"
                        stroke="#0C803D"
                        fill="#0C803D"
                    />
                    <Area
                        type="monotone"
                        dataKey="OPPORTUNITY_NOW"
                        name="Opportunity Now"
                        stackId="1"
                        stroke="#107CC4"
                        fill="#107CC4"
                    />
                    <Area
                        type="monotone"
                        dataKey="PARKING_LOT"
                        name="Parking Lot"
                        stackId="1"
                        stroke="#F04F23"
                        fill="#F04F23"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default GrowthOverTimeChart;
