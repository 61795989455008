import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useTasksHook } from '../components/tasks/hooks/useTasksHook';
import { useTaskActions } from '../components/tasks/hooks/useTaskActions';
import { useCalendarEvents } from '../contexts/CalendarEventsContext';

const TasksContext = createContext();

export const TasksProvider = ({ children }) => {
    const [selectedFilter, setSelectedFilter] = useState('TODAYS_TASKS');
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [calendarEvents, setCalendarEvents] = useState([]);

    const {
        tasks,
        isLoading,
        error,
        refetch,
        getFilteredTasks,
        filteredTasks,
        filteredPrettyEvents,
        filteredPrettyEventsSorted,
        processedRecurringTasks,
    } = useTasksHook(true, selectedFilter, selectedCollection);

    const { saveTask, deleteTask } = useTaskActions();

    const updateFilter = useCallback((newFilter) => {
        setSelectedFilter(newFilter);
        setSelectedCollection(null);
    }, []);

    const updateCollection = useCallback((newCollection) => {
        setSelectedCollection(newCollection);
        setSelectedFilter(null);
    }, []);

    const selectTask = useCallback((taskId) => {
        setSelectedTaskId(taskId);
    }, []);

    const deselectTask = useCallback(() => {
        setSelectedTaskId(null);
    }, []);

    const selectedTaskInfo = useMemo(() => {
        if (!selectedTaskId || !tasks || tasks.length === 0) {
            return { selectedTask: null, originalTask: null };
        }

        const selectedTask = tasks.find((task) => task.id === selectedTaskId);

        if (!selectedTask) {
            return { selectedTask: null, originalTask: null };
        }

        if (selectedTask.parentTask) {
            const parentTask = tasks.find((task) => task.id === selectedTask.parentTask.id);
            return {
                selectedTask: parentTask || selectedTask,
                originalTask: selectedTask,
            };
        }

        return {
            selectedTask,
            originalTask: selectedTask,
        };
    }, [selectedTaskId, tasks]);

    const { calendarEvents: fetchedCalendarEvents } = useCalendarEvents();

    const matchTasksToCalendarEvents = useCallback(() => {
        if (!tasks || !fetchedCalendarEvents) return [];

        const updatedEvents = [...fetchedCalendarEvents];
        const matchedTasks = [];

        tasks.forEach((task) => {
            if (task.calendarEvents && task.calendarEvents.length > 0) {
                task.calendarEvents.forEach((taskCalendarEvent) => {
                    const matchedEvent = updatedEvents.find((event) => taskCalendarEvent.externalEventId === event.id);
                    if (matchedEvent) {
                        matchedEvent.task = task;
                        task.calendarEvent = matchedEvent;
                        task.googleEvent = {
                            start: matchedEvent.start,
                            end: matchedEvent.end,
                            googleStart: { dateTime: matchedEvent.start },
                            googleEnd: { dateTime: matchedEvent.end },
                        };
                        matchedTasks.push(task);
                    }
                });
            }
        });

        setCalendarEvents([...updatedEvents]);

        return tasks;
    }, [tasks, fetchedCalendarEvents]);

    useEffect(() => {
        matchTasksToCalendarEvents();
    }, [tasks, fetchedCalendarEvents, matchTasksToCalendarEvents]);

    const contextValue = useMemo(
        () => ({
            tasks,
            isLoading,
            error,
            refetch,
            getFilteredTasks,
            filteredTasks,
            filteredPrettyEvents,
            filteredPrettyEventsSorted,
            processedRecurringTasks,
            selectedFilter,
            selectedCollection,
            updateFilter,
            updateCollection,
            ...selectedTaskInfo,
            selectTask,
            deselectTask,
            saveTask,
            deleteTask,
        }),
        [
            tasks,
            isLoading,
            error,
            refetch,
            getFilteredTasks,
            filteredTasks,
            filteredPrettyEvents,
            filteredPrettyEventsSorted,
            processedRecurringTasks,
            selectedFilter,
            selectedCollection,
            updateFilter,
            updateCollection,
            selectedTaskInfo,
            selectTask,
            deselectTask,
            saveTask,
            deleteTask,
        ]
    );

    return <TasksContext.Provider value={contextValue}>{children}</TasksContext.Provider>;
};

export const useTasks = () => {
    const context = useContext(TasksContext);
    if (context === undefined) {
        throw new Error('useTasks must be used within a TasksProvider');
    }
    return context;
};
