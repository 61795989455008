import React from 'react';

export const TimezoneModal = ({ timezones, handleTimezoneSelect, handleCloseTimezoneModal }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="p-6 bg-white rounded-lg shadow-lg">
                <h2 className="mb-4 text-xl font-bold">Select Timezone</h2>
                <ul>
                    {timezones.map((timezone) => (
                        <li
                            key={timezone}
                            className="py-2 cursor-pointer"
                            onClick={() => handleTimezoneSelect(timezone)}
                        >
                            {timezone}
                        </li>
                    ))}
                </ul>
                <button
                    className="px-4 py-2 mt-4 bg-gray-200 rounded"
                    onClick={handleCloseTimezoneModal}
                >
                    Close
                </button>
            </div>
        </div>
    );
};