import React from 'react';
import AccountCalendarList from './AccountCalendarList';

function createData(name, email, type, calendar) {
    return { name, email, type, calendar };
}

export const CalendarAccounts = ({ accounts, buttonSize, googleLogin, microsoftLogin }) => {
    if (!accounts || accounts == undefined) {
        return <div>No accounts are currently active.</div>;
    }
    const sortedAccounts = accounts.slice().sort((a, b) => b.primary - a.primary);

    return (
        <div className="mt-6 w-full">
            {sortedAccounts.map((account) => (
                <div key={account.id}>
                    <AccountCalendarList
                        buttonSize={buttonSize}
                        account={account}
                        googleLogin={googleLogin}
                        microsoftLogin={microsoftLogin}
                    />
                </div>
            ))}
        </div>
    );
};

export default CalendarAccounts;
