import React, { useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useNotifications } from './NotificationsContext';

const Notifier = () => {
    const displayed = useRef([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { notifications, removeNotification } = useNotifications();

    const storeDisplayed = (id) => {
        displayed.current = [...displayed.current, id];
    };

    useEffect(() => {
        notifications.forEach((notification) => {
            if (displayed.current.includes(notification.key)) return;

            enqueueSnackbar(notification.message, {
                ...notification.options,
                onClose: (event, reason, key) => {
                    if (notification.options.onClose) {
                        notification.options.onClose(event, reason, key);
                    }
                    removeNotification(key);
                },
                onExited: (event, key) => {
                    displayed.current = displayed.current.filter((id) => id !== key);
                    removeNotification(key);
                },
            });

            storeDisplayed(notification.key);
        });
    }, [notifications, enqueueSnackbar, removeNotification]);

    return null;
};

export default Notifier;
