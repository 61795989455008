import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { RRule } from 'rrule';
import { Select } from 'antd';
const { Option } = Select;

const RecurringEventPicker = React.memo(({ setRecurrenceRule, setFrequency, recurrenceRule }) => {
    const frequencies = useMemo(
        () => ['Once', 'Daily', 'Weekdays', 'Weekly', 'Every Two Weeks', 'Monthly', 'Yearly'],
        []
    );
    const weekdays = useMemo(() => ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'], []);

    const [selectedFrequency, setSelectedFrequency] = useState('Once');
    const [selectedDays, setSelectedDays] = useState(new Set());
    const [rrule, setRRule] = useState('');

    const weekdayToString = useCallback((weekdayNumber) => {
        const weekdays = { 0: 'MO', 1: 'TU', 2: 'WE', 3: 'TH', 4: 'FR', 5: 'SA', 6: 'SU' };
        return weekdays[weekdayNumber];
    }, []);

    const generateRRule = useCallback(
        (days) => {
            if (selectedFrequency === 'Once') {
                setRRule('Once');
                setRecurrenceRule('Once');
                return;
            }

            const options = {
                freq: RRule.YEARLY,
                dtstart: new Date(),
            };

            switch (selectedFrequency) {
                case 'Daily':
                    options.freq = RRule.DAILY;
                    break;
                case 'Weekdays':
                case 'Weekly':
                    options.freq = RRule.WEEKLY;
                    if (days.size > 0) {
                        options.byweekday = Array.from(days).map((day) => RRule[day]);
                    }
                    break;
                case 'Every Two Weeks':
                    options.freq = RRule.WEEKLY;
                    options.interval = 2;
                    break;
                case 'Monthly':
                    options.freq = RRule.MONTHLY;
                    break;
                case 'Yearly':
                    options.freq = RRule.YEARLY;
                    break;
                default:
                    return;
            }

            try {
                const rruleObj = new RRule(options);
                const rruleString = rruleObj.toString();
                const match = rruleString.match(/RRULE:([^\n]+)/);
                const strippedRRule = match && match[1] ? match[1] : '';
                setRRule(strippedRRule);
                setRecurrenceRule(strippedRRule);
            } catch (e) {
                console.error('Could not create RRule:', e);
            }
        },
        [selectedFrequency, setRecurrenceRule]
    );

    useEffect(() => {
        setFrequency(selectedFrequency);
        if (selectedFrequency === 'Weekdays') {
            setSelectedDays(new Set(['MO', 'TU', 'WE', 'TH', 'FR']));
        } else if (selectedFrequency !== 'Weekly') {
            setSelectedDays(new Set());
        }
    }, [selectedFrequency, setFrequency]);

    useEffect(() => {
        generateRRule(selectedDays);
    }, [selectedDays, generateRRule]);

    useEffect(() => {
        if (recurrenceRule && recurrenceRule !== 'Once') {
            try {
                const rruleObj = RRule.fromString(`RRULE:${recurrenceRule}`);
                const freq = rruleObj.options.freq;
                const byweekday = rruleObj.options.byweekday || [];
                const weekdaySet = new Set(byweekday.map(weekdayToString));

                let frequencyName = 'Once';
                let days = new Set();

                switch (freq) {
                    case RRule.DAILY:
                        frequencyName = 'Daily';
                        break;
                    case RRule.WEEKLY:
                        if (rruleObj.options.interval === 2) {
                            frequencyName = 'Every Two Weeks';
                        } else if (
                            weekdaySet.size === 5 &&
                            ['MO', 'TU', 'WE', 'TH', 'FR'].every((day) => weekdaySet.has(day))
                        ) {
                            frequencyName = 'Weekdays';
                        } else {
                            frequencyName = 'Weekly';
                        }
                        days = weekdaySet;
                        break;
                    case RRule.MONTHLY:
                        frequencyName = 'Monthly';
                        break;
                    case RRule.YEARLY:
                        frequencyName = 'Yearly';
                        break;
                }

                setSelectedFrequency(frequencyName);
                setSelectedDays(days);
            } catch (e) {
                console.error('Invalid RRule', e);
                setSelectedFrequency('Once');
            }
        } else {
            setSelectedFrequency('Once');
        }
    }, [recurrenceRule, weekdayToString]);

    const toggleDay = useCallback((day) => {
        setSelectedDays((prevDays) => {
            const newDays = new Set(prevDays);
            if (newDays.has(day)) {
                newDays.delete(day);
            } else {
                newDays.add(day);
            }
            return newDays;
        });
    }, []);

    return (
        <div className="flex flex-col gap-4 w-full">
            <div>
                <Typography variant="caption" className="font-semibold">
                    Frequency
                </Typography>
                <Select
                    value={selectedFrequency}
                    onChange={setSelectedFrequency}
                    style={{ width: '100%', marginTop: '8px' }}
                    size="large"
                    dropdownStyle={{ zIndex: 9999 }}
                >
                    {frequencies.map((frequency) => (
                        <Option value={frequency} key={frequency}>
                            {frequency}
                        </Option>
                    ))}
                </Select>
            </div>

            {(selectedFrequency === 'Weekly' || selectedFrequency === 'Weekdays') && (
                <div className="flex flex-col gap-2 w-full">
                    <Typography variant="caption" className="font-semibold">
                        Days
                    </Typography>
                    <ButtonGroup className="flex gap-2 justify-evenly">
                        {weekdays.map((day) => (
                            <Button
                                key={day}
                                onClick={() => toggleDay(day)}
                                variant={selectedDays.has(day) ? 'contained' : 'outlined'}
                                style={{ border: '1px solid gray' }}
                                className={`text-xs font-semibold py-1 px-2 ${
                                    selectedDays.has(day)
                                        ? 'text-light-1200 dark:bg-dark-900'
                                        : 'text-light-1100 dark:bg-dark-1100'
                                }`}
                            >
                                {day}
                            </Button>
                        ))}
                    </ButtonGroup>
                </div>
            )}

            <div>
                <Typography variant="caption" className="font-semibold">
                    Generated RRULE
                </Typography>
                <Typography variant="body2">{rrule}</Typography>
            </div>
        </div>
    );
});

export default RecurringEventPicker;
