import React from 'react';
import { CircularProgress } from '@mui/material';
import BaseThemeComponent from './BaseThemeComponent';

const HomeLoading = () => {
    return (
        <BaseThemeComponent>
            <div className="flex justify-center mt-6">
                <CircularProgress color="inherit" style={{ color: "#007BFF" }} />
            </div>
        </BaseThemeComponent>
    );
};

export default HomeLoading;