import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import logo from '/images/logo-no-text.png';
import './splash.css';
import './clouds.css';
import './sun.css';

const BaseThemeComponent = ({ children }) => {
    const containerVariants = useMemo(
        () => ({
            hidden: { opacity: 0 },
            visible: {
                opacity: 1,
                transition: {
                    staggerChildren: 0.1,
                },
            },
        }),
        []
    );

    const itemVariants = useMemo(
        () => ({
            hidden: { y: 20, opacity: 0 },
            visible: {
                y: 0,
                opacity: 1,
                transition: {
                    type: 'spring',
                    stiffness: 50,
                },
            },
        }),
        []
    );

    const fadeIn = useMemo(
        () => ({
            hidden: { opacity: 0 },
            visible: {
                opacity: 1,
                transition: { duration: 0.5 },
            },
        }),
        []
    );

    return (
        <motion.div
            className="flex-1 flex-grow pt-10 h-screen bg-sky-400"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            <div className="px-0 pt-0 w-full">
                <motion.div className="w-26 xs:w-60 sun xxs:block xxs:visible" variants={fadeIn}>
                    <div className="sun-animation"></div>
                </motion.div>
                <div className="z-0 mt-10 cloud_floating_container">
                    <motion.div className="icon-cloud cloud cloud_moving" variants={fadeIn}>
                        <FontAwesomeIcon className="icon-cloud cloud" icon={faCloud} />
                    </motion.div>
                    <motion.div className="fade-in-image" variants={fadeIn}>
                        <FontAwesomeIcon className="con-cloud cloud cloud2 cloud_moving" icon={faCloud} />
                    </motion.div>
                    <motion.div className="fade-in-image" variants={fadeIn}>
                        <FontAwesomeIcon className="icon-cloud cloud cloud3 cloud_moving" icon={faCloud} />
                    </motion.div>
                    <motion.div className="fade-in-image" variants={fadeIn}>
                        <FontAwesomeIcon
                            className="animate-wiggle fade-in-image icon-cloud cloud cloud4 cloud_moving"
                            icon={faCloud}
                        />
                    </motion.div>
                    <motion.div className="fade-in-image" variants={fadeIn}>
                        <FontAwesomeIcon
                            className="animate-wiggle fade-in-image icon-cloud cloud cloud5 cloud_moving"
                            icon={faCloud}
                        />
                    </motion.div>
                </div>
                <motion.div variants={itemVariants} className="pt-32 mx-auto text-center">
                    <h1 className="text-center text-black-900 md:mt-[-115px] mind-your-now-class leading-tighter tracking-tighter sm:tracking-normal font-avant text-4xl xm:text-4xl md:text-6xl md:mt-[120px] m-auto z-50 text-center justify-center flex-no-wrap">
                        <img
                            className="z-50 justify-center mx-auto mb-5 text-center"
                            src={logo}
                            alt="Mind Your Now Logo"
                        />
                        <div>
                            <span className="text-blue-800">Mind</span>
                            <span className="font-thin text-blue-200">Your</span>
                            <span className="text-yellow-500">Now</span>
                        </div>
                    </h1>
                </motion.div>
                {children}
            </div>
        </motion.div>
    );
};

export default BaseThemeComponent;
