import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import BaseThemeComponent from './BaseThemeComponent';

export const ErrorComponent = ({ message, retry }) => {
    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: { 
            y: 0,
            opacity: 1,
            transition: { 
                type: 'spring', 
                stiffness: 50 
            }
        }
    };

    return (
        <BaseThemeComponent>
            <motion.div 
                variants={itemVariants} 
                className="p-6 mx-auto mt-8 max-w-md text-center bg-white bg-opacity-90 rounded-lg shadow-lg error-message"
            >
                <FontAwesomeIcon icon={faExclamationTriangle} className="mb-4 text-4xl text-yellow-500" />
                <h2 className="mb-4 text-2xl font-semibold text-blue-800">Oops! Something went wrong</h2>
                <p className="mb-6 text-lg text-blue-600">{message}</p>
                <button 
                    onClick={retry} 
                    className="px-6 py-3 text-lg font-medium text-white bg-blue-600 rounded-full transition duration-150 ease-in-out hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    <FontAwesomeIcon icon={faRedoAlt} className="mr-2" />
                    Try Again
                </button>
            </motion.div>
        </BaseThemeComponent>
    );
};

export default ErrorComponent;