import React, { useEffect, useState, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TaskComponent from './TaskComponent';
import { useTaskModal } from '../../contexts/TaskModalContext';
import { useProjects } from '../utils/projectApi';
import Skeleton from '@mui/material/Skeleton';

export const TaskModal = () => {
    const { isOpen, currentTask, closeTaskModal, updateTask, handleUpdateTask } = useTaskModal();
    const { projectsData, isLoading: isProjectsLoading } = useProjects();
    const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);

    useEffect(() => {
        if (!isProjectsLoading && projectsData) {
            setIsProjectsLoaded(true);
        }
    }, [isProjectsLoading, projectsData]);

    const handleClose = useCallback(() => {
        closeTaskModal();
    }, [closeTaskModal]);

    const handleSave = () => {
        if (currentTask) {
            handleUpdateTask(currentTask);
        }
    };

    const style = {
        position: 'absolute',
        top: 'calc(7% + env(safe-area-inset-top))',
        left: 'calc(50% + env(safe-area-inset-left))',
        transform: 'translate(-50%, -10%)',
        bgcolor: 'background.paper',
        border: '0px solid #fff',
        boxShadow: 24,
        pt: 2,
        borderRadius: '1%',
        pl: 1,
        pr: 2,
        zIndex: 10000, // Increased z-index for the modal content
    };

    return (
        <Modal id="TaskFormModal" open={isOpen} onClose={handleClose}>
            <Box sx={{ ...style, width: '420px' }}>
                {currentTask && isProjectsLoaded && (
                    <TaskComponent
                        task={currentTask}
                        numberInList={0}
                        isModal={true}
                        saveTask={handleSave}
                        handleClose={handleClose}
                        updateTask={updateTask}
                        notifySaveAndClose={handleClose}
                    />
                )}
                {!isProjectsLoaded && <Skeleton variant="rectangular" width={200} height={40} />}
            </Box>
        </Modal>
    );
};

export default TaskModal;
