import React, { useState, useEffect } from 'react';

import { CalendarSetup } from '../calendar/CalendarSetup';
import { isAuthenticatedAtom } from '../../atoms/authAtoms';
import { tokenAtom } from '../../atoms/tokenAtoms';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Loading from '../utils/Loading';
import { useCustomer } from '../../providers/CustomerProvider';

export function AccountInfo() {
    const [passwordMessage, setPasswordMessage] = useState(null);

    const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
    const [token, setToken] = useAtom(tokenAtom);
    const navigate = useNavigate();
    const { customer, isLoading: isInfoLoading, error, refetch } = useCustomer();

    const handleLogout = () => {
        setIsAuthenticated(false);
        setToken('');
        navigate('/GettingStarted');
    };

    // rerender when isInfoLoading changes
    useEffect(() => {
        if (isInfoLoading) {
            return <Loading />;
        }
    }, [isInfoLoading]);

    if (isInfoLoading) {
        return <Loading />;
    }
    return (
        <div id="AccountSettings">
            <div className="p-6 mb-8">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-blue-800">Account Information</h2>
                    <button
                        onClick={handleLogout}
                        className="inline-flex items-center px-4 py-2 text-white bg-blue-600 rounded-md shadow-sm transition-colors hover:bg-blue-700"
                    >
                        <span className="mr-2 font-semibold">Logout</span>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                    </button>
                </div>
                <p className="mb-2 text-blue-600">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                    Email: {customer.email}
                </p>
                {/* Add more account information here if needed */}
            </div>
            <CalendarSetup />
        </div>
    );
}
