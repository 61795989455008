import React, { useCallback, memo } from 'react';
import { TextField, Switch, Grid, FormGroup, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Save from '@mui/icons-material/Save';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark as CloseTaskIcon, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import CustomDatePicker from './CustomDatePicker';
import TaskCompletedFieldset from './TaskCompletedFieldset';
import TaskScheduleSelector from './TaskScheduleSelector';
import TaskAddScheduleButton from './TaskAddScheduleButton';
import TaskSelectedSchedules from './TaskSelectedSchedules';
import RecurringEvent from './RecurringEvent';
import { Task, Schedule, TaskPriority } from '../../domain/Task';

const MemoizedFormControlLabel = memo(FormControlLabel);
const MemoizedFontAwesomeIcon = memo(FontAwesomeIcon);

interface TaskExpandedDetailsProps {
    task: Task;
    updateTask: (updates: Partial<Task>) => void;
    deleteTask: (id: string) => void;
    onComplete: (e: React.MouseEvent) => void;
    schedulesData: Schedule[];
    handleAddSchedule: (scheduleId: string) => void;
    handleDeleteSchedule: (id: string) => void;
    handleAutoScheduleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    selectedColor: string;
    saveTask: (task: Task) => void;
    cancelEdit: (e: React.MouseEvent) => void;
    toggleSlidingWindow: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TaskExpandedDetails: React.FC<TaskExpandedDetailsProps> = ({
    task,
    updateTask,
    deleteTask,
    onComplete,
    schedulesData,
    handleAddSchedule,
    handleDeleteSchedule,
    handleAutoScheduleChange,
    selectedColor,
    saveTask,
    cancelEdit,
    toggleSlidingWindow,
}) => {
    const handleChange = useCallback(
        (field: keyof Task, value: any) => {
            updateTask({ [field]: value });
        },
        [updateTask]
    );

    const memoizedHandleChange = useCallback((field: keyof Task) => (value: any) => handleChange(field, value), [handleChange]);
    const memoizedHandleAddSchedule = useCallback(
        () => handleAddSchedule(task.selectedSchedule || ''),
        [handleAddSchedule, task.selectedSchedule]
    );

    const handleSave = useCallback(() => {
        saveTask(task);
    }, [saveTask, task]);

    const handleDelete = useCallback(() => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            deleteTask(task.id);
        }
    }, [deleteTask, task.id]);

    const memoizedSetRecurrenceRule = useCallback((rule: string) => handleChange('recurrenceRule', rule), [handleChange]);

    const memoizedSetFrequency = useCallback(
        (frequency: string) => handleChange('selectedFrequency', frequency),
        [handleChange]
    );

    const getPriorityColor = (priority: TaskPriority): string => {
        switch (priority) {
            case 'CRITICAL':
                return '#0C803D';
            case 'OPPORTUNITY_NOW':
                return '#107CC4';
            case 'OVER_THE_HORIZON':
                return '#F9913B';
            case 'PARKING_LOT':
                return '#F04F23';
            default:
                return '#4CAF50';
        }
    };

    return (
        <div className="mt-4" style={{ backgroundColor: '#fff' }}>
            <div className="flex flex-row mt-2">
                <div className="flex flex-grow mr-2">
                    <CustomDatePicker
                        label="Start Date"
                        value={task.startDate}
                        setValue={memoizedHandleChange('startDate')}
                        taskStartDate={task.startDate}
                        taskPriority={task.priority}
                        onPriorityChange={memoizedHandleChange('priority')}
                        renderButtons={true}
                        updateTask={updateTask}
                    />
                </div>
                <div className="flex flex-grow">
                    <CustomDatePicker
                        label="Deadline"
                        value={task.dueDate}
                        setValue={memoizedHandleChange('dueDate')}
                        clearable={true}
                        updateTask={updateTask}
                    />
                </div>
            </div>

            {task.isCompleted && <TaskCompletedFieldset task={task} onComplete={onComplete} />}

            <div className="flex flex-col pt-1 mt-3 ml-0">
                <div className="mt-3">
                    <Grid container spacing={2} alignItems="center" className="mt-2">
                        <TaskScheduleSelector
                            selectedSchedule={task.selectedSchedule || ''}
                            setSelectedSchedule={memoizedHandleChange('selectedSchedule')}
                            handleAddSchedule={handleAddSchedule}
                            schedulesData={schedulesData}
                        />
                        <TaskAddScheduleButton
                            handleAddSchedule={memoizedHandleAddSchedule}
                            selectedSchedule={task.selectedSchedule}
                        />
                        <TaskSelectedSchedules
                            selectedSchedules={task.schedules || []}
                            handleDeleteSchedule={handleDeleteSchedule}
                        />
                    </Grid>
                </div>

                <div className="flex flex-row items-center">
                    <FormGroup className="mr-4">
                        <MemoizedFormControlLabel
                            control={
                                <Switch
                                    onChange={(e) => handleChange('isAutoScheduled', e.target.checked)}
                                    checked={task.isAutoScheduled}
                                />
                            }
                            label="Auto-schedule"
                        />
                    </FormGroup>
                    {task.selectedFrequency !== null &&
                        task.isAutoScheduled &&
                        task.selectedFrequency !== undefined &&
                        !['Once', 'Daily', 'Weekdays', 'Weekly'].includes(task.selectedFrequency) && (
                            <FormGroup>
                                <MemoizedFormControlLabel
                                    control={
                                        <Checkbox
                                            checked={task.isSlidingWindow ?? true}
                                            onChange={(e) => {
                                                handleChange('isSlidingWindow', e.target.checked);
                                                toggleSlidingWindow(e);
                                            }}
                                        />
                                    }
                                    label="Sliding Window"
                                />
                            </FormGroup>
                        )}
                </div>
            </div>
            <div>
                <RecurringEvent
                    setRecurrenceRule={memoizedSetRecurrenceRule}
                    recurrenceRule={task.recurrenceRule}
                    setFrequency={memoizedSetFrequency}
                />
            </div>
            <div className="flex flex-grow mt-2 mb-3 w-full min-w-full max-w-max">
                <TextField
                    multiline
                    fullWidth
                    placeholder="Notes:"
                    size="large"
                    value={task.notes || ''}
                    onChange={(e) => handleChange('notes', e.target.value)}
                />
            </div>

            <div className="flex flex-row justify-between">
                <div className="flex">
                    <MemoizedFontAwesomeIcon
                        className="ml-3 text-4xl cursor-pointer hover:text-white hover:bg-mynlightblue-400 hover:opacity-100"
                        color={selectedColor}
                        icon={CloseTaskIcon}
                        onClick={cancelEdit}
                        data-bs-toggle="tooltip"
                        title="Cancel Edits"
                    />
                </div>{' '}
                <div className="flex items-center">
                    <MemoizedFontAwesomeIcon
                        className="mr-4 text-2xl text-red-600 cursor-pointer hover:text-red-800"
                        icon={faTrashAlt}
                        onClick={handleDelete}
                        data-bs-toggle="tooltip"
                        title="Delete Task"
                    />
                    <Button
                        endIcon={<Save />}
                        id="saveTaskButton"
                        onClick={handleSave}
                        variant="contained"
                        type="submit"
                        style={{ backgroundColor: getPriorityColor(task.priority) }}
                    >
                        Save Task
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(TaskExpandedDetails);
