import React, { useEffect, useState, useCallback } from 'react';
import ScheduleForm from './ScheduleForm';
import ScheduleList from './ScheduleList';
import useSchedules from '../utils/scheduleApi';
import { useTasks } from '../../providers/TasksProvider';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';
import moment from 'moment';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export function ScheduleEditor() {
    const {
        schedules,
        isLoading,
        error,
        createSchedule,
        updateSchedule,
        deleteSchedule,
        refetch: refetchSchedules,
        formatAMPM,
    } = useSchedules();
    const { tasks } = useTasks();
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [creatingNewSchedule, setCreatingNewSchedule] = useState(false);

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD');
        ReactGA.send({ hitType: 'pageview', page: '/scheduleeditor', title: 'Initializing' });
    }, []);

    const handleScheduleSelect = (schedule) => {
        const firstSunday = moment(0).day(0).subtract(moment(0).day(), 'days').add(10, 'days');
        setSelectedSchedule({
            ...schedule,
            events: schedule.scheduleBlocks.map((block) => {
                const dayOfWeek = moment().day(block.dayOfWeek).day();
                return {
                    id: block.id,
                    start: firstSunday
                        .clone()
                        .day(dayOfWeek)
                        .hour(moment(block.startTime, 'HH:mm:ss').hour())
                        .minute(moment(block.startTime, 'HH:mm:ss').minute())
                        .toDate(),
                    end: firstSunday
                        .clone()
                        .day(dayOfWeek)
                        .hour(moment(block.endTime, 'HH:mm:ss').hour())
                        .minute(moment(block.endTime, 'HH:mm:ss').minute())
                        .toDate(),
                };
            }),
        });
    };

    const handleNewSchedule = () => {
        console.log('Creating new schedule');
        setCreatingNewSchedule(true);
        const newScheduleName = generateNewScheduleName(schedules);
        setSelectedSchedule({
            name: newScheduleName,
            events: [],
            timeZone: tasks[0]?.timeZone || 'Europe/Dublin',
        });
        console.log('New schedule initialized:', selectedSchedule);
    };

    const handleScheduleSave = async (schedule) => {
        const scheduleBlocks = schedule.events.map((event) => {
            const start = formatAMPM(event.start);
            const startTime = start;
            const end = formatAMPM(event.end);
            const endTime = end;
            const date = new Date(event.end).toLocaleDateString('en-US', { timeZone: schedule.timeZone });
            const dayOfWeek = new Date(date).getDay();

            return {
                startTime,
                endTime,
                dayOfWeek,
            };
        });

        const pojoSchedule = {
            id: schedule.id,
            name: schedule.name,
            timeZone: schedule.timeZone,
            scheduleBlocks: scheduleBlocks,
        };

        try {
            let result;
            if (schedule.id) {
                result = await updateSchedule(pojoSchedule);
            } else {
                pojoSchedule.id = uuidv4();
                result = await createSchedule(pojoSchedule);
            }
            console.log('Schedule saved successfully:', result);
            setSelectedSchedule(result);
            refetchSchedules();
        } catch (error) {
            console.error('Failed to save schedule:', error);
            // Handle the error (e.g., show an error message to the user)
        }
    };

    const handleScheduleDelete = async (schedule) => {
        const confirmed = window.confirm(`Are you sure you want to delete the schedule "${schedule.name}"?`);
        if (confirmed) {
            try {
                await deleteSchedule(schedule.id);

                setSelectedSchedule(null);
                refetchSchedules();
            } catch (error) {
                console.error('Failed to delete schedule:', error);
                alert('Failed to delete schedule. Please try again.');
            }
        }
    };

    const handleScheduleCancel = () => {
        setSelectedSchedule(null);
        setCreatingNewSchedule(false);
    };

    const handleTimeZoneChange = (event) => {
        const selectedTimeZone = event.target.value;

        if (selectedSchedule) {
            setSelectedSchedule({ ...selectedSchedule, timeZone: selectedTimeZone });
        }
    };

    const handleScheduleAddEvent = (info) => {
        if (selectedSchedule) {
            const newEvent = {
                id: uuidv4(),
                start: info.start,
                end: info.end,
            };
            setSelectedSchedule((prevSchedule) => ({
                ...prevSchedule,
                events: [...(prevSchedule.events || []), newEvent],
            }));
            console.log('Event added:', newEvent);
        }
    };

    const handleScheduleDeleteEvent = useCallback(
        (eventId) => {
            console.log('Deleting event from schedule:', eventId);
            if (selectedSchedule) {
                setSelectedSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    events: prevSchedule.events.filter((event) => event.id !== eventId),
                }));
            }
        },
        [selectedSchedule]
    );

    if (isLoading) {
        console.log('Loading schedules...');
        return <div>Loading schedules...</div>;
    }
    if (error) {
        console.error('Error loading schedules:', error);
        return <div>Error loading schedules: {error.message}</div>;
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="p-4 h-full bg-gradient-to-br from-blue-50 to-blue-100"
        >
            <div className="mx-auto" style={{ maxWidth: '100%' }}>
                <h1 className="flex items-center mb-8 text-4xl font-bold text-blue-800">
                    <FontAwesomeIcon icon={faClock} className="mr-4 text-blue-500" />
                    Schedule Editor
                </h1>
                <div className="flex h-full">
                    <div id="ScheduleListWrapper" className="p-4 w-1/4 h-full bg-blue-50 rounded-lg shadow-md">
                        <ScheduleList
                            schedules={schedules}
                            selectedSchedule={selectedSchedule}
                            handleScheduleSelect={handleScheduleSelect}
                            handleNewSchedule={handleNewSchedule}
                            handleScheduleDelete={handleScheduleDelete}
                        />
                    </div>
                    <div id="ScheduleFormWrapper" className="p-4 w-3/4 h-full bg-blue-50 rounded-lg shadow-md">
                        <ScheduleForm
                            selectedSchedule={selectedSchedule}
                            handleSave={handleScheduleSave}
                            onCancel={handleScheduleCancel}
                            handleScheduleDelete={handleScheduleDelete}
                            handleTimeZoneChange={handleTimeZoneChange}
                            onUpdateSelectedSchedule={setSelectedSchedule}
                            handleScheduleAddEvent={handleScheduleAddEvent}
                            handleScheduleDeleteEvent={handleScheduleDeleteEvent}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

function generateNewScheduleName(schedules) {
    const existingSchedules = schedules.filter((schedule) => /^New Schedule(\(\d+\))?$/.test(schedule.name));
    const lastNumber = existingSchedules.reduce((maxNumber, schedule) => {
        const match = schedule.name.match(/\((\d+)\)$/);
        return match ? Math.max(maxNumber, parseInt(match[1])) : maxNumber;
    }, 0);
    return `New Schedule(${lastNumber + 1})`;
}

export default ScheduleEditor;
