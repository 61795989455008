import React, { useMemo, useCallback } from 'react';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function TaskDurationField({ duration, handleChangeDuration }) {
    const validDurations = useMemo(() => ['', -1, '15m', '30m', '1h', '2h', '4h', '6h', '8h', '16h', '24h'], []);

    const handleChange = useCallback(
        (e) => {
            handleChangeDuration(e);
        },
        [handleChangeDuration]
    );

    const textFieldStyle = useMemo(() => ({ width: 140 }), []);

    return (
        <TextField
            size="medium"
            label="Duration"
            select
            style={textFieldStyle}
            value={validDurations.includes(duration) ? duration : ''}
            onChange={handleChange}
        >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
            <MenuItem value="-1">Reminder</MenuItem>
            <MenuItem value="15m">15 min</MenuItem>
            <MenuItem value="30m">30 min</MenuItem>
            <MenuItem value="1h">1 hour</MenuItem>
            <MenuItem value="2h">2 hours</MenuItem>
            <MenuItem value="4h">4 hours</MenuItem>
            <MenuItem value="6h">6 hours</MenuItem>
            <MenuItem value="8h">8 hours</MenuItem>
            <MenuItem value="16h">16 hours</MenuItem>
            <MenuItem value="24h">All Day</MenuItem>
        </TextField>
    );
}
