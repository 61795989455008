import React, { useMemo } from 'react';
import { useAtom } from 'jotai';
import { Navigate, Outlet } from 'react-router-dom';
import  HomeLoading  from '../HomeLoading';
import { isAuthenticatedAtom } from '../../atoms/authAtoms';
import { tokenAtom, isTokenExpiredAtom } from '../../atoms/tokenAtoms';

const RequireAuth = ({ redirectPath }) => {
    const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
    const [token] = useAtom(tokenAtom);
    const [isTokenExpired] = useAtom(isTokenExpiredAtom);

    useMemo(() => {
        const authStatus = Boolean(token && !isTokenExpired);
        if (isAuthenticated !== authStatus) {
            setIsAuthenticated(authStatus);
        }
    }, [token, isTokenExpired, isAuthenticated, setIsAuthenticated]);

    if (token === undefined) {
        return <HomeLoading />;
    }

    if (!isAuthenticated) {
        return <Navigate to={redirectPath} />;
    }

    return <Outlet />;
};

export default RequireAuth;