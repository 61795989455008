import moment from 'moment';
import React, { useMemo } from 'react';

export const TaskScheduleStatus = ({ task, isOnCalendar }) => {
    const getColorClassByStartDate = useMemo(() => {
        return (dueDate) => {
            if (dueDate === null || isOnCalendar) {
                return 'text-sm font-bold text-green-700';
            }

            const diffInDays = moment().diff(moment(dueDate), 'days');

            if (diffInDays === 0) {
                return 'text-sm text-gray-900';
            } else if (diffInDays < 0) {
                return 'text-sm text-gray-700';
            } else {
                return 'text-sm font-bold text-red-700';
            }
        };
    }, [isOnCalendar]);

    const getFutureOrOverdueText = useMemo(() => {
        return (startDate, dueDate) => {
            const now = moment().startOf('day');
            const startDiff = moment(startDate).startOf('day').diff(now, 'days');
            const dueDiff = now.diff(moment(dueDate).startOf('day'), 'days');

            if (dueDiff > 0) {
                return dueDiff === 1 ? 'Overdue yesterday' : `Overdue by ${dueDiff} days`;
            }
            if (task.isRecurringParent) {
                return 'Recurring Task';
            }
            if (startDiff === 0) {
                return task.constraintViolation || 'Start today';
            }
            if (task.isRecurringChild && task.constraintViolation) {
                return task.constraintViolation;
            }
            if (startDiff > 0) {
                return startDiff === 1 ? 'Tomorrow' : `In ${startDiff} days`;
            }
            return startDiff === -1 ? 'Yesterday' : `${-startDiff} days ago`;
        };
    }, [task]);

    const renderTime = (start, end) => {
        const formatTime = (time) => {
            const hour = moment(time).format('h');
            const minute = moment(time).format(':mm') === ':00' ? '' : moment(time).format(':mm');
            const period = moment(time).format('A');
            return `${hour}${minute} ${period}`;
        };

        return `${formatTime(start)} - ${formatTime(end)}`;
    };

    return (
        <div className="ml-1 text-sm font-light text-gray-900">
            {task.schedulingState === 'scheduling_requested' ? (
                <span className="text-sm font-bold text-green-700">Scheduling...</span>
            ) : task.schedulingState === 'scheduling_cancel_requested' ? (
                <span className="text-sm font-bold text-green-700">Cancelling...</span>
            ) : isOnCalendar ? (
                <span className={getColorClassByStartDate(task.dueDate)}>
                    <span className={getColorClassByStartDate(task.dueDate)}>
                        {renderTime(task.googleEvent.start, task.googleEvent.end)}
                    </span>
                </span>
            ) : (
                <span className={getColorClassByStartDate(task.dueDate)}>
                    {getFutureOrOverdueText(task.startDate, task.dueDate)}
                </span>
            )}
        </div>
    );
};

export default TaskScheduleStatus;
