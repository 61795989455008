import List from '@mui/material/List';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import ScheduleListItem from './ScheduleListItem';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: '100%',
            // backgroundColor: '#e0f7fa', // Light blue background
        },
        addButton: {
            marginTop: '10px',
            width: '100%',
        },
    })
);

export default function ScheduleList({
    schedules,
    handleScheduleSave,
    handleScheduleDelete,
    handleScheduleSelect,
    handleNewSchedule,
}) {
    const classes = useStyles();

    if (schedules === undefined) {
        console.log('Schedules is undefined');
        return <div>No schedules exist.</div>;
    }

    return (
        <div>
            <List className={classes.root} height="parent">
                {schedules.map((schedule) => (
                    <ScheduleListItem
                        key={schedule.name}
                        schedule={schedule}
                        handleScheduleSave={handleScheduleSave}
                        handleScheduleDelete={handleScheduleDelete}
                        handleScheduleSelect={handleScheduleSelect}
                    />
                ))}
            </List>
            <Button variant="contained" color="primary" onClick={handleNewSchedule} className={classes.addButton}>
                Add New Schedule
            </Button>
        </div>
    );
}
