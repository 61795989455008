import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCloud } from '@fortawesome/free-solid-svg-icons';
import EmailForm from './EmailForm';
import logo from '/images/logo-no-text.png';
import '../splash.css';
import '../clouds.css';
import '../sun.css';

const GettingStarted = () => {
    const location = useLocation();
    const [error, setError] = useState(location.state?.error || null);

    const clearError = () => {
        setError(null);
    };

    // Variants for animations
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 50,
            },
        },
    };

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.5 },
        },
    };

    return (
        <motion.div
            id="splash"
            className="flex-1 flex-grow pt-10 h-screen bg-sky-400"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            <div className="px-0 pt-0 w-full">
                <motion.div
                    className="w-26 xs:w-60 sun xxs:block xxs:visible"
                    variants={fadeIn} // Use fadeIn for the sun only
                >
                    {/* Sun image could go here */}
                </motion.div>
                <div className="z-0 mt-10 cloud_floating_container">
                    <div className="">
                        <FontAwesomeIcon className="icon-cloud cloud" icon={faCloud} />
                    </div>
                    <div className="fade-in-image">
                        <FontAwesomeIcon className="icon-cloud cloud cloud2" icon={faCloud} />
                    </div>
                    <div className="fade-in-image">
                        <FontAwesomeIcon className="icon-cloud cloud cloud3" icon={faCloud} />
                    </div>
                    <div className="fade-in-image">
                        <FontAwesomeIcon
                            className="animate-wiggle fade-in-image icon-cloud cloud cloud4"
                            icon={faCloud}
                        />
                    </div>
                    <div className="fade-in-image">
                        <FontAwesomeIcon
                            className="animate-wiggle fade-in-image icon-cloud cloud cloud5"
                            icon={faCloud}
                        />
                    </div>
                </div>
                <motion.div
                    variants={itemVariants}
                    className="pt-0 mx-auto text-center xxs:pt-10 sm:pt-10 text-black-900"
                >
                    <img className="z-50 justify-center mx-auto text-center" src={logo} alt="Mind Your Now Logo" />
                </motion.div>
                <motion.div variants={itemVariants} className="text-center text-black-900 md:mt-[-115px]">
                    <h1 className="gap-1 mind-your-now-class leading-tighter tracking-tighter sm:tracking-normal font-avant text-4xl xm:text-4xl md:text-6xl md:mt-[120px] m-auto z-50 flex text-center flex-1 justify-center flex-no-wrap">
                        <span className="text-blue-800">mind</span>
                        <span className="font-thin text-blue-200">your</span>
                        <span className="text-yellow-500">now</span>
                    </h1>
                </motion.div>
                {error && (
                    <motion.div
                        variants={itemVariants}
                        className="p-4 mx-auto mt-4 max-w-md text-center bg-white bg-opacity-70 rounded-lg shadow-lg error-message"
                    >
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mb-2 text-2xl text-yellow-500" />
                        <p className="mb-2 font-semibold text-blue-800">Oops! There was an issue with your login:</p>
                        <p className="text-blue-600">{error}</p>
                        <p className="mt-2 text-blue-600">
                            Please try again or contact support if the problem persists.
                        </p>
                    </motion.div>
                )}
                <EmailForm setError={setError} clearError={clearError} />
            </div>
        </motion.div>
    );
};

export default GettingStarted;
