import React, { useState } from 'react';

export const TimezoneEditModal = ({ timezone, handleRemoveTimezone, handleCloseModal }) => {
    const [editedTimezone, setEditedTimezone] = useState(timezone);

    const handleTimezoneChange = (event) => {
        setEditedTimezone(event.target.value);
    };

    const handleSaveTimezone = () => {
        // Perform any necessary actions with the edited timezone
        handleCloseModal();
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="p-6 bg-white rounded-lg shadow-lg">
                <h2 className="mb-4 text-xl font-bold">Edit Timezone</h2>
                <input
                    type="text"
                    value={editedTimezone}
                    onChange={handleTimezoneChange}
                    className="w-full px-4 py-2 mb-4 border border-gray-300 rounded"
                />
                <div className="flex justify-end">
                    <button
                        className="px-4 py-2 mr-2 text-white bg-blue-500 rounded"
                        onClick={handleSaveTimezone}
                    >
                        Save
                    </button>
                    <button
                        className="px-4 py-2 mr-2 text-white bg-red-500 rounded"
                        onClick={() => handleRemoveTimezone(timezone)}
                    >
                        Remove
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-200 rounded"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};