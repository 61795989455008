import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Capacitor } from '@capacitor/core';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { useBillingSubscription } from '../utils/useBillingSubscription';
import { useCustomer } from '../../providers/CustomerProvider';
import Link from '@mui/material/Link';

const store = InAppPurchase2;

const subMonthly = 'monthly_subscription';
const subAnnual = 'annual_subscription';

ReactGA.initialize('UA-297130788-01', {});

export function Billing() {
    const { customer } = useCustomer();
    const {
        createCustomerPortalSession,
        createCheckoutSession,
        setMobileSubscription,
        mobilePaymentError,
        mobilePaymentSuccess,
    } = useBillingSubscription();

    const [isMobilePaymentLoading, setIsMobilePaymentLoading] = useState(false);
    const [purchasedProduct, setPurchasedProduct] = useState(null);
    const [setMobileProducts] = useState();
    const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);

    const enableInAppPurchases = process.env.REACT_APP_ENABLE_IN_APP_PURCHASES === 'true';
    // Commented out mobile subscription function

    const onSubscribeMobileButton = async (productId) => {
        setIsMobilePaymentLoading(true);
        store
            .order(productId)
            .then(() => {
                console.log('order placed', store.get(productId));
            })
            .catch((error) => {
                console.error('purchase failed', error);
                setMobileSubscription.mutate({ error: error.toString() });
            });
    };
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/billing', title: 'Billing' });
        if (Capacitor.isNativePlatform() && enableInAppPurchases) {
            setupInAppPurchase();
        }
    }, [enableInAppPurchases]);

    const setupInAppPurchase = () => {
        store.verbosity = store.DEBUG;
        store.register([
            { id: subMonthly, type: store.PAID_SUBSCRIPTION },
            { id: subAnnual, type: store.PAID_SUBSCRIPTION },
        ]);

        setupListeners();
        store.refresh();
    };

    const setupListeners = () => {
        store.when(subMonthly).approved((product) => {
            console.log('DOING VERIFICATION MONTHLY');
            product.verify();
        });
        store.when(subAnnual).approved((product) => {
            console.log('DOING VERIFICATION ANNUAL');
            product.verify();
        });

        store.when(subMonthly).verified((product) => {
            console.log('DOING FINISH MONTHLY');
            setPurchasedProduct(product);
            product.finish();
            setIsMobilePaymentLoading(false);
            ReactGA.event({
                category: 'Payment',
                action: 'PurchasedMonthly',
            });
        });
        store.when(subAnnual).verified((product) => {
            console.log('DOING FINISH ANNUAL');
            setPurchasedProduct(product);
            product.finish();
            setIsMobilePaymentLoading(false);
            ReactGA.event({
                category: 'Payment',
                action: 'PurchasedAnnual',
            });
        });

        store.when(subMonthly).cancelled((product) => {
            console.log('Monthly subscription cancelled');
            setMobileSubscription.mutate({ error: 'Monthly subscription was cancelled.' });
            setIsMobilePaymentLoading(false);
        });
        store.when(subAnnual).cancelled((product) => {
            console.log('Annual subscription cancelled');
            setMobileSubscription.mutate({ error: 'Annual subscription was cancelled.' });
            setIsMobilePaymentLoading(false);
        });

        store.when(subMonthly).owned((product) => {
            console.log('We own ' + product.id);
            setPurchasedProduct(product);
            setIsMobilePaymentLoading(false);
        });
        store.when(subAnnual).owned((product) => {
            console.log('We own ' + product.id);
            setPurchasedProduct(product);
            setIsMobilePaymentLoading(false);
        });

        store.when(subMonthly).updated((product) => {
            console.log('Monthly subscription updated');
            setMobileProducts((prev) => ({ ...prev, [product.id]: product }));
        });
        store.when(subAnnual).updated((product) => {
            console.log('Annual subscription updated');
            setMobileProducts((prev) => ({ ...prev, [product.id]: product }));
        });

        store.error((err) => {
            console.log('Store error', err);
            setMobileSubscription.mutate({ error: err.toString() });
            setIsMobilePaymentLoading(false);
        });
        store.ready(() => {
            console.log('Store ready');
            setMobileProducts(store.products);
            setIsMobilePaymentLoading(false);
        });
    };

    const onSubscribeButton = () => {
        setIsSubscribeLoading(true);
        ReactGA.event({
            category: 'User',
            action: 'SubscribeButton',
        });
        createCheckoutSession.mutate(undefined, {
            onSettled: () => {
                setIsSubscribeLoading(false);
            },
        });
    };

    const onManageInvoicesButton = () => {
        setIsInvoiceLoading(true);
        createCustomerPortalSession.mutate(undefined, {
            onSettled: () => {
                setIsInvoiceLoading(false);
            },
        });
    };

    const manualStoreRefresh = () => {
        setIsMobilePaymentLoading(true);
        store.refresh();
    };

    if (customer == null || isMobilePaymentLoading) {
        return <div>Loading...</div>;
    }

    const isSubscribed = customer.stripeStatus === 'active' || purchasedProduct != null;
    const inFreeTrial = !isSubscribed && new Date(customer.freeTrialEndDate) >= new Date();
    const trialDaysLeft = inFreeTrial
        ? Math.round(moment.duration(moment(customer.freeTrialEndDate).diff(moment().startOf('day'))).asDays())
        : 0;

    const isMobileSubscribed = customer.expirationDate != null && new Date(customer.expirationDate) >= new Date();

    if (!Capacitor.isNativePlatform()) {
        return webDisplay();
    } else {
        return mobileDisplay();
    }

    function webDisplay() {
        if (isMobileSubscribed) {
            return (
                <fieldset className="p-3 mt-3 rounded border border-gray-400 border-solid opacity-100">
                    <legend className="pr-1 pb-0 text-3xl mt-[-5px] font-bold text-midnightblu-400 font-GilroyExtraBold m-0">
                        Billing
                    </legend>
                    <div className="text-base font-roboto">
                        You have a subscription on a mobile device with a renewal/expiration date of{' '}
                        {moment(new Date(customer.expirationDate)).format('MMMM Do YYYY, h:mm:ss a')}.
                    </div>
                    <div className="text-base font-roboto">You can manage your subscription on your mobile device.</div>
                </fieldset>
            );
        }
        return (
            <div>
                <fieldset className="p-3 mt-3 rounded border border-gray-400 border-solid opacity-100">
                    <legend className="pr-1 pb-0 text-3xl mt-[-5px] font-bold text-midnightblu-400 font-GilroyExtraBold m-0">
                        Billing
                    </legend>

                    {inFreeTrial ? <div>You have {trialDaysLeft} day(s) left in your free trial</div> : <></>}

                    {isSubscribed ? (
                        <>
                            <div className="mt-3 mb-2 text-base font-roboto">You are currently subscribed.</div>
                            <div className="text-blue-500 underline">
                                <Button color="primary" component="span" onClick={onManageInvoicesButton}>
                                    Manage Subscription
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col gap-2 mt-3">
                            <div>
                                <Button
                                    color="primary"
                                    component="span"
                                    onClick={onSubscribeButton}
                                    disabled={isSubscribeLoading}
                                >
                                    {isSubscribeLoading ? (
                                        <>
                                            <span className="mr-2">Loading...</span>
                                            <CircularProgress size={20} />
                                        </>
                                    ) : (
                                        'Subscribe Now'
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </fieldset>
            </div>
        );
    }

    function mobileDisplay() {
        return (
            <div>
                <fieldset className="p-3 mt-3 rounded border border-gray-400 border-solid opacity-100">
                    <legend className="pr-1 pb-0 text-3xl mt-[-5px] font-bold text-midnightblu-400 font-GilroyExtraBold m-0">
                        Billing
                    </legend>
                    <div className="text-red-500 font-roboto">{mobilePaymentError}</div>
                    <div className="text-green-500 font-roboto">{mobilePaymentSuccess}</div>

                    {purchasedProduct && (
                        <div className="flex flex-col text-base font-roboto">
                            <div className="flex flex-col text-green-700">{purchasedProduct.title || ''}</div>
                            <div className="flex flex-col">{purchasedProduct.description || ''}</div>
                            <div className="flex flex-col">{purchasedProduct.price || ''}</div>
                        </div>
                    )}
                    {purchasedProduct?.expiryDate && (
                        <>
                            <div className="text-base font-roboto">Renews/Expires: </div>
                            <div className="text-base font-roboto">
                                {moment(new Date(purchasedProduct.expiryDate)).format('MMM Do YY')}
                            </div>
                        </>
                    )}

                    {inFreeTrial && !isSubscribed && <div>You have {trialDaysLeft} day(s) left in your free trial</div>}

                    {isSubscribed ? (
                        <>
                            <div className="mb-2 text-base font-roboto">You are currently subscribed.</div>
                            <Button color="primary" component="span" onClick={() => store.manageSubscriptions()}>
                                Manage Subscription
                            </Button>
                            <div className="mt-2">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    component="span"
                                    onClick={manualStoreRefresh}
                                >
                                    Refresh Purchases
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <Button
                                    color="success"
                                    variant="contained"
                                    component="span"
                                    onClick={() => onSubscribeMobileButton(subMonthly)}
                                >
                                    Subscribe $4.99 Monthly
                                </Button>
                                <div className="flex flex-col mt-3">
                                    <Button
                                        color="success"
                                        variant="contained"
                                        component="span"
                                        onClick={() => onSubscribeMobileButton(subAnnual)}
                                    >
                                        Subscribe $49.99 Yearly ($10 off)
                                    </Button>
                                </div>
                            </div>
                            <div className="mt-2">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    component="span"
                                    onClick={manualStoreRefresh}
                                >
                                    Refresh Purchases
                                </Button>
                            </div>
                        </div>
                    )}
                </fieldset>
                {Capacitor.getPlatform() === 'ios' && (
                    <fieldset className="p-3 mt-2 rounded border border-gray-400 border-solid opacity-100">
                        <legend className="pr-1 pb-0 text-3xl mt-[-5px] font-bold text-midnightblu-400 font-GilroyExtraBold m-0">
                            Misc/Legal
                        </legend>
                        <Link href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/" rel="noreferrer">
                            <div className="text-blue-500 underline">
                                <Button>Terms of Service (EULA)</Button>
                            </div>
                        </Link>
                        <Link href="https://www.mindyournow.com/docs/privacy" rel="noreferrer">
                            <div className="text-blue-500 underline">
                                <Button>Privacy Policy</Button>
                            </div>
                        </Link>
                    </fieldset>
                )}
            </div>
        );
    }
}

export default Billing;
