import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faShoppingBasket,
    faFolder,
    faCalendar,
    faExclamationTriangle,
    faClock,
    faTasks,
    faInbox,
    faPlus,
    faBook,
    faMusic,
    faBriefcase,
    faUser,
    faHeart,
    faTh,
    faFilm,
    faBroom,
    faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import Briefing from './home/Briefing';
import CalendarIcon from './tasks/CalendarIcon';
import { Overview } from './Overview';
import HomeLoading from './HomeLoading';
import CollectionsPane from './CollectionsPane';
import RecurringTask from './tasks/RecurringTask';
import { useProjects } from './utils/projectApi';
import { useCustomer } from '../providers/CustomerProvider';
import criticalImage from '/images/noun-exclamation-green.svg';
import overTheHorizonImage from '/images/noun-horizon-orange.svg';
import opportunityNowImage from '/images/noun-opportunity-blue.svg';
import parkingLotImage from '/images/noun-parking-lot-red.svg';
import { useTaskModal } from '../contexts/TaskModalContext';
import { useTasks } from '../providers/TasksProvider';

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Planner = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { customer } = useCustomer();
    const { openTaskModal } = useTaskModal();

    const { projectsData, isLoading: isLoadingProjects } = useProjects();
    const [selectedCollectionObj, setSelectedCollectionObj] = useState(null);
    const [collectionTitleOverride, setCollectionTitleOverride] = useState(null);

    const collectionOrder = Object.keys(collectionIcon);

    const [showPriorityPanel, setShowPriorityPanel] = useState(false);
    const [priorityTask, setPriorityTask] = useState(null);

    const openPriorityPanel = useCallback((event, task) => {
        event.stopPropagation();
        setPriorityTask(task);
        setShowPriorityPanel(true);
    }, []);

    const {
        tasks,
        isLoading: isLoadingTasks,
        filteredTasks,
        processedRecurringTasks,
        selectedFilter,
        selectedCollection,
        updateFilter,
        updateCollection,
    } = useTasks();

    const recurringTasks = useMemo(() => {
        if (!tasks) return [];
        const recurringTaskIds = processedRecurringTasks.parentTaskIds;
        return tasks
            .filter((task) => recurringTaskIds.has(task.id))
            .map((task) => ({
                ...task,
                isScheduled: task.children && task.children.length > 0,
            }));
    }, [tasks, processedRecurringTasks.parentTaskIds]);

    const sortedProjects = useMemo(() => {
        if (projectsData) {
            return [...projectsData].sort((a, b) => collectionOrder.indexOf(a.type) - collectionOrder.indexOf(b.type));
        }
        return [];
    }, [projectsData, collectionOrder]);

    useEffect(() => {
        ReactGA.initialize('G-HC3CNRJ6WD');
        ReactGA.send({ hitType: 'pageview', page: '/planner', title: 'Planner' });
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const filter = queryParams.get('filter');
        const collection = queryParams.get('collection');

        if (filter) {
            updateFilter(filter);
        } else if (collection) {
            const selectedCollection = sortedProjects.find((proj) => proj.type === collection);
            if (selectedCollection) {
                updateCollection(collection);
                setSelectedCollectionObj(selectedCollection);
            }
        } else {
            handleFilterClick('TODAYS_TASKS');
        }
    }, [location.search, sortedProjects, updateFilter, updateCollection]);

    const handleCollectionClick = useCallback(
        (collection) => {
            updateCollection(collection.type);
            setSelectedCollectionObj(collection);
            navigate(`?collection=${collection.type}`);
        },
        [navigate, updateCollection]
    );

    const handleFilterClick = useCallback(
        (filterName) => {
            updateFilter(filterName);
            setSelectedCollectionObj(null);
            setCollectionTitleOverride(null);
            navigate(`?filter=${filterName}`);
        },
        [navigate, updateFilter]
    );

    const { type: collectionType, customName } = selectedCollectionObj || {};

    const selectedIcon = collectionIcon[collectionType] || faQuestion;
    const selectedColor = collectionColor[collectionType] || 'text-gray-500';

    const displayText = collectionTitleOverride || customName || collectionType || 'Inbox';

    const handleTaskMoved = () => {
        // No need to do anything.
    };

    function getOverviewIconAndText(selectedFilter, selectedCollection) {
        if (!selectedFilter && selectedCollection) {
            return { icon: null, text: selectedCollection.name, type: 'collection', color: selectedColor };
        }

        switch (selectedFilter) {
            case 'CRITICAL':
                return { icon: criticalImage, text: 'Critical Tasks', type: 'overview', color: 'text-myncritical-400' };
            case 'OVER_THE_HORIZON':
                return {
                    icon: overTheHorizonImage,
                    text: 'Over The Horizon',
                    type: 'overview',
                    color: 'text-mynoverthehorizon-400',
                };
            case 'OPPORTUNITY_NOW':
                return {
                    icon: opportunityNowImage,
                    text: 'Opportunity Now',
                    type: 'overview',
                    color: 'text-mynopportunitynow-400',
                };
            case 'PARKING_LOT':
                return {
                    icon: parkingLotImage,
                    text: 'Parking Lot',
                    type: 'overview',
                    color: 'text-mynparkinglot-400',
                };
            case 'TOTAL_TASKS':
                return { icon: faTasks, text: 'Total Tasks', type: 'overview', color: 'text-gray-600' };
            case 'TODAYS_TASKS':
                return {
                    icon: <CalendarIcon timezone={customer.timeZone} size={46} />,
                    text: "Today's Tasks",
                    type: 'overview',
                    color: 'text-green-700',
                };
            case 'PAST_DUE':
                return { icon: faExclamationTriangle, text: 'Past Due', type: 'overview', color: 'text-red-500' };
            case 'DUE_TODAY':
                return { icon: faClock, text: 'Due Today', type: 'overview', color: 'text-yellow-500' };
            case 'SCHEDULING_ISSUES':
                return { icon: faClock, text: 'Scheduling Issues', type: 'overview', color: 'text-red-400' };
            default:
                return { icon: selectedIcon, color: selectedColor, type: null };
        }
    }

    if (isLoadingTasks || isLoadingProjects) {
        return <HomeLoading />;
    }

    let { icon, text, type, color } = getOverviewIconAndText(selectedFilter, selectedCollectionObj);

    const fadeIn = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    const slideInFromRight = {
        hidden: { x: '100%', opacity: 0 },
        visible: { x: '0%', opacity: 1 },
    };

    return (
        <motion.div
            className="grid overflow-hidden grid-cols-12 gap-4 h-screen bg-gray-50"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
        >
            <CollectionsPane
                sortedProjects={sortedProjects}
                selectedCollection={selectedCollection}
                handleCollectionClick={handleCollectionClick}
                handleTaskMoved={handleTaskMoved}
                collectionIcon={collectionIcon}
                collectionColor={collectionColor}
            />
            <motion.div className="overflow-y-auto col-span-8 bg-gray-50" variants={fadeIn}>
                <div className="p-4">
                    <h2 className={`flex items-center mb-3 text-6xl text-gray-500 ${color} font-sfProRounded`}>
                        {type === 'overview' ? (
                            <span className="flex items-center">
                                {typeof icon === 'string' ? (
                                    <img
                                        src={icon}
                                        alt={text}
                                        className={`${color}`}
                                        style={{ maxWidth: '0.8em', maxHeight: '0.8em' }}
                                    />
                                ) : React.isValidElement(icon) ? (
                                    React.cloneElement(icon, {
                                        className: `${color}`,
                                        style: { fontSize: '0.8em' },
                                    })
                                ) : (
                                    <FontAwesomeIcon icon={icon} className={`${color}`} style={{ fontSize: '0.8em' }} />
                                )}
                            </span>
                        ) : (
                            <span className="flex items-center">
                                <FontAwesomeIcon
                                    icon={selectedIcon}
                                    className={`${selectedColor}`}
                                    style={{ fontSize: '0.8em' }}
                                />
                            </span>
                        )}
                        <span className="ml-4" style={{ fontSize: '0.8em' }}>
                            {collectionTitleOverride
                                ? collectionTitleOverride
                                : text || capitalizeFirstLetter(displayText)}
                        </span>
                    </h2>
                    <hr />
                    <div className="flex justify-between">
                        <Briefing
                            isPlanner={true}
                            selectedCollectionObj={selectedCollectionObj}
                            filteredTasks={filteredTasks}
                            selectedFilter={selectedFilter}
                            selectedCollection={selectedCollection}
                            className="w-1/2"
                        />
                        <div className="p-4 mt-[-10px]">
                            <div className="text-lg font-bold"> Recurring Tasks</div>
                            {recurringTasks.map((task) => (
                                <RecurringTask
                                    key={task.id}
                                    task={task}
                                    icon={collectionIcon[task.icon]}
                                    openPriorityPanel={openPriorityPanel}
                                    openTaskModal={openTaskModal}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div className="overflow-y-auto col-span-2 bg-gray-50" variants={slideInFromRight}>
                <Overview handleFilterClick={handleFilterClick} selectedFilter={selectedFilter} />
            </motion.div>
        </motion.div>
    );
};

export const collectionIcon = {
    All: faTh,
    INBOX: faInbox,
    TODAY: faCalendar,
    UPCOMING: faClock,
    PERSONAL: faUser,
    WORK: faBriefcase,
    HABITS: faHeart,
    CHORES: faBroom,
    GROCERIES: faShoppingBasket,
    BOOKS: faBook,
    MUSIC: faMusic,
    MOVIES: faFilm,
    OTHER: faFolder,
};

export const collectionColor = {
    ALL: 'text-black',
    INBOX: 'text-gray-500',
    TODAY: 'text-blue-500',
    UPCOMING: 'text-orange-500',
    PERSONAL: 'text-red-500',
    WORK: 'text-brown-600',
    HABITS: 'text-pink-500',
    CHORES: 'text-pink-500',
    GROCERIES: 'text-green-500',
    BOOKS: 'text-yellow-500',
    MUSIC: 'text-purple-500',
    MOVIES: 'text-orange-500',
    OTHER: 'text-brown-500',
};

export default Planner;
