import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, Legend } from 'recharts';
import { useTasks } from '../../providers/TasksProvider';

const TasksBarChart = ({ width = '95%', height = 400, days = 60 }) => {
    const { tasks, isLoading } = useTasks();

    const formatLabel = (label) => {
        return label
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const prepareDataForBarChart = (tasks, startDate, days) => {
        const dateBuckets = new Array(days).fill(null).map(() => ({
            OVER_THE_HORIZON: 0,
            CRITICAL: 0,
            OPPORTUNITY_NOW: 0,
            PARKING_LOT: 0,
        }));
        const today = new Date(startDate).setHours(0, 0, 0, 0);

        tasks.forEach((task) => {
            if (!task.startDate) return; // Skip tasks without a startDate
            const taskStartDate = new Date(task.startDate).setHours(0, 0, 0, 0);
            let dayDifference = Math.floor((taskStartDate - today) / 86400000);

            if (dayDifference >= 0 && dayDifference < days && task.priority) {
                dateBuckets[dayDifference][task.priority] = (dateBuckets[dayDifference][task.priority] || 0) + 1;
            }
        });

        return dateBuckets.map((buckets, index) => {
            const dateLabel = new Date(today + index * 86400000).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
            });
            const formattedBuckets = Object.entries(buckets).reduce((acc, [key, value]) => {
                acc[formatLabel(key)] = value;
                return acc;
            }, {});
            return {
                date: dateLabel,
                ...formattedBuckets,
            };
        });
    };

    const data = useMemo(() => {
        if (!tasks || tasks.length === 0) return [];
        const startDate = new Date();
        return prepareDataForBarChart(tasks, startDate, days);
    }, [tasks, days]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (data.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <div className="flex justify-center mt-10 w-full">
            <ResponsiveContainer width={width} height={height}>
                <BarChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 50,
                        bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tick={(props) => (
                            <g transform={`translate(${props.x},${props.y})`}>
                                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                                    {props.payload.value}
                                </text>
                            </g>
                        )}
                    >
                        <Label value="Date" offset={20} position="bottom" />
                    </XAxis>
                    <YAxis>
                        <Label value="Tasks" angle={-90} position="insideLeft" />
                    </YAxis>
                    <Tooltip />
                    <Legend wrapperStyle={{ bottom: -10 }} />
                    <Bar dataKey="Over The Horizon" stackId="a" fill="#F9913B" />
                    <Bar dataKey="Critical" stackId="a" fill="#0C803D" />
                    <Bar dataKey="Opportunity Now" stackId="a" fill="#107CC4" />
                    <Bar dataKey="Parking Lot" stackId="a" fill="#F04F23" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TasksBarChart;
