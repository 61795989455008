import React, { useCallback } from 'react';
import { Button, Grid } from '@mui/material';

export default function TaskAddScheduleButton({ handleAddSchedule }) {
    const memoizedHandleAddSchedule = useCallback(() => {
        handleAddSchedule();
    }, [handleAddSchedule]);

    return (
        <Grid item xs={12} sm={6}>
            <Button className="text-white bg-blue-500 rounded-md" onClick={memoizedHandleAddSchedule}>
                Add Schedule
            </Button>
        </Grid>
    );
}
