import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export class Loading extends React.Component {
    render() {
        return (
            <Box
                id="LoadingSpinner"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}
            >
                {/* <img className="top" src={day_logo_color} alt="Mind Your Now Logo" /> */}
                <CircularProgress />
            </Box>
        );
    }
}

export default Loading;
